import { SET_ALERTS, CLEAR_ALERT } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  alerts: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ALERTS: {
      return { ...state, alerts: action.payload };
    }
    case CLEAR_ALERT: {
      const filteredAlerts = state.alerts.filter(alert => alert.name !== action.payload);
      return { ...state, alerts: filteredAlerts };
    }
    default:
      return state;
  }
};
