import Web3 from 'web3';

const parsePoolInfo = poolInfo => {
  return {
    globals: {
      poolBalance: Number(Web3.utils.fromWei(poolInfo.globals[0]._hex)),
      numberOfParticipants: Number(Web3.utils.fromWei(poolInfo.globals[1]._hex) * 10 ** 18),
      totalPoolWeight: Number(Web3.utils.fromWei(poolInfo.globals[2]._hex) * 10 ** 18),
      activeBalance: Number(Web3.utils.fromWei(poolInfo.globals[3]._hex)),
      dividends: Number(Web3.utils.fromWei(poolInfo.globals[4]._hex)),
      totalAmountClaimedByUsers: Number(Web3.utils.fromWei(poolInfo.globals[5]._hex)),
      totalAmountLostByUsers: Number(Web3.utils.fromWei(poolInfo.globals[6]._hex)),
    },
    userStats: {
      participating: Number(Web3.utils.fromWei(poolInfo.user_stats[0]._hex) * 10 ** 18) === 1,
      rankStoredInPoolContract: Number(Web3.utils.fromWei(poolInfo.user_stats[1]._hex) * 10 ** 18),
      weight: Number(Web3.utils.fromWei(poolInfo.user_stats[2]._hex) * 10 ** 18),
      realtimeDividends: Number(Web3.utils.fromWei(poolInfo.user_stats[3]._hex)),
      estimatedDividendsNextRank: Number(Web3.utils.fromWei(poolInfo.user_stats[4]._hex)),
    },
    claimInfo: {
      userClaimableAmountUSDT: Number(Web3.utils.fromWei(poolInfo.claim_info[0]._hex)),
      previousClaimTimestamp: Number(Web3.utils.fromWei(poolInfo.claim_info[1]._hex) * 10 ** 18),
      previousUserAction: Number(Web3.utils.fromWei(poolInfo.claim_info[2]._hex) * 10 ** 18),
      nextClaimUnlockTimestamp: Number(Web3.utils.fromWei(poolInfo.claim_info[3]._hex) * 10 ** 18),
      cooldownCountdown: Number(Web3.utils.fromWei(poolInfo.claim_info[4]._hex) * 10 ** 18),
      claimExpirationTimestamp: Number(Web3.utils.fromWei(poolInfo.claim_info[5]._hex) * 10 ** 18),
      claimExpired: Number(Web3.utils.fromWei(poolInfo.claim_info[6]._hex) * 10 ** 18) === 1,
      totalReward: Number(Web3.utils.fromWei(poolInfo.claim_info[7]._hex)),
      totalLost: Number(Web3.utils.fromWei(poolInfo.claim_info[8]._hex) * 10 ** 18),
      usdtEquivalentOfPdwOwned: Number(Web3.utils.fromWei(poolInfo.claim_info[9]._hex)),
      usdtThresholdToUnlockTheClaim: Number(Web3.utils.fromWei(poolInfo.claim_info[10]._hex)),
    },
    claimFlags: {
      promoterActive: poolInfo.claim_flags[0],
      banned: poolInfo.claim_flags[1],
      pwdUsdEquivalentSatisfied: poolInfo.claim_flags[2],
      cooldownExpired: poolInfo.claim_flags[3],
      allConditions: poolInfo.claim_flags[4],
    },
    currentRewardPerRank: poolInfo.current_reward_per_rank.map(item => Number(Web3.utils.fromWei(item._hex) * 10 ** 18)),
    nParticipantsPerRank: poolInfo.n_participants_rank.map(item => Number(Web3.utils.fromWei(item._hex) * 10 ** 18)),
  };
};

export { parsePoolInfo };
