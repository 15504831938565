import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { WatchLater } from '@material-ui/icons';

const CountdownWithSecondsSimple = ({ targetTimestamp, onCountdownComplete }) => {
  const calculateTimeLeft = () => {
    const difference = +moment(targetTimestamp) - +moment();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      onCountdownComplete();
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = time => {
    return time < 10 ? `${time}` : time;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <WatchLater />
      <p
        style={{
          fontSize: 20,
          fontWeight: 'bold',
          marginBottom: -10,
          marginTop: -10,
          marginLeft: 10,
        }}>
        {timeLeft.days !== undefined
          ? `${formatTime(timeLeft.days)}d:${formatTime(timeLeft.hours)}h:${formatTime(timeLeft.minutes)}m:${formatTime(
              timeLeft.seconds,
            )}s`
          : '0d:0h:0m:0s'}
      </p>
    </div>
  );
};

export default CountdownWithSecondsSimple;
