import { LOAD_EVENTS } from '../../db/constants/ActionTypes';

export const loadEvents = events => {
  return dispatch => {
    dispatch({
      type: LOAD_EVENTS,
      payload: events || [],
    });
  };
};
