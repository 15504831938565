import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CmtCard from '@coremat/CmtCard';
import { Typography } from '@material-ui/core';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { ArrowBack, ArrowDownward } from '@material-ui/icons';
import moment from 'moment';
import { getNetworkTree } from 'db/functions';
import { getCareerInfo } from 'smart_contract/career/functions';
import ClipLoader from 'react-spinners/ClipLoader';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
    width: '90vw',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pointerOnHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contentContainer: {},
  clipLoaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
  },
  clickableText: {
    cursor: 'pointer',
    color: '#1976d2',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const UserInfo = ({ users, goToUsersInfoTable, selectedUserID }) => {
  const classes = useStyles();

  const [networkTree, setNetworkTree] = useState([]);
  const [careerInfo, setCareerInfo] = useState([]);
  const [source, setSource] = useState(users.find(user => user.userID === selectedUserID));
  const [loadingData, setLoadingData] = useState(true);

  const selectSource = userID => {
    setSource(users.find(user => Number(user.userID) === Number(userID)));
  };

  useEffect(() => {
    const asyncFunction = async () => {
      setLoadingData(true);
      const { networkTree } = await getNetworkTree(source.userID);
      setNetworkTree(networkTree);
      const { careerInfo } = await getCareerInfo(source.userID, source.ethAddress, false);
      setCareerInfo(careerInfo);
      setLoadingData(false);
    };
    asyncFunction();
  }, [source]);

  return (
    <CmtCard className={classes.container}>
      <div className={classes.headerContainer}>
        <ArrowBack className={classes.pointerOnHover} onClick={goToUsersInfoTable} />
        <Typography variant="h1" component={'h1'} style={{ padding: 20 }}>
          User Info
        </Typography>
      </div>
      <CmtCardContent className={classes.contentContainer}>
        {!loadingData ? (
          <div>
            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
              UserID: {source.userID}
            </Typography>
            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
              Username: {source.username}
            </Typography>
            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
              Email: {source.email}
            </Typography>
            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
              Wallet Address: {source.ethAddress}
            </Typography>
            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20, marginTop: 20 }}>
              Joined:{' '}
              {moment(
                typeof source.joinedTimestamp === 'number' ? source.joinedTimestamp * 1000 : source.joinedTimestamp,
              ).format('YYYY-MM-DD')}
            </Typography>

            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20, marginTop: 20 }}>
              Active: {source.isActive ? 'Yes' : 'No'}
            </Typography>
            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
              Total Earned: ${source.totalEarned}
            </Typography>

            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20, marginTop: 20 }}>
              Career Started: {careerInfo.careerStarted ? 'Yes' : 'No'}
            </Typography>
            {careerInfo.careerStarted && (
              <div>
                <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
                  Career Pointes: {careerInfo.careerPointes}
                </Typography>
                <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
                  Current Rank: {careerInfo.currentActiveRank}
                </Typography>
                <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
                  Eligable Rank: {careerInfo.eligableRank}
                </Typography>
                <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
                  Next Rank Points Left: {careerInfo.nextRankPointsLeft}
                </Typography>
              </div>
            )}

            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20, marginTop: 20 }}>
              Parent ID:{' '}
              <span
                onClick={() => source.parentID !== 1 && selectSource(source.parentID)}
                className={source.parentID !== 1 && classes.clickableText}>
                {source.parentID}
              </span>
            </Typography>
            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20 }}>
              Sponsor ID:{' '}
              <span
                onClick={() => source.sponsorID !== 1 && selectSource(source.sponsorID)}
                className={source.sponsorID !== 1 && classes.clickableText}>
                {source.sponsorID}
              </span>
            </Typography>

            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20, marginTop: 20 }}>
              Invited users:{' '}
              {source.invitedUsersIDs.map((invitedUser, i) => {
                return (
                  <div key={i}>
                    {i + 1}
                    {': '}
                    <span onClick={() => selectSource(invitedUser)} className={classes.clickableText}>
                      {invitedUser}
                    </span>
                  </div>
                );
              })}
            </Typography>
            <Typography variant="h3" component={'h3'} style={{ paddingLeft: 20, marginTop: 20 }}>
              Downline:{' '}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginTop: 20,
                }}>
                {networkTree.downline &&
                  networkTree.downline.map((downline, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}>
                        <span
                          onClick={() => selectSource(downline)}
                          className={classes.clickableText}
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            marginBottom: 10,
                          }}>
                          {downline}
                        </span>
                        <ArrowDownward />
                        {networkTree.nestedDownlines[downline].map((nestedDownline, i) => {
                          return (
                            <span
                              key={i}
                              onClick={() => selectSource(nestedDownline)}
                              className={classes.clickableText}
                              style={{ marginLeft: 10, marginRight: 10 }}>
                              {nestedDownline}
                            </span>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </Typography>
          </div>
        ) : (
          <div className={classes.clipLoaderContainer}>
            <ClipLoader />
          </div>
        )}
      </CmtCardContent>
    </CmtCard>
  );
};

export default UserInfo;
