import React from 'react';
import Box from '@material-ui/core/Box';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { makeStyles } from '@material-ui/core/styles';
import VideoBackground from 'components/Common/VideoBackground';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    width: '100%',
  },
  cmtCard: {
    width: '100%',
  },
  iFrame: {
    height: '100vh',
    width: '100%',
  },
  errorNumber: {
    color: 'white',
    fontWeight: 300,
    lineHeight: 1,
    marginBottom: 30,
    fontWeight: 'bold',
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
  },
  paragraph: {
    fontWeight: 100,
    lineHeight: 1,
    marginBottom: 30,
    fontWeight: 'bold',
    marginBottom: 0,
  },
}));

const BuyPwdPage = () => {
  const link =
    'https://pancakeswap.finance/swap?outputCurrency=0x64aBc804dD486E74bD1470B6F7447de80faFCAc4&inputCurrency=0x55d398326f99059fF775485246999027B3197955';
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <VideoBackground videoSrc="/background.mp4" />
      <iframe className={classes.iFrame} src={link} />
      <CmtCard className={classes.cmtCard}>
        <CmtCardContent className={classes.paragraph}>
          In order to successfully acquire PWD, remember to set slippage to 10% and connect your wallet
        </CmtCardContent>
        <CmtCardContent>
          <p>1.</p>
          <img src="/images/exchange/instruction_slippage_1.jpg" />
        </CmtCardContent>
        <CmtCardContent>
          <p>2.</p>
          <img src="/images/exchange/instruction_slippage_2.jpg" />
        </CmtCardContent>
        <CmtCardContent>
          <p>3.</p>
          <img src="/images/exchange/instruction_slippage_3.jpg" />
        </CmtCardContent>
      </CmtCard>
    </Box>
  );
};

export default BuyPwdPage;
