import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { loadTokenData } from './functions';
import TokenData from './TokenData';
import { fetchStart, fetchSuccess, fetchError } from 'redux/actions';
const imagesFolder = '/images/packages';

const QuantityDetailedInfoModal = ({ modalStyles, setIsOpen, modalIsOpen, packageID, configuredDiscounts }) => {
  Modal.setAppElement(`#package_element_${packageID}`);
  const dispatch = useDispatch();
  const hasConfiguredDiscounts =
    configuredDiscounts.quantityDiscountInfo && configuredDiscounts.quantityDiscountInfo.percentage > 0 ? true : false;
  const [table, setTable] = useState('');

  useEffect(() => {
    const loadTable = async () => {
      if (hasConfiguredDiscounts) {
        const tokenAddress = configuredDiscounts.quantityDiscountInfo.tokenAddress;
        const tokenAddressIsZero = parseInt(tokenAddress) === 0;

        const request = await loadTokenData({
          tokenAddresses: [tokenAddress],
        });
        if (request.error) {
          dispatch(fetchError(request.error));
          return;
        }
        const allTokenData = request.tokenData;

        const tokenData = new TokenData(allTokenData, tokenAddress);
        tokenData.init();

        const base64Data = tokenData.getBase64Data();
        let ticker = tokenData.getTicker();
        if (tokenAddressIsZero) {
          ticker = 'PURCHASES';
        }
        const type = tokenData.getType();
        let threshold = configuredDiscounts.quantityDiscountInfo.threshold;
        if (type === 'token') {
          threshold = threshold / 10 ** 18;
        }
        const percentage = configuredDiscounts.quantityDiscountInfo.percentage;

        const table = (
          <table style={modalStyles.tableDiv.table}>
            <tbody>
              <tr style={percentage > 0 ? modalStyles.tableDiv.table.currentIndex : null}>
                <td style={modalStyles.tableDiv.table.td}>
                  <div style={modalStyles.tableDiv.table.td.div}>
                    {tokenAddressIsZero ? (
                      <img style={modalStyles.tableDiv.table.logo} src={`${imagesFolder}/package.svg`} />
                    ) : (
                      <img style={modalStyles.tableDiv.table.logo} src={`data:image/png;base64, ${base64Data}`} />
                    )}
                    <p>
                      ≥ {threshold} {ticker}
                    </p>
                  </div>
                </td>
                <td style={modalStyles.tableDiv.table.td}>{percentage} %</td>
              </tr>
            </tbody>
          </table>
        );
        setTable(table);
      }
    };
    loadTable();
  }, [hasConfiguredDiscounts]);

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={modalIsOpen}
      contentLabel={'Discount detailed info'}
      onRequestClose={handleModalClose}>
      <Button onClick={handleModalClose}>Close</Button>
      {hasConfiguredDiscounts ? (
        <CmtCardContent style={modalStyles.body.withData}>
          <h3>Quantity discount available for package {packageID}</h3>
          <div style={modalStyles.tableDiv}>{table}</div>
        </CmtCardContent>
      ) : (
        <CmtCardContent style={modalStyles.body.noData}>
          <h2>No Quantity Discounts available for this Package</h2>
        </CmtCardContent>
      )}
    </Modal>
  );
};

export default QuantityDetailedInfoModal;
