import React from 'react';
import {
  Event,
  Beenhere,
  People,
  ShoppingBasket,
  AttachMoney,
  AttachFile,
  Home,
  Redeem,
  Whatshot,
  Face,
  Person,
  AddCircle,
  Timeline,
  Waves,
  BusinessCenter,
} from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';

export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id={'pages.accountPage'} />,
        type: 'collapse',
        icon: <Person />,
        link: '/account',
        children: [
          {
            name: <IntlMessages id={'pages.homePage'} />,
            type: 'userInfo',
            link: '/userInfo',
          },
          {
            name: <IntlMessages id={'pages.accountPage.wallet'} />,
            type: 'collapse',
            link: '/wallet',
            children: [
              {
                name: <IntlMessages id={'pages.homePage'} />,
                type: 'wallet',
                link: '/walletchild',
              },
            ],
          },
        ],
      },
      {
        name: <IntlMessages id={'pages.homePage'} />,
        type: 'item',
        icon: <Home />,
        link: '/home',
      },
      {
        name: <IntlMessages id={'pages.careerPage'} />,
        type: 'collapse',
        icon: <BusinessCenter />,
        link: '',
        children: [
          {
            name: <IntlMessages id={'pages.careerPage.main'} />,
            type: 'item',
            link: '/career',
          },
          {
            name: <IntlMessages id={'pages.careerPage.pools'} />,
            type: 'collapse',
            link: '',
            children: [
              {
                name: <IntlMessages id={'pages.careerPage.pools.activity'} />,
                type: 'item',
                link: '/pools/activity',
              },
              {
                name: <IntlMessages id={'pages.careerPage.pools.infinity'} />,
                type: 'item',
                link: '/pools/infinity',
              },
            ],
          },
        ],
      },
      {
        name: <IntlMessages id={'pages.networkPage'} />,
        type: 'collapse',
        icon: <People />,
        link: '',
        children: [
          {
            name: <IntlMessages id={'pages.networkPage.matrix'} />,
            type: 'item',
            link: '/network',
          },
          {
            name: <IntlMessages id={'pages.networkPage.earnings'} />,
            type: 'collapse',
            link: '/earnings',
            children: [
              {
                name: <IntlMessages id={'pages.networkPage.earnings'} />,
                type: 'earningsInfo',
                link: '/earningsinfo',
              },
            ],
          },
          {
            name: <IntlMessages id={'pages.networkPage.levels'} />,
            type: 'collapse',
            link: '/levels',
            children: [
              {
                name: <IntlMessages id={'pages.networkPage.levels'} />,
                type: 'levelsInfo',
                link: '/levelsInfo',
              },
            ],
          },
        ],
      },
      {
        name: <IntlMessages id={'pages.packagesPage'} />,
        type: 'collapse',
        icon: <Beenhere />,
        link: '',
        children: [
          {
            name: <IntlMessages id={'pages.pack.memberPage'} />,
            type: 'item',
            link: '/pack/member',
          },
          {
            name: <IntlMessages id={'pages.pack.primePage'} />,
            type: 'item',
            link: '/pack/prime',
          },
          {
            name: <IntlMessages id={'pages.pack.promoterPage'} />,
            type: 'item',
            link: '/pack/promoter',
          },
          {
            name: <IntlMessages id={'pages.pack.professionalPage'} />,
            type: 'item',
            link: '/pack/professional',
          },
        ],
      },
      {
        name: <IntlMessages id={'pages.pack.powerFirePage'} />,
        type: 'item',
        icon: <Whatshot />,
        link: '/pack/powerfire',
      },
      {
        name: <IntlMessages id={'pages.productsPage'} />,
        type: 'collapse',
        icon: <ShoppingBasket />,
        link: '',
        children: [
          {
            name: <IntlMessages id={'pages.products.nftPage'} />,
            type: 'item',
            link: '/products/nft',
          },
          {
            name: <IntlMessages id={'pages.products.academyPage'} />,
            type: 'item',
            link: '/products/academy',
          },
          {
            name: <IntlMessages id={'pages.products.coursesPage'} />,
            type: 'item',
            link: '/products/courses',
          },
          {
            name: <IntlMessages id={'pages.products.ecommercePage'} />,
            type: 'item',
            link: '/products/ecommerce',
          },
          {
            name: <IntlMessages id={'pages.products.copytradingPage'} />,
            type: 'item',
            link: '/products/copytrading',
          },
          {
            name: <IntlMessages id={'pages.products.otherPage'} />,
            type: 'item',
            link: '/products/other',
          },
          {
            name: <IntlMessages id={'pages.products.allPage'} />,
            type: 'item',
            link: '/packages',
          },
        ],
      },
      {
        name: <IntlMessages id={'pages.buyPwdPage'} />,
        type: 'item',
        icon: <AddCircle />,
        link: '/buy-pwd',
      },
      {
        name: <IntlMessages id={'pages.defiPassivePage'} />,
        type: 'collapse',
        icon: <Timeline />,
        link: '',
        children: [
          {
            name: <IntlMessages id={'pages.defiPassivePage.poolStaking'} />,
            type: 'item',
            link: '/defi-passive/pool-staking',
          },
          {
            name: <IntlMessages id={'pages.defiPassivePage.yieldFarming'} />,
            type: 'collapse',
            link: '',
            children: [
              {
                name: <IntlMessages id={'pages.defiPassivePage.yieldFarming.liquidity'} />,
                type: 'item',
                link: '/defi-passive/yield-farming/liquidity',
              },
              {
                name: <IntlMessages id={'pages.defiPassivePage.yieldFarming.poolFarmingBNB'} />,
                type: 'item',
                link: '/defi-passive/yield-farming/pool-farming-bnb',
              },
            ],
          },
        ],
      },
      {
        name: <IntlMessages id={'pages.marketplace'} />,
        type: 'collapse',
        icon: <Redeem />,
        link: '',
        children: [
          {
            name: <IntlMessages id={'pages.marketplace.marketplacePage'} />,
            type: 'item',
            link: '/marketplace/marketplace',
          },
          {
            name: <IntlMessages id={'pages.marketplace.giftCardsPage'} />,
            type: 'item',
            link: '/marketplace/giftcards',
          },
        ],
      },
      {
        name: <IntlMessages id={'pages.nftOpenSeaPage'} />,
        type: 'collapse',
        icon: <Waves />,
        link: '',
        children: [
          {
            name: <IntlMessages id={'pages.nftOpenSeaPage.celebrationPass'} />,
            type: 'item',
            link: '/nft-opensea/celebration-pass',
          },
          {
            name: <IntlMessages id={'pages.nftOpenSeaPage.peopleByVanessi'} />,
            type: 'item',
            link: '/nft-opensea/people-by-vanessi',
          },
          {
            name: <IntlMessages id={'pages.nftOpenSeaPage.heroesByChiuciSama'} />,
            type: 'item',
            link: '/nft-opensea/heroes-by-chiuci-sama',
          },
          {
            name: <IntlMessages id={'pages.nftOpenSeaPage.careerRank'} />,
            type: 'item',
            link: '/nft-opensea/career-rank',
          },
        ],
      },
      {
        name: <IntlMessages id={'pages.metaverse'} />,
        type: 'item',
        icon: <Face />,
        link: '/metaverse',
      },
      {
        name: <IntlMessages id={'pages.eventsPage'} />,
        type: 'item',
        icon: <Event />,
        link: '/events',
      },
      {
        name: <IntlMessages id={'pages.purchasesPage'} />,
        type: 'item',
        icon: <AttachMoney />,
        link: '/purchases',
      },
      {
        name: <IntlMessages id={'pages.resourcesPage'} />,
        type: 'item',
        icon: <AttachFile />,
        link: '/resources',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.homePage'} />,
        type: 'item',
        icon: <Home />,
        link: '/home',
      },
      {
        name: <IntlMessages id={'pages.networkPage'} />,
        type: 'item',
        icon: <People />,
        link: '/network',
      },
      {
        name: <IntlMessages id={'pages.pack.memberPage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/member',
      },
      {
        name: <IntlMessages id={'pages.pack.primePage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/prime',
      },
      {
        name: <IntlMessages id={'pages.pack.promoterPage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/promoter',
      },
      {
        name: <IntlMessages id={'pages.pack.professionalPage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/professional',
      },
      {
        name: <IntlMessages id={'pages.pack.powerFirePage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/powerfire',
      },
      {
        name: <IntlMessages id={'pages.productsPage'} />,
        type: 'item',
        icon: <ShoppingBasket />,
        link: '/products',
      },
      {
        name: <IntlMessages id={'pages.marketplacePage'} />,
        type: 'item',
        icon: <Redeem />,
        link: '/marketplace',
      },
      {
        name: <IntlMessages id={'pages.eventsPage'} />,
        type: 'item',
        icon: <Event />,
        link: '/events',
      },
      {
        name: <IntlMessages id={'pages.purchasesPage'} />,
        type: 'item',
        icon: <AttachMoney />,
        link: '/purchases',
      },
      {
        name: <IntlMessages id={'pages.resourcesPage'} />,
        type: 'item',
        icon: <AttachFile />,
        link: '/resources',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.homePage'} />,
        type: 'item',
        icon: <Home />,
        link: '/home',
      },
      {
        name: <IntlMessages id={'pages.networkPage'} />,
        type: 'item',
        icon: <People />,
        link: '/network',
      },
      {
        name: <IntlMessages id={'pages.pack.memberPage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/member',
      },
      {
        name: <IntlMessages id={'pages.pack.primePage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/prime',
      },
      {
        name: <IntlMessages id={'pages.pack.promoterPage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/promoter',
      },
      {
        name: <IntlMessages id={'pages.pack.professionalPage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/professional',
      },
      {
        name: <IntlMessages id={'pages.pack.powerFirePage'} />,
        type: 'item',
        icon: <Beenhere />,
        link: '/pack/powerfire',
      },
      {
        name: <IntlMessages id={'pages.productsPage'} />,
        type: 'item',
        icon: <ShoppingBasket />,
        link: '/products',
      },
      {
        name: <IntlMessages id={'pages.marketplacePage'} />,
        type: 'item',
        icon: <Redeem />,
        link: '/marketplace',
      },
      {
        name: <IntlMessages id={'pages.eventsPage'} />,
        type: 'item',
        icon: <Event />,
        link: '/events',
      },
      {
        name: <IntlMessages id={'pages.purchasesPage'} />,
        type: 'item',
        icon: <AttachMoney />,
        link: '/purchases',
      },
      {
        name: <IntlMessages id={'pages.resourcesPage'} />,
        type: 'item',
        icon: <AttachFile />,
        link: '/resources',
      },
    ],
  },
];
