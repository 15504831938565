import React, { useEffect, useState } from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector, useDispatch } from 'react-redux';
import { loadAlerts } from 'services/alerts/functions';
import { setAlerts } from 'redux/actions';

const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: 'relative',
  },
}));

const CmtVertical = props => {
  const { menuItems } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const alerts = useSelector(({ alerts }) => alerts.alerts);
  const authUser = useSelector(({ auth }) => auth.authUser);

  useEffect(() => {
    const asyncFunction = async () => {
      setLoadingAlerts(true);
      const alerts = await loadAlerts(authUser.userAddress);
      dispatch(setAlerts(alerts));
    };
    if (!loadingAlerts) {
      setTimeout(() => {
        asyncFunction();
      }, 100);
    }
  }, []);

  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {menuItems.map((item, index) => {
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} alerts={alerts} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} alerts={alerts} />;
          case 'item':
            return <NavMenuItem {...item} key={index} alerts={alerts} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CmtVertical;
