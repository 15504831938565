import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { clearAlert } from 'redux/actions';
import { WatchLater } from '@material-ui/icons';

const CountdownWithSeconds = ({ heading, targetTimestamp, setCountdownComplete = () => {} }) => {
  const dispatch = useDispatch();

  const calculateTimeLeft = () => {
    const difference = +moment(targetTimestamp) - +moment();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      dispatch(clearAlert('activityPoolRoundEnding'));
      setCountdownComplete(true);
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = time => {
    return time < 10 ? `0${time}` : time;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <WatchLater style={{ fontSize: 80 }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContentL: 'center',
          alignItems: 'center',
        }}>
        <p style={{ fontSize: 24, marginBottom: -5 }}>{heading}:</p>
        <p
          style={{
            fontSize: 40,
            fontWeight: 'bold',
            marginBottom: -10,
            marginTop: -10,
          }}>
          {timeLeft.days !== undefined
            ? `${formatTime(timeLeft.days)}:${formatTime(timeLeft.hours)}:${formatTime(timeLeft.minutes)}:${formatTime(
                timeLeft.seconds,
              )}`
            : '00:00:00:00'}
        </p>
        <p style={{ fontSize: 33, marginTop: -5 }}>DD:HH:MM:SS</p>
      </div>
    </div>
  );
};

export default CountdownWithSeconds;
