import React from 'react';
import PackagesPage from 'components/PackagesPage';

const PromoterPage = () => {
  return (
    <div>
      <PackagesPage
        category={'promoter'}
        messageID={'pages.pack.promoterPage'}
        descriptionID={'pages.pack.promoterPage.description'}
      />
    </div>
  );
};

export default PromoterPage;
