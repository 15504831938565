import React from 'react';
import PackagesPage from 'components/PackagesPage';

const AllPackagesPage = () => {
  return (
    <div>
      <PackagesPage
        category={undefined}
        messageID={'pages.products.allPage'}
        descriptionID={'pages.products.allPage.description'}
      />
    </div>
  );
};

export default AllPackagesPage;
