import React from 'react';
import BuyPwdPage from 'routes/Pages/BuyPwdPage';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';

const BuyPwdModal = ({ setIsOpen, modalIsOpen }) => {
  Modal.setAppElement('#root');
  const screenWidth = window.innerWidth;
  const breakingPoint = 800;

  const modalStyles = {
    content: {
      zIndex: 4000,
      left: screenWidth > breakingPoint ? '8vw' : '0',
      marginTop: screenWidth > breakingPoint ? 120 : 0,
      height: '100vh',
      width: '100vw',
      overflowY: 'scroll',
      position: 'absolute',
      inset: 0,
      padding: 0,
      margin: 0,
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
      textAlign: 'center',
    },
    button: {
      color: 'white',
    },
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal style={modalStyles} isOpen={modalIsOpen} contentLabel={'Buy PWD Modal'} onRequestClose={handleModalClose}>
      <Button style={modalStyles.button} onClick={handleModalClose}>
        Close
      </Button>
      <BuyPwdPage />
      <Button style={modalStyles.button} onClick={handleModalClose}>
        Close
      </Button>
    </Modal>
  );
};

export default BuyPwdModal;
