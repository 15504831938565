import React from 'react';
import LoadButton from '../LoadButton';

const customStyle = {
  background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
  textShadow: '2px 2px 1px #311b1b',
};

const PurchaseAgainButton = ({ purchaseEnabled, onPurchase, purchasesCount }) => {
  return (
    <LoadButton
      customStyle={purchaseEnabled ? customStyle : {}}
      disabled={!purchaseEnabled}
      loading={false}
      onClick={() => {
        if (purchaseEnabled) {
          onPurchase();
        }
      }}
      messageID={purchasesCount > 0 ? 'component.buttonGroup.purchaseAgain' : 'component.buttonGroup.purchase'}
    />
  );
};

export default PurchaseAgainButton;
