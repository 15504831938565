import React, { useState } from 'react';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import LoadButton from 'components/Common/LoadButton';
import SchoolIcon from '@material-ui/icons/School';
import Description from 'components/Common/Description';
import './styles.css';

const Event = ({
  title,
  languages,
  uniqueString,
  description,
  start,
  packageID,
  zoomLink,
  builderAllLink,
  handleRegister,
  enabled,
}) => {
  const [loading, setLoading] = useState(false);
  if (loading) {
    enabled = false;
  }
  const screenWidth = window.innerWidth;

  const languageFlags = languages.map(language => {
    return (
      <img style={{ width: 30, margin: '0 5px', }} key={language} src={`/images/flag/packages/${language}.svg`} />
    )
  });

  return (
    <CmtCard style={{ marginBottom: '20px', position: 'relative' }}>
      <div style={{ display: 'flex', position: 'absolute', top: 10, right: 3 }}>
          {languageFlags}
      </div>
      {screenWidth > 460 ? (
        <CmtCardHeader
          style={{ zIndex: 0 }}
          title={title}
          subTitle={`Associated to PackageID ${packageID} - Event UID: ${uniqueString}`}
          icon={<SchoolIcon style={{ fontSize: 50, color: '#6ba134' }} />}
        />
      ) : (
        <CmtCardHeader
          style={{ zIndex: 0 }}
          title={title}
          subTitle={`Associated to PackageID ${packageID}`}
          icon={<SchoolIcon style={{ fontSize: 50, color: '#6ba134' }} />}
        />
      )}
      <CmtCardContent style={{ zIndex: 0 }}>
        <Description description={description} />
      </CmtCardContent>
      <CmtCardContent style={{ zIndex: 0 }}>
        <LoadButton
          customStyle={{ zIndex: 0 }}
          disabled={!enabled}
          loading={loading}
          onClick={() => {
            if (enabled) {
              handleRegister(packageID, uniqueString, start, title, zoomLink, builderAllLink, setLoading);
            }
          }}
          messageID={'component.buttonGroup.register'}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default Event;
