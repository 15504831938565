import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableContainer, TableCell, TableRow, makeStyles, TableHead } from '@material-ui/core';
import { History, SettingsBackupRestore } from '@material-ui/icons';
import { getCycleData, getUserInfoAndEndedCycles } from 'smart_contract/activity_pool/functions';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import moment from 'moment';
import FilterInterface from './FilterInterface';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(() => ({
  loadHistoryButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loadingHistoryContainer: {
    animation: '$rotate 2s linear infinite',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  marginRight: {
    marginRight: 10,
  },
}));

const CycleHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const userAddress = authUser.userAddress;

  const [cycleData, setCycleData] = useState(undefined);
  const [filteredData, setFilteredData] = useState(undefined);
  const [userInfoAndEndedCycles, setUserInfoAndEndedCycles] = useState(undefined);
  const [loadingCycleData, setLoadingCycleData] = useState(false);
  const defaultValue = 'all';
  const [filter, setFilter] = useState(defaultValue);

  const onLoadCycleData = async () => {
    dispatch(fetchStart());
    setLoadingCycleData(true);
    const response = await getCycleData();
    if (response.error) {
      dispatch(fetchError(`Error - onLoadCycleData: ${response.error}`));
      setLoadingCycleData(false);
      return;
    }
    const cycleData = response.cycleData;
    const now = new Date();
    cycleData.cycles = cycleData.cycles.filter(obj => new Date(obj.closeTimestamp * 1000) <= now);
    cycleData.cycles = cycleData.cycles.sort((a, b) => {
      if (a.id > b.id) {
        return -1;
      } else {
        return 1;
      }
    });
    setFilteredData(cycleData);
    setCycleData(cycleData);

    setLoadingCycleData(false);
    dispatch(fetchSuccess());
  };

  const filterAll = () => {
    if (cycleData) {
      setFilteredData(cycleData);
    }
  };

  const filterParticipated = async () => {
    let localUserInfoAndEndedCycles = userInfoAndEndedCycles;
    if (!userInfoAndEndedCycles) {
      const response = await getUserInfoAndEndedCycles(userAddress);
      if (response.error) {
        return;
      }
      localUserInfoAndEndedCycles = response.userInfoAndEndedCycles;
      setUserInfoAndEndedCycles(localUserInfoAndEndedCycles);
    }
    if (cycleData) {
      const userEndedCycles = localUserInfoAndEndedCycles.endedCycles;
      const filteredCycles = cycleData.cycles.filter(cycle =>
        userEndedCycles.find(userEndedCycle => userEndedCycle === cycle.id),
      );
      const newCycleData = { ...cycleData };
      newCycleData.cycles = filteredCycles;
      setFilteredData(newCycleData);
    }
  };

  useEffect(() => {
    const asyncFunction = async () => {
      if (filter === 'all') {
        filterAll();
      }
      if (filter === 'participated') {
        await filterParticipated();
      }
    };
    asyncFunction();
  }, [filter]);

  return (
    <TableContainer component={Paper}>
      {!cycleData ? (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={5} align="center">
                {!loadingCycleData ? (
                  <div className={classes.loadHistoryButtonContainer} onClick={onLoadCycleData}>
                    <div className={classes.marginRight}>
                      <History />
                    </div>
                    <IntlMessages id={'component.loadActivityPoolHistory'} />
                  </div>
                ) : (
                  <div className={classes.loadingHistoryContainer}>
                    <SettingsBackupRestore fontSize={'large'} />
                  </div>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <div>
          <FilterInterface defaultValue={defaultValue} filter={filter} setFilter={setFilter} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>
                  <IntlMessages id={'component.started'} />
                </TableCell>
                <TableCell>
                  <IntlMessages id={'component.ended'} />
                </TableCell>
                <TableCell>
                  <IntlMessages id={'component.participants'} />
                </TableCell>
                <TableCell>
                  <IntlMessages id={'component.balance'} />
                </TableCell>
                <TableCell>
                  <IntlMessages id={'component.availableBalance'} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.cycles.map(cycle => {
                return (
                  <TableRow key={cycle.id}>
                    <TableCell>{cycle.id}</TableCell>
                    <TableCell>{moment(cycle.createdTimestamp * 1000).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{moment(cycle.closeTimestamp * 1000).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{cycle.nParticipants}</TableCell>
                    <TableCell>{cycle.balance.toFixed(2)} USDT</TableCell>
                    <TableCell>{cycle.availableBalance.toFixed(2)} USDT</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </TableContainer>
  );
};

export default CycleHistory;
