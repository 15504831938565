import React from 'react';
import PackagesPage from 'components/PackagesPage';

const CopytradingPage = () => {
  return (
    <div>
      <PackagesPage
        category={'copytrading'}
        messageID={'pages.products.copytradingPage'}
        descriptionID={'pages.products.copytradingPage.description'}
      />
    </div>
  );
};

export default CopytradingPage;
