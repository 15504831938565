import React from 'react';
import PackagesPage from 'components/PackagesPage';

const ProfessionalPage = () => {
  return (
    <div>
      <PackagesPage
        category={'professional'}
        messageID={'pages.pack.professionalPage'}
        descriptionID={'pages.pack.professionalPage.description'}
      />
    </div>
  );
};

export default ProfessionalPage;
