import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import IntlMessages from '@jumbo/utils/IntlMessages';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  navHeader: {
    marginBottom: 5,
    position: 'relative',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 15,
    letterSpacing: 1.5,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
}));

const RegistrationInfo = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  let registrationDate;
  if (authUser) {
    const registrationTimestamp = authUser.registrationTimestamp * 1000;
    registrationDate = moment(registrationTimestamp).format('YYYY-MM-DD');
  }

  return (
    <div className={classes.root} id={'registration'}>
      <div>
        <Typography className={classes.navHeader}>
          <IntlMessages id={'sidebar.registeredOn'} />
        </Typography>
        {authUser && registrationDate ? (
          <div style={{ display: 'flex', whiteSpace: 'nowrap', marginBottom: 10 }}>
            <Typography component="h4" variant="h4">
              {registrationDate}
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RegistrationInfo;
