import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import VideoBackground from 'components/Common/VideoBackground';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    width: '100%',
  },
  cmtCard: {
    width: '100%',
  },
  iFrame: {
    height: '100vh',
    width: '100%',
  },
  errorNumber: {
    color: 'white',
    fontWeight: 300,
    lineHeight: 1,
    marginBottom: 30,
    fontWeight: 'bold',
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
  },
  paragraph: {
    fontWeight: 100,
    lineHeight: 1,
    marginBottom: 30,
    fontWeight: 'bold',
    marginBottom: 0,
  },
}));

const PoolFarmingBnbPage = () => {
  const link = 'https://app.uncx.network/chain/bsc/farm/0x1881BC224B6cd8FA87aF296d5443f56e978a1c38';
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <VideoBackground videoSrc="/background.mp4" />
      <iframe className={classes.iFrame} src={link} />
    </Box>
  );
};

export default PoolFarmingBnbPage;
