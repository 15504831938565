import React, { useState } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Typography, makeStyles } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import markdownit from 'markdown-it';

const useStyles = makeStyles(theme => ({
  expandContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerExpandContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const Description = ({ description }) => {
  const classes = useStyles();
  
  const md = markdownit({
    html: false,
    linkify: true,
    typographer: true
  });
  
  const characterLimit = 500;
  const fullText = description ? description : 'No description';
  const previewText = fullText && fullText.length > characterLimit ? fullText.substring(0, characterLimit).concat('...') : fullText;

  const [expanded, setExpanded] = useState(false);

  const onTriggerExpand = () => {
    setExpanded(!expanded);
  }

  return (
    <Typography>
      <div dangerouslySetInnerHTML={{ __html: md.render(expanded ? fullText : previewText) }} />
      {fullText.length > characterLimit && 
        <div className={classes.expandContainer}>
          <div className={classes.innerExpandContainer} onClick={onTriggerExpand}>
            {!expanded ? <IntlMessages id="component.more" /> : <IntlMessages id="component.less" />}
            {!expanded ? <ExpandMore /> : <ExpandLess />}
          </div>
        </div>
      }
    </Typography>
  );
};

export default Description;
