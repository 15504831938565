import { smartContractFunctionCall } from 'smart_contract/services';
import { parseCareerInfo, parseGlobalCareerSettings } from './services';

const checkActiveUser = async userAddress => {
  try {
    const params = {
      user_address: userAddress,
    };

    const contractAdress = process.env.REACT_APP_RANK_SBT_CONTRACT_ADDRESS;
    const activeUser = await smartContractFunctionCall(contractAdress, 'checkActiveUser', 'RankSbtABI', params);
    return { error: false, activeUser };
  } catch (error) {
    return { error: error.message };
  }
};

const getUserStatus = async userAddress => {
  try {
    const params = {
      user_address: userAddress,
    };

    const contractAdress = process.env.REACT_APP_RANK_SBT_CONTRACT_ADDRESS;
    const userStatus = await smartContractFunctionCall(contractAdress, 'getUserStatus', 'RankSbtABI', params);
    return { error: false, userStatus };
  } catch (error) {
    return { error: error.message };
  }
};

const getCareerInfo = async (userID = 0, userAddress, enableMetaData = true) => {
  try {
    const params = {
      userID,
      userAddress,
      enable_metadata: enableMetaData,
    };

    const contractAdress = process.env.REACT_APP_RANK_SBT_CONTRACT_ADDRESS;
    const careerInfo = await smartContractFunctionCall(contractAdress, 'getCareerInfo', 'RankSbtABI', params);
    const parsedCareerInfo = parseCareerInfo(careerInfo);
    return { error: false, careerInfo: parsedCareerInfo };
  } catch (error) {
    return { error: error.message };
  }
};

const getGlobalCareerSettings = async () => {
  try {
    const params = {};

    const contractAdress = process.env.REACT_APP_RANK_SBT_CONTRACT_ADDRESS;
    const globalCareerSettings = await smartContractFunctionCall(
      contractAdress,
      'getGlobalCareerSettings',
      'RankSbtABI',
      params,
    );
    const parsedGlobalCareerSettings = parseGlobalCareerSettings(globalCareerSettings);
    return { error: false, globalCareerSettings: parsedGlobalCareerSettings };
  } catch (error) {
    return { error: error.message };
  }
};

const refreshUpgradeRank = async userAddress => {
  try {
    const params = {
      userAddress,
    };

    const contractAdress = process.env.REACT_APP_RANK_SBT_CONTRACT_ADDRESS;
    const data = await smartContractFunctionCall(contractAdress, 'refreshUpgradeRank', 'RankSbtABI', params, true);
    return { error: false, data };
  } catch (error) {
    return { error: error.message };
  }
};

export { checkActiveUser, getUserStatus, getCareerInfo, getGlobalCareerSettings, refreshUpgradeRank };
