import { getCustomTokenInfos } from 'db/functions';

const isPWD = (token, discountsInfos) => {
  return token === 'pwd' && Object.keys(discountsInfos).length > 0;
};

const setDisplayPercentageDecimal = number => {
  if (
    number.toString().includes('.') &&
    number
      .toFixed(1)
      .toString()
      .slice(-1) === '0'
  ) {
    return Number(number).toFixed();
  } else if (!number.toString().includes('.')) {
    return Number(number).toFixed();
  } else {
    return Number(number).toFixed(1);
  }
};

const loadTokenData = async configuredDiscounts => {
  const tokenDataJSON = require('../../MainTokens.json');

  const tokenData = [];
  const fallBack = tokenDataJSON.find(tokenData => tokenData.token === '0x0000000000000000000000000000000000000000');
  tokenData.push(fallBack);

  const length = configuredDiscounts.tokenAddresses.length;
  let i = 0;
  while (i < length) {
    const tokenAddress = configuredDiscounts.tokenAddresses[i];

    if (!tokenData.find(addedToken => addedToken.token === tokenAddress)) {
      let data;
      const localData = tokenDataJSON.find(tokenData => tokenData.token === tokenAddress);
      if (localData) {
        data = localData;
      } else {
        const request = await getCustomTokenInfos(tokenAddress);
        if (request.error) {
          return { error: request.error };
        }
        data = request.response;
      }

      tokenData.push(data);
    }

    i++;
  }

  return { error: false, tokenData };
};

const getTitleAndDescription = (textContent, locale) => {
  const textInLocaleIndex = textContent.findIndex(obj => obj.language === locale);
  
  let index;
  if (textInLocaleIndex > -1) {
    index = textInLocaleIndex;
  } else {
    index = 0;
  }

  const title = textContent[index].title;
  const description = textContent[index].description;
  return { title, description };
}

export { isPWD, setDisplayPercentageDecimal, loadTokenData, getTitleAndDescription };
