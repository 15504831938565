import React from 'react';
import { Typography } from '@material-ui/core';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { LockOutlined, LockOpen } from '@material-ui/icons';
import calculateSideMarginsToCenterModal from 'components/Functions/calculateSideMarginsToCenterModal';
import IntlMessages from '@jumbo/utils/IntlMessages';

const LevelsModal = ({ closeModal, modalIsOpen, level, levelsUnlocked = [] }) => {
  Modal.setAppElement(`#levels`);
  const screenWidth = window.innerWidth;
  const breakingPoint = 800;

  const modalStyles = {
    content: {
      zIndex: 4000,
      left: screenWidth > breakingPoint ? '8vw' : '0',
      marginTop: screenWidth > breakingPoint ? 120 : 0,
      height: screenWidth > breakingPoint ? '75vh' : '100vh',
      marginRight: calculateSideMarginsToCenterModal(900).marginRight,
      marginLeft: calculateSideMarginsToCenterModal(900).marginLeft,
      width: screenWidth > breakingPoint ? 800 : '100vw',
      maxWidth: 800,
      overflowY: 'scroll',
      position: 'absolute',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: screenWidth > breakingPoint ? 15 : 20,
    },
  };

  const handleModalClose = () => {
    closeModal();
  };

  const renderCriteriaContent = () => {
    switch (level) {
      case 0:
        return (
          <p>
            - <IntlMessages id={'component.powermadePromoterSubscription'} /> (<IntlMessages id={'component.package'} />{' '}
            {process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID}) <IntlMessages id={'component.purchasedAndNotExpired'} />
          </p>
        );

      case 1:
        return (
          <div>
            <p>
              - <IntlMessages id={'component.powermadePromoterSubscription'} /> (<IntlMessages id={'component.package'} />{' '}
              {process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID}) <IntlMessages id={'component.purchasedAndNotExpired'} />
            </p>
            <p>
              - <IntlMessages id={'component.levelsModal.criteria2'} />
            </p>
          </div>
        );

      case 2:
        return (
          <div>
            <p>
              - <IntlMessages id={'component.powermadePromoterSubscription'} /> (<IntlMessages id={'component.package'} />{' '}
              {process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID}) <IntlMessages id={'component.purchasedAndNotExpired'} />
            </p>
            <p>
              - <IntlMessages id={'component.levelsModal.criteria2'} />
            </p>
          </div>
        );

      case 3:
        return (
          <div>
            <p>
              - <IntlMessages id={'component.powermadePromoterSubscription'} /> (<IntlMessages id={'component.package'} />{' '}
              {process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID}) <IntlMessages id={'component.purchasedAndNotExpired'} />
            </p>
            <p>
              - <IntlMessages id={'component.levelsModal.criteria2'} />
            </p>
            <p>
              - <IntlMessages id={'component.levelsModal.criteria3'} />
            </p>
            <i>
              <p style={{ marginTop: 20 }}>
                <IntlMessages id={'component.leveldModal.criteria3.asterix'} />
              </p>
            </i>
          </div>
        );

      case 4:
        return (
          <div>
            <p>
              - <IntlMessages id={'component.powermadePromoterSubscription'} /> (<IntlMessages id={'component.package'} />{' '}
              {process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID}) <IntlMessages id={'component.purchasedAndNotExpired'} />
            </p>
            <p>
              - <IntlMessages id={'component.levelsModal.criteria2'} />
            </p>
            <p>
              - <IntlMessages id={'component.levelsModal.criteria4'} />
            </p>
            <i>
              <p style={{ marginTop: 20 }}>
                <IntlMessages id={'component.levelsModal.criteria4.asterix'} />
              </p>
            </i>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <Modal style={modalStyles} isOpen={modalIsOpen} contentLabel={'Level detailed info'} onRequestClose={handleModalClose}>
      <Button onClick={handleModalClose}>
        <IntlMessages id={'component.close'} />
      </Button>
      <CmtCardContent style={modalStyles.body}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography component="h1" variant="h1" style={{ fontSize: 25 }}>
            <IntlMessages id={'component.level'} /> {level + 1}
          </Typography>
          {levelsUnlocked[level] ? (
            <LockOpen style={{ marginLeft: 10, fontSize: 25 }} />
          ) : (
            <LockOutlined style={{ marginLeft: 10, fontSize: 25 }} />
          )}
        </div>
        <div style={{ textAlign: 'left' }}>
          <p style={{ marginTop: 10, fontWeight: 'bold' }}>
            ({levelsUnlocked[level] ? <IntlMessages id={'component.UNLOCKED'} /> : <IntlMessages id={'component.LOCKED'} />})
            <IntlMessages id={'component.levelsModal.header'} />:
          </p>
          {renderCriteriaContent()}
        </div>
      </CmtCardContent>
    </Modal>
  );
};

export default LevelsModal;
