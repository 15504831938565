import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoadButton from 'components/Common/LoadButton';
import IntlMessages from '@jumbo/utils/IntlMessages';
import IconModal from 'components/Common/IconModal';
import calculateSideMarginsToCenterModal from 'components/Functions/calculateSideMarginsToCenterModal';

const useOverviewStyles = makeStyles(theme => ({
  upgradeRankButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  overviewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    textWrap: 'wrap',
    [theme.breakpoints.down(830)]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  overviewLeft: {
    flex: 1,
  },
  overviewRight: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    marginRight: 50,
    [theme.breakpoints.down(830)]: {
      marginBottom: 20,
    },
  },
  metaDataDescription: {
    textWrap: 'wrap',
  },
  overviewImage: {
    height: '100%',
    width: '100%',
  },
  careerPoints: {
    marginBottom: '10px',
  },
  marginTop: {
    marginTop: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
  text: {
    fontSize: 20,
  },
  header: {
    fontSize: 30,
  },
  rankName: {
    whiteSpace: 'nowrap',
  },
}));

const OverviewSection = ({ careerInfo, generateRankNameFromRankLevel, onUpgradeRank, upgradingRank }) => {
  const classes = useOverviewStyles();

  const modalStyles = {
    content: {
      zIndex: 1,
      left: '8vw',
      marginTop: 120,
      marginRight: calculateSideMarginsToCenterModal(900).marginRight,
      marginLeft: calculateSideMarginsToCenterModal(900).marginLeft,
      width: 800,
      maxWidth: 800,
      overflowY: 'scroll',
    },
    body: {
      withData: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        textAlign: 'center',
      },
      noData: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#7a7b7d',
      },
      iconModal: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#7a7b7d',
      },
    },
    tableDiv: {
      width: '100%',
      marginTop: 20,
      table: {
        width: '100%',
        border: '2px solid black',
        borderCollapse: 'collapse',
        td: {
          height: 50,
          border: '2px solid black',
          borderCollapse: 'collapse',
          div: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
        textSmall: {
          fontSize: 10,
        },
        logo: {
          width: 25,
          height: 25,
          marginRight: 5,
        },
        currentIndex: {
          backgroundColor: '#f7cb78',
        },
      },
    },
  };

  const [iconModalOpen, setIconModalOpen] = useState(false);
  const [cursor, setCursor] = useState('default');
  const imageURL = `${careerInfo.metaData.image
    .replace('ipfs://', `https://${process.env.REACT_APP_IPFS_GATEWAY}/ipfs/`)
    .concat('?height=750&optimizer=image')}`;

  const onHover = () => {
    setCursor('zoom-in');
  };

  const onClick = () => {
    setIconModalOpen(true);
  };

  return (
    <div>
      <IconModal
        modalStyles={modalStyles}
        setIsOpen={setIconModalOpen}
        modalIsOpen={iconModalOpen}
        appElement={'#career_main'}
        icon={imageURL}
      />
      {!careerInfo.careerStarted && (
        <div className={classes.upgradeRankButtonContainer}>
          <LoadButton
            customStyle={{ width: 300, height: 50 }}
            disabled={upgradingRank}
            loading={upgradingRank}
            onClick={() => onUpgradeRank(careerInfo.eligibleRank)}
            messageID={'button.startCareer'}
          />
        </div>
      )}
      {careerInfo.careerStarted && (
        <div className={classes.overviewContainer}>
          <div className={classes.overviewLeft}>
            <div className={classes.careerPoints}>
              <h2 className={classes.header}>
                <IntlMessages id={'component.careerPoints'} />
                {`: ${careerInfo.careerPoints}`}
              </h2>
            </div>
            <p className={classes.text}>
              <IntlMessages id={'component.currentActiveRank'} />
              {': '}
              <strong>{careerInfo.metaData.attributes.rank}</strong>
            </p>
            <p className={classes.text}>
              <IntlMessages id={'component.eligibleRank'} />
              {': '}
              <strong>{generateRankNameFromRankLevel(careerInfo.eligibleRank)}</strong>
            </p>
            <div className={classes.marginTop}>
              <p className={classes.text}>
                <IntlMessages id={'component.nextRank'} />
                {': '}
                <strong>{generateRankNameFromRankLevel(careerInfo.nextRank)}</strong>
              </p>
              <p className={classes.text}>
                <IntlMessages id={'component.remainingPointsUntilNextRank'} />
                {': '}
                <strong>{careerInfo.nextRankPointsLeft}</strong>
              </p>
            </div>
            <div className={classes.marginTop}>
              <h2 className={classes.header}>
                <IntlMessages id={'component.totalPrizeReceived'} />
                {': '}
                <strong>{`$${careerInfo.totalPaidPrizes}`}</strong>
              </h2>
            </div>
          </div>
          <div className={classes.overviewRight} style={{ cursor }}>
            <div className={classes.marginBottom}>
              <h3 className={classes.rankName}>{careerInfo.metaData.name}</h3>
            </div>
            <img className={classes.overviewImage} src={imageURL} onMouseOver={onHover} onClick={onClick} />
            <div className={classes.marginTop}>
              <p className={classes.metaDataDescription}>
                <i>{careerInfo.metaData.description}</i>
              </p>
            </div>
            <div>
              {careerInfo.metaData.team && (
                <p>
                  <IntlMessages id={'component.team'} />
                  {': '}
                  <strong>{careerInfo.metaData.team}</strong>
                </p>
              )}
              {careerInfo.metaData.slogan && (
                <p>
                  <IntlMessages id={'component.slogan'} />
                  {': '}
                  <strong>{careerInfo.metaData.slogan}</strong>
                </p>
              )}
              {careerInfo.metaData.tokenID && (
                <p>
                  {'TokenID: '}
                  <strong>{careerInfo.metaData.tokenID}</strong>
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverviewSection;
