import { LOAD_PACKAGES } from '../../smart_contract/constants/ActionTypes';

export const loadPackages = packages => {
  return dispatch => {
    dispatch({
      type: LOAD_PACKAGES,
      payload: packages || [],
    });
  };
};
