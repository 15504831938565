import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import IntlMessages from '@jumbo/utils/IntlMessages';
import VideoBackground from 'components/Common/VideoBackground';
import ContentLoader from '@jumbo/components/ContentLoader';
import { getCareerInfo, getGlobalCareerSettings, refreshUpgradeRank } from 'smart_contract/career/functions';
import { fetchError, fetchStart, fetchSuccess, clearAlert } from 'redux/actions';
import { useDispatch } from 'react-redux';
import OverviewSection from 'components/CareerPage/OverviewSection';
import RankList from 'components/CareerPage/RankList';
import RankDetailsTable from 'components/CareerPage/RankDetailsTable';
import { addRankUpgradeTransaction, getRankUpgradeTransactionsByUserAddress, refreshNftMetaData } from 'db/functions';
import TransactionConfirmationModal from 'components/InfinityPoolPage/TransactionConfirmationModal';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: 70,
  },
}));

const CareerPage = () => {
  const breadcrumbs = [
    { label: <IntlMessages id={'sidebar.main'} />, link: '/home' },
    { label: <IntlMessages id={'pages.careerPage'} />, isActive: true },
  ];

  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const userAddress = authUser.userAddress;

  const [careerInfo, setCareerInfo] = useState({});
  const [globalCareerSettings, setGlobalCareerSettings] = useState({});
  const [selectedRank, setSelectedRank] = useState({});
  const [loadingFinished, setLoadingFinished] = useState(false);
  const [upgradingRank, setUpgradingRank] = useState(false);
  const [upgradeRankTransactions, setUpgradeRankTransactions] = useState([]);
  const [loadModals, setLoadModals] = useState(false);
  const [transactionConfirmationModalIsOpen, setTransactionConfirmationModalIsOpen] = useState(false);
  const [nftMetaDataRefreshed, setNftMetaDataRefreshed] = useState(false);

  useEffect(() => {
    const asyncFunction = async () => {
      dispatch(fetchStart());

      let response = await getCareerInfo(0, userAddress, true);
      if (response.error) {
        dispatch(fetchError(`Error - getCareerInfo: ${response.error}`));
        return;
      }
      const careerInfo = response.careerInfo;
      setCareerInfo(careerInfo);

      response = await getRankUpgradeTransactionsByUserAddress(userAddress);
      if (response.error) {
        dispatch(fetchError(`Error - getRankUpgradeTransactionsByUserAddress: ${response.error}`));
        return;
      }
      const transactions = response.transactions;
      setUpgradeRankTransactions(transactions);

      response = await getGlobalCareerSettings();
      if (response.error) {
        dispatch(fetchError(`Error - getGlobalCareerSettings: ${response.error}`));
        return;
      }
      const globalCareerSettings = response.globalCareerSettings;
      setGlobalCareerSettings(globalCareerSettings);
      setSelectedRank({
        ...globalCareerSettings.ranks[careerInfo.currentActiveRank],
        LV: careerInfo.currentActiveRank,
      });

      setLoadModals(true);
      setLoadingFinished(true);
      dispatch(fetchSuccess());
    };
    asyncFunction();
  }, []);

  const generateRankNameFromRankLevel = rankLevel => {
    return `LV. ${rankLevel} - ${globalCareerSettings.ranks[rankLevel].name}`;
  };

  const onUpgradeRank = async rank => {
    dispatch(fetchStart());
    setUpgradingRank(true);

    let response = await refreshUpgradeRank(userAddress);
    if (response.error) {
      dispatch(fetchError(`Error - refreshUpgradeRank: ${response.error}`));
      setUpgradingRank(false);
      return;
    }
    const transactionHash = response.data.transactionHash;
    await addRankUpgradeTransaction(userAddress, transactionHash, rank);

    response = await refreshNftMetaData(authUser.userID);
    let nftMetaDataRefreshed;
    if (response.error) {
      nftMetaDataRefreshed = false;
    } else if (response.status !== 200) {
      nftMetaDataRefreshed = false;
    } else {
      nftMetaDataRefreshed = true;
    }
    setNftMetaDataRefreshed(nftMetaDataRefreshed);

    response = await getCareerInfo(0, userAddress, true);
    if (response.error) {
      dispatch(fetchError(`Error - getCareerInfo: ${response.error}`));
      return;
    }
    const careerInfo = response.careerInfo;
    setCareerInfo(careerInfo);
    response = await getRankUpgradeTransactionsByUserAddress(userAddress);
    if (response.error) {
      dispatch(fetchError(`Error - getRankUpgradeTransactionsByUserAddress: ${response.error}`));
      return;
    }
    const transactions = response.transactions;
    dispatch(clearAlert('careerRankUpgradeAvailable'));
    setUpgradeRankTransactions(transactions);
    setUpgradingRank(false);
    setTransactionConfirmationModalIsOpen(true);
    dispatch(fetchSuccess());
  };

  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer
        id="purchases_container"
        heading={<IntlMessages id={'pages.careerPage.main.description'} />}
        breadcrumbs={breadcrumbs}>
        {loadModals && (
          <div>
            <TransactionConfirmationModal
              setIsOpen={setTransactionConfirmationModalIsOpen}
              modalIsOpen={transactionConfirmationModalIsOpen}
              nftMetaDataRefreshed={nftMetaDataRefreshed}
            />
          </div>
        )}
        <CmtCard id={'career_main'}>
          <CmtCardContent
            style={{
              paddingTop: 24,
              alignItems: 'center',
              whiteSpace: 'nowrap',
              zIndex: 0,
            }}>
            {!loadingFinished ? (
              <ContentLoader />
            ) : (
              <div>
                <OverviewSection
                  careerInfo={careerInfo}
                  generateRankNameFromRankLevel={generateRankNameFromRankLevel}
                  onUpgradeRank={onUpgradeRank}
                  upgradingRank={upgradingRank}
                />
                <Divider />
                <h2 className={classes.marginTop}>
                  <IntlMessages id={'component.ranksInfo'} />
                </h2>
                <RankList
                  ranks={globalCareerSettings.ranks}
                  selectedRank={selectedRank}
                  onSelectRank={setSelectedRank}
                  onUpgradeRank={onUpgradeRank}
                  upgradingRank={upgradingRank}
                  careerInfo={careerInfo}
                />
                <Divider />
                <RankDetailsTable
                  careerStarted={careerInfo.careerStarted}
                  selectedRank={selectedRank}
                  currentActiveRank={careerInfo.currentActiveRank}
                  upgradeRankTransactions={upgradeRankTransactions}
                />
              </div>
            )}
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </div>
  );
};

export default CareerPage;
