import React, { useState } from 'react';
import VideoBackground from 'components/Common/VideoBackground';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import LoadButton from 'components/Common/LoadButton';
import { Moralis } from 'moralis';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchSuccess } from 'redux/actions';

const useStyles = makeStyles(theme => ({
  containerCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
  },
  innerContainerCard: {
    maxWidth: 600,
  },
  header: {
    padding: 0,
    paddingBottom: 10,
  },
  marginTop: {
    marginTop: 20,
  },
  textFieldRoot: {
    width: 600,
    maxWidth: 600,
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.copytradingRegistration'} />, isActive: true },
];

const CopytradingFormPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);

  const [accountNumber, setAccountNumber] = useState('');
  const [password, setPassword] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);

  const onSubmit = async () => {
    setButtonLoading(true);
    const userID = authUser.userID;
    const response = await Moralis.Cloud.run('submitCopytradingForm', { userID, accountNumber, password });
    if (response.success) {
      setSubmitSuccessful(true);
      dispatch(fetchSuccess(<IntlMessages id="success.submitCopytradingForm" />));
    } else {
      setButtonLoading(false);
      dispatch(fetchError(`${<IntlMessages id="error.submitCopytradingForm" />}: ${response.error}`));
    }
  };

  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer heading={<IntlMessages id="pages.copytradingRegistration" />} breadcrumbs={breadcrumbs}>
        <CmtCard className={classes.containerCard}>
          <CmtCardContent className={classes.innerContainerCard}>
            <CmtCardHeader className={classes.header} title={'Copytrading registration form'} />
            <form>
              <Box mb={2}>
                <TextField
                  label={<IntlMessages id="appModule.accountNumber" />}
                  fullWidth
                  defaultValue={''}
                  value={accountNumber}
                  onChange={event => setAccountNumber(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  disabled={submitSuccessful || buttonLoading}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  defaultValue={''}
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  disabled={submitSuccessful || buttonLoading}
                />
              </Box>
              <div className={classes.marginTop}></div>
              { !submitSuccessful &&
                <LoadButton
                  loading={buttonLoading}
                  disabled={buttonLoading}
                  onClick={onSubmit}
                  messageID={'button.submit'}
                />
              }
            </form>
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </div>
  );
};

export default CopytradingFormPage;
