import React from 'react';
import VideoBackground from 'components/Common/VideoBackground';

const HomePage = () => {
  const muteVideo = process.env.REACT_APP_MUTE_HOME_VIDEO === 'true';

  return (
    <div>
      <VideoBackground videoSrc="/background.mp4" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100vh',
          justifyContent: 'center',
        }}>
        <video controls controlsList="nodownload" style={{ maxWidth: '80vw' }} width="1000px" autoPlay muted={muteVideo}>
          <source src="/video/home/home_video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default HomePage;
