import React from 'react';
import web3 from 'web3';
import { allowance } from 'smart_contract/functions';

const checkAllowance = async (token, packagePrice, discountedPricePWD, userAddress) => {
  let tokenAddr;
  let spender;
  let priceToPay;
  if (token === 'busd') {
    tokenAddr = process.env.REACT_APP_BUSD_TOKEN_ADDRESS;
    spender = process.env.REACT_APP_POWERMADE_AFFILIATION_CONTRACT_ADDRESS;
    priceToPay = Number(web3.utils.fromWei(packagePrice));
  } else if (token === 'pwd') {
    tokenAddr = process.env.REACT_APP_PWD_TOKEN_ADDRESS;
    spender = process.env.REACT_APP_DISCOUNT_WRAPPER_STANDALONE_CONTRACT_ADDRESS;
    priceToPay = Number(web3.utils.fromWei(discountedPricePWD));
  }
  let request = await allowance(userAddress, tokenAddr, spender);
  if (request.error) {
    return true;
  }
  const currentAllowance = Number(request.allowance);
  return priceToPay > currentAllowance;
};

export { checkAllowance };
