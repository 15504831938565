import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CmtCard from '@coremat/CmtCard';
import { getChangelogData } from 'db/functions';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 120,
    width: '90vw',
  },
  tableContainer: {
    maxHeight: '60vh',
    overflow: 'scroll',
  },
  tableHeaderCell: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}));

const Changelog = () => {
  const classes = useStyles();

  const [data, setData] = useState([]);

  useEffect(() => {
    const asyncFunction = async () => {
      const changelog = await getChangelogData();
      setData(changelog);
    };
    asyncFunction();
  }, []);

  return (
    <CmtCard className={classes.container}>
      <Typography variant="h1" component={'h1'} style={{ padding: 20 }}>
        Changelog
      </Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('purchaseID')}>
                Date
              </TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('packageID')}>
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((log, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{moment(log.date).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{log.description}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </CmtCard>
  );
};

export default Changelog;
