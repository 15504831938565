import { smartContractFunctionCall } from 'smart_contract/services';
import { parseJoinClaimConditionsInfo, parsePoolInfo, parseCycleData, parseUserInfoAndEndedCycles } from './services';

const getPoolInfo = async userAddress => {
  try {
    const params = {
      user_address: userAddress,
    };

    const contractAdress = process.env.REACT_APP_ACTIVITY_POOL_CONTRACT_ADDRESS;
    const poolInfo = await smartContractFunctionCall(contractAdress, 'getPoolInfo', 'ActivityPoolABI', params);
    const parsedPoolInfo = parsePoolInfo(poolInfo);
    return { error: false, poolInfo: parsedPoolInfo };
  } catch (error) {
    return { error: error.message };
  }
};

const getJoinClaimConditionsInfo = async userAddress => {
  try {
    const params = {
      user_address: userAddress,
    };

    const contractAdress = process.env.REACT_APP_ACTIVITY_POOL_CONTRACT_ADDRESS;
    const joinClaimConditionsInfo = await smartContractFunctionCall(
      contractAdress,
      'getJoinClaimConditionsInfo',
      'ActivityPoolABI',
      params,
    );
    const parsedJoinClaimConditionsInfo = parseJoinClaimConditionsInfo(joinClaimConditionsInfo);
    return {
      error: false,
      joinClaimConditionsInfo: parsedJoinClaimConditionsInfo,
    };
  } catch (error) {
    return { error: error.message };
  }
};

const getCycleData = async (startID = 1, stopID = 999999999) => {
  try {
    const params = {
      startID,
      stopID,
    };

    const contractAdress = process.env.REACT_APP_ACTIVITY_POOL_CONTRACT_ADDRESS;
    const cycleData = await smartContractFunctionCall(contractAdress, 'getCycleData', 'ActivityPoolABI', params);
    const parsedCycleData = parseCycleData(cycleData);
    return { error: false, cycleData: parsedCycleData };
  } catch (error) {
    return { error: error.message };
  }
};

const getUserInfoAndEndedCycles = async (
  userAddress,
  endedStartIndex = 1,
  endedStopIndex = 999999999,
  endedOnlyLast = false,
) => {
  try {
    const params = {
      user_address: userAddress,
      ended_start_index: endedStartIndex,
      ended_stop_index: endedStopIndex,
      ended_only_last: endedOnlyLast,
    };

    const contractAdress = process.env.REACT_APP_ACTIVITY_POOL_CONTRACT_ADDRESS;
    const userInfoAndEndedCycles = await smartContractFunctionCall(
      contractAdress,
      'getUserInfoAndEndedCycles',
      'ActivityPoolABI',
      params,
    );
    const parsedUserInfoAndEndedCycles = parseUserInfoAndEndedCycles(userInfoAndEndedCycles);
    return { error: false, userInfoAndEndedCycles: parsedUserInfoAndEndedCycles };
  } catch (error) {
    return { error: error.message };
  }
};

const depositPool = async amountWei => {
  try {
    const params = {
      amount_wei: amountWei,
    };

    const contractAdress = process.env.REACT_APP_ACTIVITY_POOL_CONTRACT_ADDRESS;
    const data = await smartContractFunctionCall(contractAdress, 'depositPool', 'ActivityPoolABI', params, true);
    return { error: false, data };
  } catch (error) {
    return { error: error.message };
  }
};

const join = async () => {
  try {
    const params = {};

    const contractAdress = process.env.REACT_APP_ACTIVITY_POOL_CONTRACT_ADDRESS;
    const data = await smartContractFunctionCall(contractAdress, 'join', 'ActivityPoolABI', params, true);
    return { error: false, data };
  } catch (error) {
    return { error: error.message };
  }
};

const claim = async () => {
  try {
    const params = {};

    const contractAdress = process.env.REACT_APP_ACTIVITY_POOL_CONTRACT_ADDRESS;
    const data = await smartContractFunctionCall(contractAdress, 'claim', 'ActivityPoolABI', params, true);
    return { error: false, data };
  } catch (error) {
    return { error: error.message };
  }
};

export { getPoolInfo, getJoinClaimConditionsInfo, getCycleData, getUserInfoAndEndedCycles, depositPool, join, claim };
