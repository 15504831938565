import React from 'react';
import PackagesPage from 'components/PackagesPage';

const CoursesPage = () => {
  return (
    <div>
      <PackagesPage
        category={'courses'}
        messageID={'pages.products.coursesPage'}
        descriptionID={'pages.products.coursesPage.description'}
      />
    </div>
  );
};

export default CoursesPage;
