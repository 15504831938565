import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../../utils/IntlMessages';
import LoadButton from 'components/Common/LoadButton';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

const PageOne = ({
  variant = 'default',
  userAddress,
  email,
  setEmail,
  username,
  setUsername,
  telegram,
  setTelegram,
  firstname,
  setFirstname,
  lastname,
  setLastname,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  phone,
  setPhone,
  setAcceptedDataPolicy,
  acceptedDataPolicy,
  handleRegistration,
}) => {
  const classes = useStyles({ variant });
  const [buttonLoading, setButtonLoading] = useState(false);

  return (
    <form>
      <Box mb={2}>
        <TextField
          label={<IntlMessages id="appModule.address" />}
          fullWidth
          defaultValue={userAddress}
          margin="normal"
          variant="outlined"
          className={classes.textFieldRoot}
          disabled
        />
      </Box>
      <Box mb={2}>
        <TextField
          type="email"
          label={<IntlMessages id="appModule.email" />}
          fullWidth
          onChange={event => setEmail(event.target.value)}
          defaultValue={email}
          margin="normal"
          variant="outlined"
          className={classes.textFieldRoot}
        />
      </Box>
      <Box mb={2}>
        <TextField
          type="text"
          label={<IntlMessages id="appModule.username" />}
          fullWidth
          onChange={event => setUsername(event.target.value)}
          defaultValue={username}
          margin="normal"
          variant="outlined"
          className={classes.textFieldRoot}
        />
      </Box>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box mb={2}>
          <TextField
            type="password"
            label={<IntlMessages id="appModule.password" />}
            fullWidth
            onChange={event => setPassword(event.target.value)}
            defaultValue={password}
            margin="normal"
            variant="outlined"
            className={classes.textFieldRoot}
          />
        </Box>
        <Box mb={2}>
          <TextField
            type="password"
            label={<IntlMessages id="appModule.confirmPassword" />}
            fullWidth
            onChange={event => setConfirmPassword(event.target.value)}
            defaultValue={confirmPassword}
            margin="normal"
            variant="outlined"
            className={classes.textFieldRoot}
          />
        </Box>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box mb={2}>
          <TextField
            type="text"
            label={<IntlMessages id="appModule.telegram" />}
            fullWidth
            onChange={event => setTelegram(event.target.value)}
            defaultValue={telegram}
            margin="normal"
            variant="outlined"
            className={classes.textFieldRoot}
          />
        </Box>
        <Box mb={2}>
          <TextField
            type="tel"
            label={<IntlMessages id="appModule.phone" />}
            fullWidth
            onChange={event => setPhone(event.target.value)}
            defaultValue={phone}
            margin="normal"
            variant="outlined"
            className={classes.textFieldRoot}
          />
        </Box>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box mb={2}>
          <TextField
            type="text"
            label={<IntlMessages id="appModule.firstname" />}
            fullWidth
            onChange={event => setFirstname(event.target.value)}
            defaultValue={firstname}
            margin="normal"
            variant="outlined"
            className={classes.textFieldRoot}
          />
        </Box>
        <Box mb={2}>
          <TextField
            type="text"
            label={<IntlMessages id="appModule.lastname" />}
            fullWidth
            onChange={event => setLastname(event.target.value)}
            defaultValue={lastname}
            margin="normal"
            variant="outlined"
            className={classes.textFieldRoot}
          />
        </Box>
      </div>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ sm: 'center' }}
        justifyContent={{ sm: 'space-between' }}
        mb={3}>
        <Box mb={{ xs: 2, sm: 0 }} style={{ display: 'flex', alignItems: 'center' }}>
          <LoadButton
            className={classes.titleRoot}
            loading={buttonLoading}
            disabled={!acceptedDataPolicy || buttonLoading}
            onClick={() => {
              if (acceptedDataPolicy) {
                handleRegistration(setButtonLoading);
              }
            }}
            messageID={'appModule.next'}
          />
          <FormControlLabel
            style={{ marginLeft: 5 }}
            label={<IntlMessages id="appModule.acceptUserDataPolicy" />}
            control={
              <Checkbox
                id={'false'}
                color={'primary'}
                checked={acceptedDataPolicy}
                onChange={event => setAcceptedDataPolicy(event.target.checked)}
                defaultValue={false}
                margin="normal"
                variant="outlined"
              />
            }
          />
        </Box>
        <Box mb={2}>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            1/3
          </Typography>
        </Box>
      </Box>
    </form>
  );
};

export default PageOne;
