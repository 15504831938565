import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 10,
    background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
    textShadow: '2px 2px 1px #311b1b',
    color: '#FFFFFF',
    backgroundColor: '#e0e0e0',
    whiteSpace: 'nowrap',
  },
}));

const SubscriptionPurchaseButton = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  if (!authUser) {
    return <div />;
  }
  let expiration;
  if (authUser && authUser.expirationTimestamp) {
    expiration = authUser.expirationTimestamp * 1000;
  }

  const expirationTimestamp = authUser.expirationTimestamp * 1000;
  const momentObj = moment(expirationTimestamp);
  const now = moment();
  let expired = false;
  if (momentObj.isBefore(now)) {
    expired = true;
  }

  const link = `/packages?packageQuery=ID:${process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID}&popup=no`;
  return (
    <div>
      {!expiration && (
        <Button onClick={() => (window.location.href = link)} className={classes.button}>
          Buy Promoter Subscription
        </Button>
      )}
      {expired && (
        <Button onClick={() => (window.location.href = link)} className={classes.button}>
          <IntlMessages id={'button.renewSubscription'} />
        </Button>
      )}
    </div>
  );
};

export default SubscriptionPurchaseButton;
