import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useRankDetailsStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
}));

const RankDetailsTable = ({ careerStarted, selectedRank, currentActiveRank, upgradeRankTransactions }) => {
  const classes = useRankDetailsStyles();

  const isUnlocked = careerStarted && selectedRank.LV <= currentActiveRank;

  const renderTransactionHashLink = () => {
    if (!isUnlocked) {
      return 'N/A';
    }

    // --- START ---
    // Remove after integrating "Advanced main career page functions".
    // Currently not able to save transaction for rank 0 since we are not using events
    // to fetch the transactionHash:
    if (selectedRank.LV === 0) {
      return 'N/A';
    }
    // --- END ---

    const upgradeRankTransaction = upgradeRankTransactions.find(transaction => {
      return transaction.rank >= selectedRank.LV;
    });
    const transactionHash =
      upgradeRankTransaction && upgradeRankTransaction.transactionHash ? upgradeRankTransaction.transactionHash : undefined;
    if (transactionHash) {
      const href = `${process.env.REACT_APP_BSCSCAN_LINK}${transactionHash}`;
      return (
        <a target="_blank" href={href} rel="noreferrer">
          LINK
        </a>
      );
    } else {
      return <p>N/A</p>;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="rank details table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>LV</strong>
            </TableCell>
            <TableCell>
              <strong>
                <IntlMessages id={'component.name'} />
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                <IntlMessages id={'component.unlocked'} />
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                <IntlMessages id={'component.transaction'} />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id={'component.prize'} />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id={'component.pwdPercentage'} />
              </strong>
            </TableCell>
            <TableCell align="right">
              <strong>
                <IntlMessages id={'component.threshold'} />
              </strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedRank ? (
            <TableRow>
              <TableCell>{selectedRank.LV}</TableCell>
              <TableCell>{selectedRank.name}</TableCell>
              <TableCell>
                {isUnlocked ? <IntlMessages id={'component.yes'} /> : <IntlMessages id={'component.no'} />}
              </TableCell>
              <TableCell>{renderTransactionHashLink()}</TableCell>
              <TableCell align="right">${selectedRank.prize.toFixed(2)}</TableCell>
              <TableCell align="right">{selectedRank.pwdPercentage}%</TableCell>
              <TableCell align="right">
                {Math.floor(selectedRank.threshold)} <IntlMessages id={'component.points'} />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <IntlMessages id={'component.noRankSelected'} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RankDetailsTable;
