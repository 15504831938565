import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants';

import { IntlProvider } from 'react-intl';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import rtl from 'jss-rtl';

import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme, jssPreset, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppContext from '../contextProvider/AppContextProvider/AppContext';
import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';
import Moralis from 'moralis';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const AppWrapper = ({ children }) => {
  const { locale, theme } = useContext(AppContext);

  const dispatch = useDispatch();

  Moralis.onWeb3Deactivated(() => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  });
  Moralis.onChainChanged(() => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  });
  Moralis.onAccountChanged(() => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  });

  if (localStorage.getItem('link_on_reload')) {
    if (
      window.performance
        .getEntriesByType('navigation')
        .map(nav => nav.type)
        .includes('reload')
    ) {
      const link = JSON.parse(localStorage.getItem('link_on_reload'));
      window.location.assign(link);
    }
    localStorage.removeItem('link_on_reload');
  }

  const muiTheme = useMemo(() => {
    return createTheme(theme);
  }, [theme]);

  return (
    <IntlProvider locale={AppLocale[locale.locale].locale} messages={AppLocale[locale.locale].messages}>
      <ThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StylesProvider jss={jss}>
            <CssBaseline />
            <AppLayout>{children}</AppLayout>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default AppWrapper;
