import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Switch,
  Grid,
} from '@material-ui/core';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
    width: '90vw',
  },
  tableContainer: {
    maxHeight: '50vh',
    overflow: 'scroll',
  },
  tableHeaderCell: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  clickableCell: {
    cursor: 'pointer',
    color: '#1976d2',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  searchFieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  searchField: {
    margin: '10px',
    width: 'calc(33.33% - 20px)',
    '@media (max-width: 900px)': {
      width: 'calc(50% - 20px)',
    },
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
}));

const UsersInfoTable = ({ users, goToUserView }) => {
  const classes = useStyles();

  const [searchUserID, setSearchUserID] = useState('');
  const [searchUsername, setSearchUsername] = useState('');
  const [searchUserAddress, setSearchUserAddress] = useState('');
  const [searchSponsorID, setSearchSponsorID] = useState('');
  const [searchParentID, setSearchParentID] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'userID',
    direction: 'ascending',
  });

  const filteredAndSortedUsers = React.useMemo(() => {
    const filteredUsers = users.filter(user => {
      const isActiveUser = !showActiveOnly || user.isActive;
      const matchesUserID =
        searchUserID.trim() === '' || user.userID.toString().toLowerCase() === searchUserID.trim().toLowerCase();
      const matchesUsername =
        searchUsername.trim() === '' || user.username.toLowerCase() === searchUsername.trim().toLowerCase();
      const matchesSponsorID =
        searchSponsorID.trim() === '' || user.sponsorID.toString().toLowerCase() === searchSponsorID.trim().toLowerCase();
      const matchesParentID =
        searchParentID.trim() === '' || user.parentID.toString().toLowerCase() === searchParentID.trim().toLowerCase();

      const matchesUserAddress = (user.ethAddress || '').toLowerCase().includes(searchUserAddress.toLowerCase());

      const userJoinedDate = moment(user.joinedTimestamp);
      const matchesFromDate = fromDate ? userJoinedDate.isSameOrAfter(fromDate, 'day') : true;
      const matchesToDate = toDate ? userJoinedDate.isSameOrBefore(toDate, 'day') : true;

      return (
        isActiveUser &&
        matchesUserID &&
        matchesUsername &&
        matchesUserAddress &&
        matchesSponsorID &&
        matchesParentID &&
        matchesFromDate &&
        matchesToDate
      );
    });

    return filteredUsers.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, [
    users,
    showActiveOnly,
    searchUserID,
    searchUsername,
    searchUserAddress,
    searchSponsorID,
    searchParentID,
    fromDate,
    toDate,
    sortConfig,
  ]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <CmtCard className={classes.container}>
      <Typography variant="h1" component={'h1'} style={{ padding: 20 }}>
        Users Info
      </Typography>
      <CmtCardContent className={classes.searchFieldsContainer}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search by User ID"
          onChange={e => setSearchUserID(e.target.value)}
        />
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search by Username"
          onChange={e => setSearchUsername(e.target.value)}
        />
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search by User Address"
          onChange={e => setSearchUserAddress(e.target.value)}
        />
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search by Sponsor ID"
          onChange={e => setSearchSponsorID(e.target.value)}
        />
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search by Parent ID"
          onChange={e => setSearchParentID(e.target.value)}
        />
      </CmtCardContent>
      <CmtCardContent className={classes.searchFieldsContainer}>
        <KeyboardDatePicker
          className={classes.searchField}
          disableToolbar
          variant="inline"
          format="YYYY-MM-DD"
          margin="normal"
          label="Joined From Date"
          value={fromDate}
          onChange={setFromDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          className={classes.searchField}
          disableToolbar
          variant="inline"
          format="YYYY-MM-DD"
          margin="normal"
          label="Joined To Date"
          value={toDate}
          onChange={setToDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <div style={{ marginTop: 16 }}>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>All Users</Grid>
              <Grid item>
                <Switch
                  checked={showActiveOnly}
                  onChange={e => setShowActiveOnly(e.target.checked)}
                  name="showActiveOnly"
                  color="primary"
                />
              </Grid>
              <Grid item>Active Users Only</Grid>
            </Grid>
          </Typography>
        </div>
      </CmtCardContent>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('userID')}>
                ID
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Username</TableCell>
              <TableCell className={classes.tableHeaderCell}>Email</TableCell>
              <TableCell className={classes.tableHeaderCell}>Address</TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('joinedTimestamp')}>
                Joined
              </TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('active')}>
                Active
              </TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('totalEarned')}>
                Total Earned
              </TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('sponsorID')}>
                SponsorID
              </TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('parentID')}>
                ParentID
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedUsers.map(user => (
              <TableRow key={user.userID}>
                <TableCell
                  className={user.userID !== 1 ? classes.clickableCell : undefined}
                  onClick={() => user.userID !== 1 && goToUserView(user.userID)}>
                  {user.userID}
                </TableCell>
                <TableCell
                  className={user.userID !== 1 ? classes.clickableCell : undefined}
                  onClick={() => user.userID !== 1 && goToUserView(user.userID)}>
                  {user.username}
                </TableCell>
                <TableCell
                  className={user.userID !== 1 ? classes.clickableCell : undefined}
                  onClick={() => user.userID !== 1 && goToUserView(user.userID)}>
                  {user.email}
                </TableCell>
                <TableCell
                  className={user.userID !== 1 ? classes.clickableCell : undefined}
                  onClick={() => user.userID !== 1 && goToUserView(user.userID)}>
                  {user.ethAddress}
                </TableCell>
                <TableCell>{moment(user.joinedTimestamp).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{user.isActive ? 'Yes' : 'No'}</TableCell>
                <TableCell>${user.totalEarned}</TableCell>
                <TableCell
                  className={user.sponsorID !== 1 ? classes.clickableCell : undefined}
                  onClick={() => user.sponsorID !== 1 && goToUserView(user.sponsorID)}>
                  {user.sponsorID}
                </TableCell>
                <TableCell
                  className={user.parentID !== 1 ? classes.clickableCell : undefined}
                  onClick={() => user.parentID !== 1 && goToUserView(user.parentID)}>
                  {user.parentID}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h1" component={'h1'} style={{ padding: 20 }}>
        Result: {filteredAndSortedUsers.length}
      </Typography>
    </CmtCard>
  );
};

export default UsersInfoTable;
