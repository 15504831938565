import React from 'react';
import Criteria from 'components/Common/Pools/Criteria';
import IntlMessages from '@jumbo/utils/IntlMessages';

const ClaimCriterias = ({ joinClaimConditionsInfo, activityPoolSettings }) => {
  const userBanned = !joinClaimConditionsInfo.noBanSatisfied;

  const criteria = [
    {
      text: <IntlMessages id={'component.activePromoter'} />,
      conditionMet: joinClaimConditionsInfo.promoterActiveSatisfied,
    },
    {
      text: (
        <div>
          <IntlMessages id={'component.equivalentUsdInPwd'} /> (${joinClaimConditionsInfo.pwdUsdUnitsAmount.toFixed(2)}/$
          {activityPoolSettings.minPWDeqUSDamount.toFixed(2)})
        </div>
      ),
      conditionMet: joinClaimConditionsInfo.pwdUsdSatisfied,
    },
  ];

  return (
    <div>
      <h3>
        <IntlMessages id={'component.criteriaForClaiming'} />
      </h3>
      {!userBanned ? (
        criteria.map((criterion, index) => (
          <Criteria key={index} text={criterion.text} conditionMet={criterion.conditionMet} />
        ))
      ) : (
        <Criteria text={<IntlMessages id={'component.userNotApproved'} />} conditionMet={false} />
      )}
    </div>
  );
};

export default ClaimCriterias;
