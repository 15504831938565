import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({}));

const CountdownTimer = ({ targetTimestamp, endsIn, endsOn, endedOn, timeFormat }) => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = moment();
    const target = moment(targetTimestamp);
    const duration = moment.duration(target.diff(now));
    const hourText = duration.hours() === 1 ? <IntlMessages id="component.hour" /> : <IntlMessages id="component.hours" />;
    const minuteText =
      duration.minutes() === 1 ? <IntlMessages id="component.minute" /> : <IntlMessages id="component.minutes" />;

    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
      hourText,
      minuteText,
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear interval if the component is unmounted or the countdown is over
    return () => clearInterval(timer);
  }, []);

  // If we're past the target time, render an expiration message
  if (timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0) {
    const formattedMoment = moment(targetTimestamp).format(timeFormat);
    return (
      <div style={{ color: 'red' }}>
        <p>
          {endedOn}: {formattedMoment}
        </p>
      </div>
    );
  }

  // If more than 24 hours are left, display the end date instead of the countdown
  if (timeLeft.days > 0) {
    const formattedMoment = moment(targetTimestamp).format(timeFormat);
    return (
      <div>
        {endsOn}: {formattedMoment}
      </div>
    );
  }

  // Render the countdown
  return (
    <div>
      {endsIn}: {timeLeft.hours} {timeLeft.hourText} <IntlMessages id={'component.and'} /> {timeLeft.minutes}{' '}
      {timeLeft.minuteText}
    </div>
  );
};

export default CountdownTimer;
