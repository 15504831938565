/*import Firebase from './firebase';
import JWTAuth from './jwt';
import BasicAuth from './Basic';*/
import MoralisAuth from './moralis';

export const AuhMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,
  basic: BasicAuth,*/
  moralis: MoralisAuth,
};
