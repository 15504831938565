import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import { MoralisProvider } from 'react-moralis';
import Moralis from 'moralis';
import { CookiesProvider } from 'react-cookie';

export const store = configureStore();

const App = () => {
  const scaleStyles = {
    zoom: '90%',
  };

  useEffect(() => {
    Moralis.start({
      serverUrl: process.env.REACT_APP_SERVER_URL,
      appId: process.env.REACT_APP_APP_ID,
    });
  }, []);

  // This is in place to clear server token one time.
  // This was used for the transitoin to self host from Moralis Cloud
  const cleared = localStorage.getItem('cleared');
  if (cleared !== '2') {
    localStorage.clear();
    localStorage.setItem('cleared', 2);
  }
  // This is in place to clear server token one time.
  // This was used for the transitoin to self host from Moralis Cloud

  return (
    <MoralisProvider serverUrl={process.env.REACT_APP_SERVER_URL} appId={process.env.REACT_APP_APP_ID}>
      <CookiesProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppContextProvider>
              <div style={scaleStyles}>
                <AppWrapper>
                  <Switch>
                    <Routes />
                  </Switch>
                </AppWrapper>
              </div>
            </AppContextProvider>
          </ConnectedRouter>
        </Provider>
      </CookiesProvider>
    </MoralisProvider>
  );
};

export default App;
