import React from 'react';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

const IconModal = ({ modalStyles, setIsOpen, modalIsOpen, appElement, icon }) => {
  Modal.setAppElement(appElement);

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal style={modalStyles} isOpen={modalIsOpen} contentLabel={'Level detailed info'} onRequestClose={handleModalClose}>
      <Button onClick={handleModalClose}>Close</Button>
      <CmtCardContent style={modalStyles.body.iconModal}>
        <img style={{ width: 500, maxWidth: '90vw' }} src={icon} />
      </CmtCardContent>
    </Modal>
  );
};

export default IconModal;
