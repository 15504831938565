import React, { useEffect, useState } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import User from '../../../components/NetworkPage/User';
import NetworkTreeDesktop from '../../../components/NetworkPage/NetworkTreeDesktop';
import NetworkTreeMobile from '../../../components/NetworkPage/NetworkTreeMobile';
import { useSelector, useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess, loadNetworkUsers, clearAlert } from '../../../redux/actions';
import { getNetworkTree, getFullNetwork } from 'db/functions';
import Xarrow from 'react-xarrows';
import { getUsernames, getSubscriptionExpirations, getDirectOrSpillover } from 'db/functions';
import { Typography } from '@material-ui/core';
import VideoBackground from 'components/Common/VideoBackground';
import { handleLoadNewDownlineUsers } from 'components/Functions/NetworkPage/functions';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.networkPage'} />, isActive: true },
];

const NetworkPage = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const networkUsers = useSelector(({ networkUsers }) => networkUsers);
  const [usernames, setUsernames] = useState(undefined);
  const [subscriptionExpirations, setSubscriptionExpirations] = useState(undefined);
  const [directOrSpillover, setDirectOrSpillover] = useState(undefined);
  const [userHistory, setUserHistory] = useState([]);
  const [userHistoryForward, setUserHistoryForward] = useState([]);
  const [render, setRender] = useState(true);
  const [authUserDownline, setAuthUserDownline] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const screenWidth = window.innerWidth;
  const desktopBreakingPoint = screenWidth > 580;
  const dispatch = useDispatch();
  useEffect(() => {
    const loadData = async () => {
      const fullNetwork = await getFullNetwork(authUser.userID);
      const newUsers = handleLoadNewDownlineUsers(fullNetwork, true);
      setNewUsers(newUsers);
      setAuthUserDownline(fullNetwork.flat());
      dispatch(clearAlert('NewDownlineUsers'));
      await getTree(authUser.userID);
    };
    loadData();
  }, [dispatch]);

  const reRenderComponent = () => {
    setRender(false);
    setRender(true);
  };

  const getTree = async (id = 0) => {
    dispatch(fetchStart());
    const request = await getNetworkTree(id);
    if (request.error) {
      if (request.error === "Cannot read properties of undefined (reading 'get')") {
        dispatch(fetchError(<IntlMessages id={'error.idNotInNetwork'} />));
      } else {
        dispatch(fetchError(request.error));
      }
    }
    const { networkTree } = request;

    // Set userInfo - START
    const userIDs = [];
    networkTree.downline.forEach(userID => {
      userIDs.push(userID.toString());
    });
    networkTree.invitedUsers.forEach(userID => {
      userIDs.push(userID.toString());
    });
    for (const userID in networkTree.nestedDownlines) {
      const nestedDownline = networkTree.nestedDownlines[userID];
      nestedDownline.forEach(userID => {
        userIDs.push(userID.toString());
      });
    }
    userIDs.push(networkTree.source ? networkTree.source.toString() : '');
    const { usernames } = await getUsernames(userIDs);
    const { subscriptionExpirations } = await getSubscriptionExpirations(userIDs);
    const { directOrSpillover } = await getDirectOrSpillover(userIDs);
    setUsernames(usernames);
    setSubscriptionExpirations(subscriptionExpirations);
    setDirectOrSpillover(directOrSpillover);
    // Set userInfo - END

    dispatch(loadNetworkUsers(networkTree));
    reRenderComponent();
    dispatch(fetchSuccess());
  };

  const handleGoBack = async () => {
    if (userHistory.length > 0) {
      const newArray = [...userHistory];
      const previousID = newArray.pop();
      setUserHistoryForward([...userHistoryForward, networkUsers.source]);
      setUserHistory(newArray);
      await getTree(previousID);
    }
  };

  const handleGoForward = async () => {
    if (userHistoryForward.length > 0) {
      const newArray = [...userHistoryForward];
      const previousID = newArray.pop();
      setUserHistory([...userHistory, networkUsers.source]);
      setUserHistoryForward(newArray);
      await getTree(previousID);
    }
  };

  const handleGoMe = async () => {
    setUserHistory([...userHistory, networkUsers.source]);
    setUserHistoryForward([]);
    await getTree(authUser.userID);
  };

  const handleSearch = async value => {
    if (process.env.REACT_APP_CHAIN_ID !== '0x61') {
      if (!authUserDownline.find(userID => userID === value) && authUser.userID.toString() !== value) {
        dispatch(fetchError(<IntlMessages id={'error.idNotInNetwork'} />));
        return;
      }
    }

    setUserHistory([...userHistory, networkUsers.source]);
    setUserHistoryForward([]);
    if (!value) {
      await getTree();
    } else {
      await getTree(value);
    }
  };

  let downlineUsers = [];
  let downlineXarrowAnchors = [];
  let invitedUsers = undefined;
  if (networkUsers.downline && usernames && subscriptionExpirations) {
    downlineUsers = networkUsers.downline.map((user, i) => {
      if (user) {
        let nestedDownline = networkUsers.nestedDownlines[user];

        nestedDownline = nestedDownline.map((nestedUser, nestedI) => {
          const key = `downline${i + 1}_nested${nestedI + 1}`;
          const newUser = newUsers.some(newUser => newUser == nestedUser.toString());
          return (
            <div key={key}>
              <div style={{ marginTop: 30 }}>
                <User
                  role={'User'}
                  newUser={newUser}
                  username={usernames[nestedUser]}
                  subscriptionExpiration={subscriptionExpirations[nestedUser]}
                  arrowID={key}
                  userID={nestedUser}
                  number={undefined}
                  parentID={user}
                  directOrSpillover={
                    directOrSpillover && directOrSpillover[nestedUser] && directOrSpillover[nestedUser].direct
                      ? 'Direct'
                      : 'Spillover'
                  }
                />
              </div>
            </div>
          );
        });
        const newUser = newUsers.some(newUser => newUser == user.toString());
        return (
          <div key={i}>
            <User
              role={'User'}
              newUser={newUser}
              username={usernames[user]}
              subscriptionExpiration={subscriptionExpirations[user]}
              arrowID={`downline${i + 1}`}
              userID={user}
              number={i + 1}
              parentID={networkUsers.source}
              directOrSpillover={
                directOrSpillover && directOrSpillover[user] && directOrSpillover[user].direct ? 'Direct' : 'Spillover'
              }
            />
            {desktopBreakingPoint && (
              <div>
                {nestedDownline.length > 0 && (
                  <div style={{ position: 'absolute' }}>
                    <Typography component="h1" variant="h1" style={{ color: 'white' }}>
                      Downline: {nestedDownline.length}/5
                    </Typography>
                  </div>
                )}
                {nestedDownline}
                {nestedDownline.length > 0 && (
                  <Xarrow
                    animateDrawing={true}
                    color="white"
                    path={'grid'}
                    startAnchor={'bottom'}
                    endAnchor={'top'}
                    start={`downline${i + 1}`}
                    end={`downline${i + 1}_nested1`}
                    key={i}
                  />
                )}
              </div>
            )}
          </div>
        );
      }
    });

    downlineXarrowAnchors = networkUsers.downline.map((user, i) => {
      if (user) {
        return (
          <Xarrow
            animateDrawing={true}
            color="white"
            path={'grid'}
            startAnchor={'bottom'}
            endAnchor={'top'}
            start="source"
            end={`downline${i + 1}`}
            key={i}
          />
        );
      }
    });

    invitedUsers = networkUsers.invitedUsers.map((user, i) => {
      if (user && usernames && subscriptionExpirations) {
        return (
          <User
            role={'User'}
            username={usernames[user]}
            subscriptionExpiration={subscriptionExpirations[user]}
            userID={user}
            number={i + 1}
            key={i}
          />
        );
      }
    });
  }
  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer heading={<IntlMessages id="pages.networkPage" />} breadcrumbs={breadcrumbs}>
        <GridContainer>
          <Grid item xs={12}>
            <div
              style={{
                marginBottom: 10,
                display: 'flex-start',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}>
              <IntlMessages id="pages.networkPage.description" />
            </div>
            <Divider />
            {networkUsers ? (
              desktopBreakingPoint ? (
                render && (
                  <NetworkTreeDesktop
                    networkUsers={networkUsers}
                    handleGoBack={handleGoBack}
                    handleGoForward={handleGoForward}
                    handleGoMe={handleGoMe}
                    handleSearch={handleSearch}
                    downlineUsers={downlineUsers}
                    invitedUsers={invitedUsers}
                    downlineXarrowAnchors={downlineXarrowAnchors}
                    usernames={usernames}
                    subscriptionExpirations={subscriptionExpirations}
                  />
                )
              ) : (
                <NetworkTreeMobile
                  networkUsers={networkUsers}
                  handleGoBack={handleGoBack}
                  handleGoForward={handleGoForward}
                  handleGoMe={handleGoMe}
                  handleSearch={handleSearch}
                  downlineUsers={downlineUsers}
                  invitedUsers={invitedUsers}
                  usernames={usernames}
                  subscriptionExpirations={subscriptionExpirations}
                />
              )
            ) : null}
          </Grid>
        </GridContainer>
      </PageContainer>
    </div>
  );
};

export default NetworkPage;
