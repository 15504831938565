import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import VideoBackground from 'components/Common/VideoBackground';
import UsersInfo from 'components/DirectorsPage/UsersInfo';
import TotalSales from 'components/DirectorsPage/TotalSales';
import { AuhMethods } from 'services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import { checkIfDirectorAccount } from 'services/auth/moralis/functions';
import Changelog from 'components/DirectorsPage/Changelog';

const AdminPage = () => {
  const dispatch = useDispatch();
  
  const { authUser } = useSelector(({ auth }) => auth);
  if (!authUser) return;

  const [checkingIsDirector, setCheckingIsDirector] = useState(true);

  useEffect(() => {
    const asyncFunction = async () => {
      const isDirector = await checkIfDirectorAccount();
      if (!isDirector) {
        dispatch(AuhMethods[CurrentAuthMethod].onLogout());
      }
      setCheckingIsDirector(false);
    };
    asyncFunction();
  }, []);

  if (checkingIsDirector) return <div />;

  return (
    <div>
      <VideoBackground videoSrc="/background.mp4" />
      <div>
        <Changelog />
        <UsersInfo authUser={authUser} />
        <TotalSales authUser={authUser} />
      </div>
    </div>
  );
};

export default AdminPage;
