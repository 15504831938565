import React from 'react';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import calculateSideMarginsToCenterModal from 'components/Functions/calculateSideMarginsToCenterModal';
import IntlMessages from '@jumbo/utils/IntlMessages';

const TransactionConfirmationModal = ({ setIsOpen, modalIsOpen, nftMetaDataRefreshed }) => {
  const screenWidth = window.innerWidth;

  const modalStyles = {
    content: {
      position: screenWidth > 910 ? 'relative' : 'none',
      zIndex: 1,
      marginTop: 120,
      marginRight: calculateSideMarginsToCenterModal(800).marginRight,
      marginLeft: calculateSideMarginsToCenterModal(800).marginLeft,
      maxHeight: 500,
      width: 800,
      maxWidth: '100%',
      overflowY: 'scroll',
    },
  };
  const contentStyles = {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    justifyContent: 'space-around',
    textAlign: 'center',
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={modalIsOpen}
      contentLabel={'Transaction Confirmation Modal'}
      onRequestClose={handleModalClose}>
      <Button onClick={handleModalClose}>
        <IntlMessages id={'component.close'} />
      </Button>
      <CmtCardContent style={contentStyles}>
        <h2>
          <IntlMessages id={'component.operationCompleted'} />
        </h2>
        <p>
          <strong>
            {nftMetaDataRefreshed ? (
              <IntlMessages id={'component.openseaMetadataRefreshSentSuccessfully'} />
            ) : (
              <IntlMessages id={'component.pleaseRefreshYourSbtManuallyOnOpensea'} />
            )}
          </strong>
        </p>
      </CmtCardContent>
    </Modal>
  );
};

export default TransactionConfirmationModal;
