import Web3 from 'web3';

const parsePoolInfo = poolInfo => {
  return {
    claimInfo: {
      claimableCycleID: Number(Web3.utils.fromWei(poolInfo.claim_info[0]._hex) * 10 ** 18),
      claimBalanceUSDT: Number(Web3.utils.fromWei(poolInfo.claim_info[1]._hex)),
      claimExpirationTimestamp: Number(Web3.utils.fromWei(poolInfo.claim_info[2]._hex) * 10 ** 18),
      claimExpired: Number(Web3.utils.fromWei(poolInfo.claim_info[3]._hex) * 10 ** 18) === 0 ? false : true,
      totalWithdrawn: Number(Web3.utils.fromWei(poolInfo.claim_info[4]._hex)),
      totalLost: Number(Web3.utils.fromWei(poolInfo.claim_info[5]._hex)),
      pwdConversionPercentage: Number(Web3.utils.fromWei(poolInfo.claim_info[6]._hex) * 10 ** 18),
    },
    currentCycleStats: {
      poolBalance: Number(Web3.utils.fromWei(poolInfo.current_cycle_stats[0]._hex)),
      numberParticipants: Number(Web3.utils.fromWei(poolInfo.current_cycle_stats[1]._hex) * 10 ** 18),
      perUserReward: Number(Web3.utils.fromWei(poolInfo.current_cycle_stats[2]._hex)),
      userReward: Number(Web3.utils.fromWei(poolInfo.current_cycle_stats[3]._hex)),
      userJoined: Number(Web3.utils.fromWei(poolInfo.current_cycle_stats[4]._hex) * 10 ** 18) === 0 ? false : true,
    },
    globals: {
      currentActiveCycleID: Number(Web3.utils.fromWei(poolInfo.globals[0]._hex) * 10 ** 18),
      currentViewedCycleID: Number(Web3.utils.fromWei(poolInfo.globals[1]._hex) * 10 ** 18),
      currentViewedEndTimestamp: Number(Web3.utils.fromWei(poolInfo.globals[2]._hex) * 10 ** 18),
      currentViewedRemainingSeconds: Number(Web3.utils.fromWei(poolInfo.globals[3]._hex) * 10 ** 18),
      nextCycleID: Number(Web3.utils.fromWei(poolInfo.globals[4]._hex) * 10 ** 18),
      lastCompletedCycleID: Number(Web3.utils.fromWei(poolInfo.globals[5]._hex) * 10 ** 18),
    },
    lastCycleStats: {
      poolBalance: Number(Web3.utils.fromWei(poolInfo.last_cycle_stats[0]._hex)),
      numberParticipants: Number(Web3.utils.fromWei(poolInfo.last_cycle_stats[1]._hex) * 10 ** 18),
      perUserReward: Number(Web3.utils.fromWei(poolInfo.last_cycle_stats[2]._hex)),
      userReward: Number(Web3.utils.fromWei(poolInfo.last_cycle_stats[3]._hex)),
      userJoined: Number(Web3.utils.fromWei(poolInfo.last_cycle_stats[4]._hex) * 10 ** 18) === 0 ? false : true,
    },
  };
};

const parseJoinClaimConditionsInfo = joinClaimConditionsInfo => {
  return {
    pwdUsdSatisfied: joinClaimConditionsInfo.PWD_USD_satisfied,
    pwdUsdUnitsAmount: Number(Web3.utils.fromWei(joinClaimConditionsInfo.PWD_USDunits_amount._hex) * 10 ** 18),
    careerPointsSatisfied: joinClaimConditionsInfo.career_points_satisfied,
    invitedSatisfied: joinClaimConditionsInfo.invited_satisfied,
    minRankSatisfied: joinClaimConditionsInfo.min_rank_satisfied,
    nCareerPointsDiff: Number(Web3.utils.fromWei(joinClaimConditionsInfo.n_career_points_diff._hex) * 10 ** 18),
    nInvitedDiff: Number(Web3.utils.fromWei(joinClaimConditionsInfo.n_invited_diff._hex) * 10 ** 18),
    noBanSatisfied: joinClaimConditionsInfo.no_ban_satisfied,
    promoterActiveSatisfied: joinClaimConditionsInfo.promoter_active_satisfied,
    zeroClaimableCanRejoin: joinClaimConditionsInfo.zero_claimable_can_rejoin,
  };
};

const parseCycleData = cycleData => {
  const cyclesCount = Number(Web3.utils.fromWei(cycleData.cycles_count._hex) * 10 ** 18);
  const isEndedLast = cycleData.is_ended_last;

  const cycles = [];
  for (let i = 0; i < cyclesCount; i++) {
    cycles.push({
      availableBalance: Number(Web3.utils.fromWei(cycleData.avail_balance[i]._hex)),
      balance: Number(Web3.utils.fromWei(cycleData.balance[i]._hex)),
      closeTimestamp: Number(Web3.utils.fromWei(cycleData.closeTimestamp[i]._hex) * 10 ** 18),
      createdTimestamp: Number(Web3.utils.fromWei(cycleData.createdTimestamp[i]._hex) * 10 ** 18),
      id: Number(Web3.utils.fromWei(cycleData.id[i]._hex) * 10 ** 18),
      nParticipants: Number(Web3.utils.fromWei(cycleData.n_participants[i]._hex) * 10 ** 18),
    });
  }

  return {
    cyclesCount,
    isEndedLast,
    cycles,
  };
};

const parseUserInfoAndEndedCycles = userInfoAndEndedCycles => {
  return {
    burnedCareerPoints: Number(Web3.utils.fromWei(userInfoAndEndedCycles.burnedCareerPoints._hex) * 10 ** 18),
    burnedInvitations: Number(Web3.utils.fromWei(userInfoAndEndedCycles.burnedInvitations._hex) * 10 ** 18),
    currentCycleId: Number(Web3.utils.fromWei(userInfoAndEndedCycles.currentCycleId._hex) * 10 ** 18),
    endedCycles:
      userInfoAndEndedCycles.endedCycles.length < 1
        ? []
        : userInfoAndEndedCycles.endedCycles.map(item => Number(Web3.utils.fromWei(item._hex)) * 10 ** 18),
    nEndedCycles: Number(Web3.utils.fromWei(userInfoAndEndedCycles.n_ended_cycles._hex) * 10 ** 18),
    totalExpiredLost: Number(Web3.utils.fromWei(userInfoAndEndedCycles.totalExpiredLost._hex) * 10 ** 18),
    totalWithdrawn: Number(Web3.utils.fromWei(userInfoAndEndedCycles.totalWithdrawn._hex) * 10 ** 18),
  };
};

export { parsePoolInfo, parseJoinClaimConditionsInfo, parseCycleData, parseUserInfoAndEndedCycles };
