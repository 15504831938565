const calculateSideMarginsToCenterModal = modalWidth => {
  const screenWidth = window.innerWidth;
  const sidebarBreakpoint = 1279;
  const sidebarWidth = 220;
  const contentSpace = screenWidth - sidebarWidth;

  const marginCentered = (contentSpace - modalWidth) / 2;
  const marginRight = screenWidth > sidebarBreakpoint ? marginCentered - sidebarWidth : 'auto';
  const marginLeft = screenWidth > sidebarBreakpoint ? marginCentered + sidebarWidth : 'auto';

  return { marginRight, marginLeft };
};

export default calculateSideMarginsToCenterModal;
