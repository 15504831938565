import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { AuhMethods } from 'services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 50,
    right: 100,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    fontSize: 30,
    color: 'white',
  },
  text: {
    color: 'white',
  },
}));

const Logout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  return (
    <div onClick={onClick} className={classes.container}>
      <ExitToApp className={classes.icon} />
      <p className={classes.text}>EXIT</p>
    </div>
  );
};

export default Logout;
