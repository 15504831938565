const downloadAsset = async (url, base64, title, filetype) => {
  let blob;
  if (url) {
    const response = await fetch(url);
    blob = await response.blob();
  } else if (base64) {
    const binaryData = Buffer.from(base64, 'base64');
    blob = new Blob([binaryData], { type: `application/${filetype}` });
  }

  // Create a link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${title}.${filetype}`;

  // Append the link to the body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
};

export { downloadAsset };
