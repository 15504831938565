import { LOAD_NETWORKUSERS } from '../../smart_contract/constants/ActionTypes';

export const loadNetworkUsers = networkUsers => {
  return dispatch => {
    dispatch({
      type: LOAD_NETWORKUSERS,
      payload: networkUsers || { downline: [], invitedUsers: [] },
    });
  };
};
