import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CmtVerticalLayout from '../../../../../@coremat/CmtLayouts/Vertical';
import CmtHeader from '../../../../../@coremat/CmtLayouts/Vertical/Header';
import Header from '../../partials/Header';
import CmtSidebar from '../../../../../@coremat/CmtLayouts/Vertical/Sidebar';
import SidebarHeader from '../../partials/SidebarHeader';
import SideBar from '../../partials/SideBar';
import TelegramFloat from '../../partials/TelegramFloat';
import CmtContent from '../../../../../@coremat/CmtLayouts/Vertical/Content';
import ContentLoader from '../../../ContentLoader';
import CmtFooter from '../../../../../@coremat/CmtLayouts/Vertical/Footer';
import Footer from '../../partials/Footer';
import defaultContext from '../../../contextProvider/AppContextProvider/defaultContext';

const layoutOptions = {
  headerType: defaultContext.headerType,
  footerType: 'fixed',
  sidebarType: defaultContext.sidebarType,
  isSidebarFixed: defaultContext.isSidebarFixed,
  isSidebarOpen: false,
  showTourOpt: true,
  showFooterOpt: true,
  miniSidebarWidth: 80,
  layoutStyle: defaultContext.layoutType,
  drawerBreakPoint: defaultContext.drawerBreakPoint,
  sidebarWidth: defaultContext.sidebarWidth,
};

const VerticalDefault = ({ children }) => {
  const [forceCloseSidebar, setForceCloseSidebar] = useState(false);
  const location = useLocation();

  if (location.pathname !== '/admin') {
    return (
      <CmtVerticalLayout
        className="verticalDefaultLayout"
        layoutOptions={layoutOptions}
        header={
          <CmtHeader>
            <Header />
          </CmtHeader>
        }
        sidebar={
          <CmtSidebar forceCloseSidebar={forceCloseSidebar} setForceCloseSidebar={setForceCloseSidebar}>
            <SidebarHeader />
            <SideBar />
          </CmtSidebar>
        }
        footer={
          <CmtFooter>
            <Footer />
          </CmtFooter>
        }>
        <CmtContent>
          {children}
          <TelegramFloat />
          <ContentLoader />
        </CmtContent>
      </CmtVerticalLayout>
    );
  } else {
    return (
      <CmtContent>
        {children}
        <ContentLoader />
      </CmtContent>
    );
  }
};

export default VerticalDefault;
