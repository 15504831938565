import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import IntlMessages from '@jumbo/utils/IntlMessages';
import VideoBackground from 'components/Common/VideoBackground';
import ContentLoader from '@jumbo/components/ContentLoader';
import PoolInfo from 'components/InfinityPoolPage/PoolInfo';
import ClaimInfo from 'components/InfinityPoolPage/ClaimInfo';
import { fetchError, fetchStart, fetchSuccess, clearAlert } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { getPoolInfo, claim } from 'smart_contract/infinity_pool/functions';
import { getGlobalCareerSettings } from 'smart_contract/career/functions';
import TransactionConfirmationModal from 'components/Common/TransactionConfirmationModal';

const useStyles = makeStyles(theme => ({
  flexRow: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  marginTop: {
    marginTop: 70,
  },
  divider: {
    display: 'flex',
    borderBottom: 'solid 2px #E0E0E0',
    width: '100%',
    marginTop: 50,
    marginBottom: 50,
    display: 'none',
    '@media (max-width: 630px)': {
      display: 'flex',
    },
  },
}));

const InfinityPoolPage = () => {
  const breadcrumbs = [
    { label: <IntlMessages id={'sidebar.main'} />, link: '/home' },
    {
      label: <IntlMessages id={'pages.careerPage.pools.infinity'} />,
      isActive: true,
    },
  ];

  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const userAddress = authUser.userAddress;

  const [loadingFinished, setLoadingFinished] = useState(false);
  const [poolInfo, setPoolInfo] = useState({});
  const [globalCareerSettings, setGlobalCareerSettings] = useState({});
  const [claiming, setClaiming] = useState(false);
  const [loadModals, setLoadModals] = useState(false);
  const [transactionConfirmationModalIsOpen, setTransactionConfirmationModalIsOpen] = useState(false);

  const loadData = async () => {
    dispatch(fetchStart());
    let response = await getPoolInfo(userAddress);
    if (response.error) {
      console.log(`Error loading pool info: ${response.error}`);
      return;
    }
    const poolInfo = response.poolInfo;
    setPoolInfo(poolInfo);

    response = await getGlobalCareerSettings();
    if (response.error) {
      console.log(`Error loading global career settings: ${response.error}`);
      return;
    }
    const globalCareerSettings = response.globalCareerSettings;
    setGlobalCareerSettings(globalCareerSettings);

    setLoadModals(true);
    setLoadingFinished(true);
    dispatch(fetchSuccess());
  };

  useEffect(() => {
    const asyncFunction = async () => {
      await loadData();
    };
    asyncFunction();
  }, []);

  const onClaim = async () => {
    setClaiming(true);
    dispatch(fetchStart());
    const response = await claim();
    if (response.error) {
      dispatch(fetchError(`Error - onClaim: ${response.error}`));
      setClaiming(false);
      return;
    }
    const data = response.data;
    await loadData();

    dispatch(clearAlert('infinityPoolClaimAvailable'));
    dispatch(fetchSuccess(<IntlMessages id={'success.claimedReward'} />));
    setTransactionConfirmationModalIsOpen(true);
    setClaiming(false);
  };

  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer
        id="infinity_pool_container"
        heading={<IntlMessages id={'pages.careerPage.pools.infinity.description'} />}
        breadcrumbs={breadcrumbs}>
        {loadModals && (
          <div>
            <TransactionConfirmationModal
              setIsOpen={setTransactionConfirmationModalIsOpen}
              modalIsOpen={transactionConfirmationModalIsOpen}
            />
          </div>
        )}
        <CmtCard id={'career_main'}>
          <CmtCardContent
            style={{
              paddingTop: 24,
              alignItems: 'center',
              whiteSpace: 'nowrap',
              zIndex: 0,
            }}>
            {!loadingFinished && <ContentLoader />}
            {loadingFinished && (
              <div>
                <div className={classes.flexRow}>
                  <PoolInfo poolInfo={poolInfo} globalCareerSettings={globalCareerSettings} />
                  <div className={classes.divider} />
                  <ClaimInfo poolInfo={poolInfo} claiming={claiming} onClaim={onClaim} />
                </div>
              </div>
            )}
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </div>
  );
};

export default InfinityPoolPage;
