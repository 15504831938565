import { languageData } from '../../AppLayout/partials/LanguageSwitcher/data';
import { saveLocalePreferenceToDB } from 'db/functions';

export const getLanguageByLocale = locale => {
  return languageData.find(lang => lang.locale === locale);
};

export const setDefaultLng = item => {
  localStorage.setItem('lngPreference', JSON.stringify(item));
  saveLocalePreferenceToDB(item.locale);
};

export const getDefaultLng = () => {
  // const lngPreference = JSON.parse(localStorage.getItem('lngPreference')) || undefined;
  // let defaultLng;

  // if (!lngPreference) {
  //   const browserLocale = navigator.language.slice(0, 2); // Get the language code (e.g., 'en', 'es', 'it')
  //   defaultLng = getLanguageByLocale(browserLocale) || getLanguageByLocale('en'); // Default to English
  //   setDefaultLng(defaultLng);
  // } else {
  //   defaultLng = lngPreference;
  // }

  // return defaultLng;

  return getLanguageByLocale('en');
};
