class TokenData {
  constructor(allTokenData, token) {
    this.allTokenData = allTokenData;
    this.token = token;
    this.tokenData = {};
  }

  init = () => {
    let tokenData = this.allTokenData.find(tokenData => tokenData.token.toLowerCase() === this.token.toLowerCase());

    if (!tokenData) {
      tokenData = this.allTokenData.find(tokenData => tokenData.token === '0x0000000000000000000000000000000000000000');
    }

    this.tokenData = tokenData;
  };

  getBase64Data = () => {
    return this.tokenData.logo;
  };

  getTicker = () => {
    return this.tokenData.ticker;
  };

  getType = () => {
    return this.tokenData.type;
  };
}

export default TokenData;
