import React, { useState } from 'react';
import CmtCard from '@coremat/CmtCard';
import CmtSearch from '@coremat/CmtSearch';
import { Typography, Box, Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const Navigator = ({ handleGoBack, handleGoForward, handleGoMe, handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const intl = useIntl();
  const goToIdText = intl.formatMessage({ id: 'component.goToID' });
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', maxWidth: '100vw' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h1">
          <IntlMessages id={'component.navigateNetwork'} />
        </Typography>
        <CmtCard
          style={{
            marginTop: 10,
            marginBottom: 10,
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'url(/images/gray_bg.jpg)',
            backgroundSize: 'cover',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CmtSearch
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              style={{ margin: 5, maxWidth: 170 }}
              placeholder={goToIdText}
              iconStyle={{ zIndex: '0' }}
              inputStyle={{ zIndex: '-1' }}
            />
            <Button onClick={() => handleSearch(searchTerm)}>
              <IntlMessages id={'component.go'} />
            </Button>
          </div>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  setSearchTerm('');
                  handleGoBack();
                }}
                style={{
                  margin: 5,
                  maxWidth: 170,
                  background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
                  textShadow: '2px 2px 1px #311b1b',
                }}
                color="primary"
                variant="contained">
                <IntlMessages id={'component.goBack'} />
              </Button>
              <Button
                onClick={() => {
                  setSearchTerm('');
                  handleGoForward();
                }}
                style={{
                  margin: 5,
                  maxWidth: 170,
                  background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
                  textShadow: '2px 2px 1px #311b1b',
                }}
                color="primary"
                variant="contained">
                <IntlMessages id={'component.goForward'} />
              </Button>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  setSearchTerm('');
                  handleGoMe();
                }}
                style={{
                  margin: 5,
                  maxWidth: 170,
                  background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
                  textShadow: '2px 2px 1px #311b1b',
                }}
                color="primary"
                variant="contained">
                <IntlMessages id={'component.returnToMe'} />
              </Button>
            </div>
          </Box>
        </CmtCard>
      </div>
    </div>
  );
};

export default Navigator;
