import { Moralis } from 'moralis';
import web3 from 'web3';
import PowerMadeAffiliationABI from './abi/PowermadeAffiliation.json';
import BEP20TokenABI from './abi/BEP20Token.json';
import PowermadeEventAclABI from './abi/PowermadeEventACL.json';
import DiscountWrapperStandaloneABI from './abi/DiscountWrapperStandalone.json';
import RankSbtABI from './abi/RankSBT.json';
import ActivityPoolABI from './abi/ActivityPool.json';
import InfinityPoolABI from './abi/InfinityPool.json';
import ExternalThresholdRulesProxyABI from './abi/ExternalThresholdRulesProxy.json';
import { getDiscountsInfos, getConfiguredDiscounts } from './functions';
const { ethereum } = window;

const getABI = abiName => {
  const abi = {
    PowerMadeAffiliationABI,
    BEP20TokenABI,
    PowermadeEventAclABI,
    DiscountWrapperStandaloneABI,
    RankSbtABI,
    ActivityPoolABI,
    InfinityPoolABI,
    ExternalThresholdRulesProxyABI,
  };

  return abi[abiName];
};

const smartContractFunctionCall = async (contractAddress, functionName, abiName, params, write) => {
  try {
    const abi = getABI(abiName);
    const options = {
      contractAddress,
      functionName,
      abi,
      params,
    };
    await Moralis.enableWeb3();
    let response = await Moralis.executeFunction(options);
    if (write) {
      response = await response.wait();
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error.data) {
      throw Error(error.data.message);
    } else {
      throw Error(error.message);
    }
  }
};

const smartContractFunctionCallGasLimit = async (
  contractAddress,
  functionName,
  abiName,
  params,
  fixedGasLimit = 0,
  increaseEstimatedGasFactor = 1.3,
  increaseGasPriceFactor = 1,
) => {
  try {
    const abi = getABI(abiName);
    const ethers = Moralis.web3Library;
    const web3Provider = await Moralis.enableWeb3();
    const gasPrice = await web3Provider.getGasPrice();
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);
    const estimatedGasLimit = await contract.estimateGas[functionName](...params);
    const overrides = {
      gasLimit: fixedGasLimit > 0 ? fixedGasLimit : parseInt(estimatedGasLimit * increaseEstimatedGasFactor),
      gasPrice: gasPrice * increaseGasPriceFactor,
    };
    console.log({
      gasPrice: parseInt(gasPrice._hex),
      estimatedGasLimit: parseInt(estimatedGasLimit._hex),
      gasLimit: overrides.gasLimit,
    });
    let response = await contract[functionName](...params, overrides); // Function name must be, for example, "BuyPackagePWD(uint256,uint16)"
    response = await response.wait();
    return response;
  } catch (error) {
    console.log(error);
    if (error.data) {
      throw Error(error.data.message);
    } else {
      throw Error(error.message);
    }
  }
};

const getUserAddress = async () => {
  let user = await Moralis.User.currentAsync();
  user = JSON.stringify(user);
  return JSON.parse(user).ethAddress;
};

const parseAddedPackagesMainInfo = addedPackagesMainInfo => {
  const parsedAddedPackagesMainInfo = [];
  const length = addedPackagesMainInfo[0].length;
  for (let i = 0; i < length; i++) {
    parsedAddedPackagesMainInfo.push({
      duration: parseInt(addedPackagesMainInfo.duration[i]._hex, 16),
      enabled: addedPackagesMainInfo.enabled[i],
      hasPrerequisites: addedPackagesMainInfo.has_prerequisites[i],
      packageID: addedPackagesMainInfo.packageID[i],
      price: parseInt(addedPackagesMainInfo.price[i]._hex, 16) / 10 ** 18,
      rebuyEnabled: addedPackagesMainInfo.rebuy_enabled[i],
    });
  }

  return parsedAddedPackagesMainInfo;
};

const parseGetAllPurchases = allPurchasesInfo => {
  const parsedPurchasesInfo = [];
  const length = allPurchasesInfo[1].length;
  for (let i = 0; i < length; i++) {
    parsedPurchasesInfo.push({
      duration: parseInt(allPurchasesInfo.durations[i]._hex, 16),
      expired: allPurchasesInfo.expireds[i],
      packageID: allPurchasesInfo.packageIDs[i],
      timestamp: parseInt(allPurchasesInfo.timestamps[i]._hex, 16),
      userOrPurchaseID: parseInt(allPurchasesInfo.userIDs_or_PIDs[i]._hex, 16),
    });
  }

  return parsedPurchasesInfo;
};

const parseAddedPackagesUserInfo = addedPackagesUserInfo => {
  const parsedAddedPackagesUserInfo = [];
  const length = addedPackagesUserInfo[0].length;

  for (let i = 0; i < length; i++) {
    parsedAddedPackagesUserInfo.push({
      packageID: addedPackagesUserInfo.packageID[i],
      totalEarnedPack: Number(web3.utils.fromWei(addedPackagesUserInfo.totalEarnedPack[i]._hex)),
      purchasesCount: parseInt(addedPackagesUserInfo.purchasesCount[i]._hex, 16),
      lastPID: parseInt(addedPackagesUserInfo.last_PID[i]._hex, 16),
      lastTimestamp: parseInt(addedPackagesUserInfo.last_timestamp[i]._hex, 16),
      lastExpired: addedPackagesUserInfo.last_expired[i],
    });
  }

  return parsedAddedPackagesUserInfo;
};

const generateTicket = () => {
  const generatedTicket = web3.utils.randomHex(20);
  const ticketHashed = web3.utils.soliditySha3(generatedTicket);
  return { ticket: generatedTicket, ticketHashed };
};

const parseDiscountsInfos = response => {
  return {
    index: response.index,
    level: response.level,
    quantityDiscount: response.applied_percentages[0] / 10,
    discountLevel: response.applied_percentages[1] / 10,
    totalPercentage: response.applied_percentages[2] / 10,
    priceBUSD: response.prices[0]._hex,
    pricePWD: response.prices[1]._hex,
    discountedPriceBUSD: response.prices[2]._hex,
    discountedPricePWD: response.prices[3]._hex,
  };
};

const parseConfiguredDiscounts = response => {
  return {
    levels: response.levels,
    percentages: response.percentages.map(percentage => percentage / 10),
    tokenAddresses: response.token_addresses,
    thresholds: response.thresholds.map(threshold => threshold.toString()),
    rarities: response.rarities,
    quantityDiscountInfo: {
      percentage: response.quantity_discount_info.percentage / 10,
      threshold: parseInt(response.quantity_discount_info.threshold._hex),
      tokenAddress: response.quantity_discount_info.token_address,
    },
  };
};

const parseUnlockedStatus = response => {
  return {
    levelsUnlocked: [
      response.levels_unlocked[1],
      response.levels_unlocked[2],
      response.levels_unlocked[3],
      response.levels_unlocked[4],
      response.levels_unlocked[5],
    ],
  };
};

const getAllDiscountsInfos = async packages => {
  const { userID } = JSON.parse(localStorage.getItem('user'));
  const packageLength = packages.length;
  let parsedDiscountsInfos = [];

  const populateParsedDiscounstInfos = async () => {
    let i = 0;
    while (i < packageLength) {
      const packageID = packages[i].get('packageID');
      try {
        let request = await getDiscountsInfos(userID, packageID);
        if (request.error) {
          return request.error;
        }
        parsedDiscountsInfos.push(parseDiscountsInfos(request.response));
      } catch (error) {
        return { error: error.message };
      }
      i++;
    }
    return;
  };

  await populateParsedDiscounstInfos();
  return { error: false, parsedDiscountsInfos };
};

const getSingleDiscountsInfos = async packageID => {
  const userAddress = ethereum.selectedAddress;
  let parsedDiscountsInfos = [];
  try {
    const request = await getDiscountsInfos(0, userAddress, packageID);
    parsedDiscountsInfos = parseDiscountsInfos(request.response);
  } catch (error) {
    return { error: error.message };
  }

  return { error: false, parsedDiscountsInfos };
};

const getParsedConfiguredDiscounts = async packageID => {
  try {
    const request = await getConfiguredDiscounts(packageID);
    const parsedConfiguredDiscounts = parseConfiguredDiscounts(request.response);
    return { error: false, parsedConfiguredDiscounts };
  } catch (error) {
    return { error: error.message };
  }
};

export {
  getABI,
  smartContractFunctionCall,
  smartContractFunctionCallGasLimit,
  getUserAddress,
  parseAddedPackagesMainInfo,
  parseGetAllPurchases,
  parseAddedPackagesUserInfo,
  parseUnlockedStatus,
  getAllDiscountsInfos,
  getSingleDiscountsInfos,
  getParsedConfiguredDiscounts,
  generateTicket,
};
