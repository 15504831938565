import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Packages from './Packages';
import Users from './Users';
import Events from './Events';
import NetworkUsers from './NetworkUsers';
import Alerts from './Alerts';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    packages: Packages,
    users: Users,
    events: Events,
    networkUsers: NetworkUsers,
    alerts: Alerts,
  });
