import React, { useEffect, useState, useContext } from 'react';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { useLocation } from 'react-router-dom';
import GridContainer from '../../@jumbo/components/GridContainer';
import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Package from './Package';
import PurchaseConfirmationModal from 'components/PackagesPage/PurchaseConfirmationModal';
import CouponModal from 'components/CouponModal/CouponModal';
import { useSelector, useDispatch } from 'react-redux';
import AnnouncementModal from '../AnnouncementModal/AnnouncementModal';
import { fetchStart, fetchSuccess, loadPackages } from '../../redux/actions';
import { getAllPackages } from '../../db/functions';
import { getQueryParams, getQueryString } from 'routes/queryFunctions';
import SearchPopover from '@jumbo/components/AppLayout/partials/SearchPopover';
import moment from 'moment';
import { getTitleAndDescription } from './functions';
import VideoBackground from 'components/Common/VideoBackground';

const PackagesPage = ({ category, messageID, descriptionID }) => {
  const breadcrumbs = [
    { label: <IntlMessages id={'sidebar.main'} />, link: '/home' },
    { label: <IntlMessages id={messageID} />, isActive: true },
  ];
  let packages = useSelector(({ packages }) => packages);
  const { authUser } = useSelector(({ auth }) => auth);
  const [filteredPackages, setFilteredPackages] = useState(packages);
  const [searchTerm, setSearchTerm] = useState('');
  const [purchaseConfirmationModalIsOpen, setPurchaseConfirmationIsOpen] = useState(false);
  const [couponModalIsOpen, setCouponIsOpen] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [PID, setPID] = useState('');
  const [coupon, setCoupon] = useState({});
  const [couponSetting, setCouponSetting] = useState({});
  const [couponLoaded, setCouponLoaded] = useState(false);
  const [copytradingLink, setCopytradingLink] = useState(undefined);
  const [loadModals, setLoadModals] = useState(false);
  const [render, setRender] = useState(true);
  const search = useLocation().search;
  const packageQuery = new URLSearchParams(search).get('packageQuery');
  const dispatch = useDispatch();

  const { locale } = useContext(AppContext);

  const isDisabled = _package => {
    let expired = false;
    if (_package.couponExpiration) {
      const expirationMoment = moment(_package.couponExpiration * 1000);
      const now = moment();
      if (expirationMoment.isBefore(now)) {
        expired = true;
      }
    }
    return !_package.enabled || expired;
  };

  useEffect(() => {
    const loadData = async () => {
      setRender(false);
      dispatch(fetchStart());
      const { packages } = await getAllPackages(category);
      localStorage.setItem('packages', JSON.stringify(packages));
      const filteredPackages = packages.filter(_package => !isDisabled(_package));
      localStorage.setItem('filteredPackages', JSON.stringify(filteredPackages));
      setFilteredPackages(JSON.parse(localStorage.getItem('filteredPackages')));
      dispatch(loadPackages(packages));
      if (packageQuery) {
        onSearchTermChange(packageQuery);
      }
      setLoadModals(true);
      setRender(true);
      dispatch(fetchSuccess());
    };
    loadData();
  }, []);

  const onSearchTermChange = async (value, removeValue = false) => {
    if (removeValue) {
      localStorage.setItem('params_to_remove', JSON.stringify(['packageQuery']));
      const queryParams = getQueryParams(search, ['packageQuery']);
      const queryString = getQueryString(queryParams);
      localStorage.setItem('link_on_reload', JSON.stringify(queryString));
    }
    const packages = JSON.parse(localStorage.getItem('packages'));
    setSearchTerm(value);
    if (!packages && packages.length < 1) {
      return;
    }
    if (!value) {
      const filteredPackages = packages.filter(_package => !isDisabled(_package));
      setFilteredPackages(filteredPackages);
      return;
    }

    const advancedSearch = value.slice(0, 3) === 'ID:' && !value.includes(' ');
    if (advancedSearch) {
      let packagesToIncludeInSearch = value.slice(3).split(',');
      let filteredPackages = packages.filter(_package => {
        return packagesToIncludeInSearch.includes(_package.packageID);
      });
      setFilteredPackages(filteredPackages);
      return;
    }

    let filteredPackages = packages.filter(_package => {
      return (
        _package.packageID.toString() === value ||
        (_package.title ? _package.title.toLowerCase().includes(value.toLowerCase()) : false) ||
        (_package.description ? _package.description.toLowerCase().includes(value.toLowerCase()) : false)
      );
    });
    setFilteredPackages(filteredPackages);
  };

  packages = packages.map((_package, i) => {
    if (!filteredPackages.find(filteredPackage => filteredPackage.packageID === _package.packageID)) {
      return null;
    }

    const { title, description } = getTitleAndDescription(_package.textContent, locale.locale);
    return (
      <Package
        key={i}
        title={title}
        description={description}
        languages={_package.languages}
        duration={_package.duration}
        enabled={_package.enabled}
        hasPrerequisites={_package.hasPrerequisites}
        packageID={_package.packageID}
        packagePrice={_package.price}
        rebuyEnabled={_package.rebuyEnabled}
        purchasesCount={_package.purchasesCount}
        lastTimestamp={_package.lastTimestamp}
        lastPID={_package.lastPID}
        lastExpired={_package.lastExpired}
        availableUnits={_package.availableUnits}
        disablePWD={_package.disablePWD}
        disableBUSD={_package.disableBUSD}
        couponKeyword={_package.couponKeyword}
        couponExpiration={_package.couponExpiration}
        customIcon={_package.customIcon}
        userAddress={authUser.userAddress}
        userEmail={authUser.email}
        setTransactionHash={setTransactionHash}
        setPID={setPID}
        setCoupon={setCoupon}
        setCouponSetting={setCouponSetting}
        setCopytradingLink={setCopytradingLink}
        setPurchaseConfirmationIsOpen={setPurchaseConfirmationIsOpen}
        setCouponLoaded={setCouponLoaded}
        isDisabled={isDisabled(_package)}
      />
    );
  });
  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer id="package_container" heading={<IntlMessages id={messageID} />} breadcrumbs={breadcrumbs}>
        {loadModals && (
          <div>
            {/* <AnnouncementModal /> */}
            <PurchaseConfirmationModal
              modalIsOpen={purchaseConfirmationModalIsOpen}
              setIsOpen={setPurchaseConfirmationIsOpen}
              transactionHash={transactionHash}
              PID={PID}
              setCouponIsOpen={setCouponIsOpen}
              couponLoaded={couponLoaded}
              copytradingLink={copytradingLink}
            />
            {couponLoaded && (
              <CouponModal
                modalIsOpen={couponModalIsOpen}
                setIsOpen={setCouponIsOpen}
                code={coupon.code}
                url={coupon.url}
                expiration={coupon.expiration}
                description={couponSetting.name}
                type={couponSetting.type}
                duration={coupon.duration ? coupon.duration : couponSetting.duration}
                forceCouponCode={couponSetting.forceCouponCode}
              />
            )}
          </div>
        )}
        <GridContainer>
          <Grid item xs={12}>
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}>
              <IntlMessages id={descriptionID} />
              <SearchPopover searchTerm={searchTerm} setSearchTerm={onSearchTermChange} />
            </div>
            <Divider />
            {render && <div style={{ marginTop: 24 }}>{packages}</div>}
          </Grid>
        </GridContainer>
      </PageContainer>
    </div>
  );
};

export default PackagesPage;
