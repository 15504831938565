import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { loadTokenData } from './functions';
import TokenData from './TokenData';
import { fetchError } from 'redux/actions';
import web3 from 'web3';

const LevelDetailedInfoModal = ({ modalStyles, setIsOpen, modalIsOpen, packageID, configuredDiscounts, currentIndex }) => {
  Modal.setAppElement(`#package_element_${packageID}`);
  const dispatch = useDispatch();
  const hasConfiguredDiscounts = configuredDiscounts.levels && configuredDiscounts.levels.length > 0 ? true : false;
  const [table, setTable] = useState([]);

  useEffect(() => {
    const loadTable = async () => {
      if (hasConfiguredDiscounts) {
        const request = await loadTokenData(configuredDiscounts);
        if (request.error) {
          dispatch(fetchError(request.error));
          return;
        }
        const allTokenData = request.tokenData;

        const table = configuredDiscounts.levels.map((level, i) => {
          const tokenData = new TokenData(allTokenData, configuredDiscounts.tokenAddresses[i]);
          tokenData.init();

          const rarity = configuredDiscounts.rarities[i];
          const percentage = configuredDiscounts.percentages[i];
          const base64Data = tokenData.getBase64Data();
          const ticker = tokenData.getTicker();
          const type = tokenData.getType();
          let threshold = configuredDiscounts.thresholds[i];
          if (type === 'token') {
            threshold = web3.utils.fromWei(threshold);
          }
          const displayRarity = () => {
            if (type === 'nft_rarity' || rarity > 0) {
              return true;
            } else {
              return false;
            }
          };
          const isCurrentIndex = currentIndex === i;

          return (
            <tr style={isCurrentIndex ? modalStyles.tableDiv.table.currentIndex : null} key={i}>
              <td style={modalStyles.tableDiv.table.td}>ID {i}</td>
              <td style={modalStyles.tableDiv.table.td}>
                <div style={modalStyles.tableDiv.table.td.div}>
                  <img style={modalStyles.tableDiv.table.logo} src={`data:image/png;base64, ${base64Data}`} />
                  <div>
                    <p>
                      ≥ {threshold} {ticker}
                    </p>
                    {displayRarity() && <p style={modalStyles.tableDiv.table.textSmall}>RarityID ≥ {rarity}</p>}
                  </div>
                </div>
              </td>
              <td style={modalStyles.tableDiv.table.td}>LEVEL {level}</td>
              <td style={modalStyles.tableDiv.table.td}>{percentage} %</td>
            </tr>
          );
        });
        setTable(table);
      }
    };
    loadTable();
  }, [hasConfiguredDiscounts]);

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal style={modalStyles} isOpen={modalIsOpen} contentLabel={'Level detailed info'} onRequestClose={handleModalClose}>
      <Button onClick={handleModalClose}>Close</Button>
      {hasConfiguredDiscounts ? (
        <CmtCardContent style={modalStyles.body.withData}>
          <h3>
            These are the discount rules and levels available for package {packageID}. Your current discount level, if
            active, will be highlighted in the table
          </h3>
          <div style={modalStyles.tableDiv}>
            <table style={modalStyles.tableDiv.table}>
              <tbody>{table}</tbody>
            </table>
          </div>
        </CmtCardContent>
      ) : (
        <CmtCardContent style={modalStyles.body.noData}>
          <h2>No Level Discounts available for this Package</h2>
        </CmtCardContent>
      )}
    </Modal>
  );
};

export default LevelDetailedInfoModal;
