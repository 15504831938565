import React, { useEffect, useState } from 'react';
import CmtCard from '@coremat/CmtCard';
import { Typography, Avatar } from '@material-ui/core';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { getSponsorAndParentOfUser } from 'db/functions';
import { StarRate } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  alertIcon: {
    position: 'absolute',
    top: 140,
    left: 5,
    zIndex: 1,
    animation: '$fadeInOut 1s infinite',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    width: 28,
    height: 28,
    borderRadius: 14,
    boxShadow: '0px 0px 16px red',
    marginRight: 2.5,
    marginLeft: 2.5,
  },
  '@keyframes fadeInOut': {
    '0%, 100%': { opacity: 1 },
    '50%': { opacity: 0.6 },
  },
}));

const User = ({ role, newUser, username, subscriptionExpiration, arrowID, userID, number, parentID, directOrSpillover }) => {
  // Only for testnet
  const [sponsorIdToDisplay, setSponsorIdToDisplay] = useState(undefined);
  const [parentIdToDisplay, setParentIdToDisplay] = useState(undefined);
  useEffect(() => {
    const asyncFunction = async () => {
      const result = await getSponsorAndParentOfUser(userID);
      if (!result) return;
      setSponsorIdToDisplay(result.sponsorID);
      setParentIdToDisplay(result.parentID);
    };
    if (process.env.REACT_APP_CHAIN_ID === '0x61' && role === 'Source') {
      asyncFunction();
    }
  }, []);
  // Only for testnet

  const classes = useStyles();

  let subscriptionExpired = false;

  if (!subscriptionExpiration) {
    subscriptionExpiration = true;
  }

  const momentObj = moment(Number(subscriptionExpiration) * 1000);
  const now = moment();
  if (momentObj.isBefore(now)) {
    subscriptionExpired = true;
  }

  return (
    <div
      id={arrowID}
      style={{
        minWidth: 220,
        margin: 5,
        maxWidth: 220,
        position: 'relative',
      }}>
      {newUser &&
        <div className={classes.alertIcon}>
          <StarRate style={{ fontSize: 16, color: 'white' }} />
        </div>
      }
      <Avatar
        style={{
          background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
          textShadow: '2px 2px 1px #311b1b',
          position: 'relative',
          width: 100,
          height: 100,
          top: 50,
        }}
      />
      <div
        style={{
          position: 'relative',
          right: '-150px',
          backgroundColor: 'white',
          width: '70px',
          padding: 5,
          borderRight: 'solid #CFCED2 1px',
          borderRadius: '4px 4px 0 0',
          textAlign: 'center',
          fontWeight: 'bold',
          background: 'url(/images/gray_bg.jpg)',
          backgroundSize: 'cover',
        }}>
        <p style={{ color: subscriptionExpired ? 'red' : 'green' }}>
          {subscriptionExpired ? <IntlMessages id={'component.expired'} /> : <IntlMessages id={'component.active'} />}
        </p>
      </div>
      <CmtCard
        style={{
          padding: 10,
          paddingTop: 50,
          zIndex: '-1',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: 130,
          borderRadius: '4px 0 4px 4px',
          background: 'url(/images/gray_bg.jpg)',
          backgroundSize: 'cover',
        }}>
        {number && (
          <div
            style={{
              position: 'relative',
              bottom: 40,
              left: 160,
              whiteSpace: 'nowrap',
            }}>
            <p>#{number}</p>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {parentID && (
            <div
              style={{
                whiteSpace: 'nowrap',
                marginRight: 0,
              }}>
              <p>
                <i>
                  <IntlMessages id={'component.parentID'} /> {parentID}
                </i>
              </p>
              <p>
                <i>
                  (<IntlMessages id={`component.${directOrSpillover ? directOrSpillover.toLowerCase() : ''}`} />)
                </i>
              </p>
            </div>
          )}
          {sponsorIdToDisplay && <p style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Sponsor ID {sponsorIdToDisplay}</p>}
          {parentIdToDisplay && <p style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>Parent ID {parentIdToDisplay}</p>}
          <Typography component="h1" variant="h1" style={{ marginTop: 10 }}>
            {'User'}
            {':'}
          </Typography>
          {
            <Typography component="p" variant="body1" style={{ marginTop: 5 }}>
              {username ? username : 'N/A'}
            </Typography>
          }
        </div>
        <div>
          <p style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>ID {userID}</p>
        </div>
      </CmtCard>
    </div>
  );
};

export default User;
