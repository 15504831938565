import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CmtCard from '@coremat/CmtCard';
import { LineChart } from '@mui/x-charts/LineChart';
import moment from 'moment';
import { generateChartData } from 'components/Functions/Admin/helpers';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
    height: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const TotalSalesChart = ({ data }) => {
  const classes = useStyles();

  const [chartData, setChartData] = useState({ xAxis: [], series: [] });

  useEffect(() => {
    if (data && data.length > 0) {
      const chartData = generateChartData(data);
      setChartData(chartData);
    }
  }, [data]);

  return (
    <CmtCard className={classes.container}>
      <LineChart
        xAxis={[
          {
            data: chartData.xAxis,
            scaleType: 'point',
            valueFormatter: value => {
              if (value.length === 4) {
                // Yearly
                return value;
              } else if (value.length === 7) {
                // Monthly
                return moment(value).format('MMM, YY');
              } else if (value.length === 10) {
                // Daily
                return moment(value).format('DD');
              }
            },
          },
        ]}
        yAxis={[
          {
            valueFormatter: value => `$${value.toLocaleString()}`,
          },
        ]}
        series={[
          {
            data: chartData.series,
            area: true,
            valueFormatter: value => `$${value.toLocaleString()}`,
          },
        ]}
        height={600}
        margin={{ left: 100, right: 100, top: 30, bottom: 30 }}
        tooltip={{
          itemContent: ({ series, value }) => (
            <div>
              <strong>{series}</strong>: ${value.toLocaleString()}
            </div>
          ),
        }}
      />
    </CmtCard>
  );
};

export default TotalSalesChart;
