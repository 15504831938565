import React from 'react';
import PackagesPage from 'components/PackagesPage';

const AcademyPage = () => {
  return (
    <div>
      <PackagesPage
        category={'academy'}
        messageID={'pages.products.academyPage'}
        descriptionID={'pages.products.academyPage.description'}
      />
    </div>
  );
};

export default AcademyPage;
