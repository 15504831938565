import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RankItem from './RankItem';

const useRankListStyles = makeStyles(() => ({
  ranksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflowX: 'scroll',
  },
}));

const RankList = ({ ranks, selectedRank, onSelectRank, onUpgradeRank, upgradingRank, careerInfo }) => {
  const classes = useRankListStyles();

  return (
    <div className={classes.ranksContainer}>
      {ranks.map((rank, index) => {
        const rankUnlocked = careerInfo.careerStarted && careerInfo.currentActiveRank >= index;
        const isSelected = selectedRank && rank.name === selectedRank.name;
        const unlockAvailable =
          (index === 0 && !careerInfo.careerStarted) ||
          (careerInfo.currentActiveRank < index && careerInfo.careerPoints >= rank.threshold);
        return (
          <RankItem
            key={rank.name}
            index={index}
            rank={rank}
            isSelected={isSelected}
            upgradingRank={upgradingRank}
            rankUnlocked={rankUnlocked}
            unlockAvailable={unlockAvailable}
            selectRank={() => onSelectRank({ ...rank, LV: index })}
            onUpgradeRank={() => onUpgradeRank(careerInfo.eligibleRank)}
          />
        );
      })}
    </div>
  );
};

export default RankList;
