import React from 'react';
import CmtCard from '@coremat/CmtCard';
import { Typography } from '@material-ui/core';
import { AccountTree } from '@material-ui/icons';
import IntlMessages from '@jumbo/utils/IntlMessages';

const NetworkStats = ({ fullNetwork }) => {
  const levels = fullNetwork.map((level, i) => {
    if (i > 5) {
      return;
    }
    const calculateOtherLevels = () => {
      let value = 0;

      for (let i = 5; i < fullNetwork.length; i++) {
        value += fullNetwork[i].length;
      }

      return value;
    };
    return (
      <div key={i}>
        <Typography component="p" variant="body1">
          {i < 5 ? `Level ${i + 1}: ${level.length}/${Math.pow(5, i + 1)}` : `Level 6+: ${calculateOtherLevels()}`}
        </Typography>
      </div>
    );
  });

  const calculateTotal = () => {
    let value = 0;
    fullNetwork.forEach(level => {
      value += level.length;
    });
    return value;
  };

  return (
    <CmtCard
      style={{
        padding: 10,
        background: 'url(/images/gray_bg.jpg)',
        backgroundSize: 'cover',
      }}>
      <div style={{ display: 'flex' }}>
        <AccountTree style={{ marginRight: 10, color: '#B08954' }} />
        <Typography component="h3" variant="h3">
          <IntlMessages id={'component.networkStats'} />
        </Typography>
      </div>
      {levels}
      <Typography component="p" variant="body1" style={{ fontWeight: 'bold' }}>
        Total: {calculateTotal()}
      </Typography>
    </CmtCard>
  );
};

export default NetworkStats;
