import React, { useEffect, useContext, useState } from 'react';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchStart, fetchSuccess, fetchError, loadEvents } from '../../../redux/actions';
import {
  getAllEvents,
  getTelegramLink,
  revokeTelegramLink,
  registerUserToBuilderAllClass,
  deactivateUserFromBuilderAllClass,
  addUserToEventList,
  sendRegisterConfirmationEmailToUser,
  sendAdminNotificationEmailOnEventRegistration,
} from '../../../db/functions';
import { registerToEvent } from '../../../smart_contract/functions';
import { generateTicket } from '../../../smart_contract/services';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchPopover from '@jumbo/components/AppLayout/partials/SearchPopover';
import Event from '../../../components/EventsPage/Event';
import RegisterModal from 'components/EventsPage/RegisterModal';
import moment from 'moment';
import Moralis from 'moralis';
import { getQueryParams, getQueryString } from 'routes/queryFunctions';
import VideoBackground from 'components/Common/VideoBackground';
import { getTitleAndDescription } from './functions';
const { ethereum } = window;

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.eventsPage'} />, isActive: true },
];

const EventsPage = () => {
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loadModals, setLoadModals] = useState(false);

  let events = useSelector(({ events }) => events);
  const { authUser } = useSelector(({ auth }) => auth);
  const userID = authUser.userID;
  const search = useLocation().search;
  const eventQuery = new URLSearchParams(search).get('eventQuery');
  const { locale } = useContext(AppContext);

  const dispatch = useDispatch();
  useEffect(() => {
    const loadData = async () => {
      dispatch(fetchStart());
      const { events } = await getAllEvents();
      const filteredEvents = events.map(event => event);
      setFilteredEvents(filteredEvents);
      localStorage.setItem('events', JSON.stringify(events));
      dispatch(loadEvents(events));
      if (eventQuery) {
        onSearchTermChange(eventQuery);
      }
      setLoadModals(true);
      dispatch(fetchSuccess());
    };
    loadData();
  }, [dispatch]);

  const onSearchTermChange = async (value, removeValue = false) => {
    if (removeValue) {
      localStorage.setItem('params_to_remove', JSON.stringify(['eventQuery']));
      const queryParams = getQueryParams(search, ['eventQuery']);
      const queryString = getQueryString(queryParams);
      localStorage.setItem('link_on_reload', JSON.stringify(queryString));
    }
    setSearchTerm(value);
    const events = JSON.parse(localStorage.getItem('events'));
    if (!events && events.length < 1) {
      return;
    }

    if (!value) {
      const filteredEvents = events.map(event => event);
      setFilteredEvents(filteredEvents);
      return;
    }

    const advancedSearch = value.slice(0, 4) === 'UID:' && !value.includes(' ');
    if (advancedSearch) {
      let eventsToIncludeInSearch = value.slice(4).split(',');
      let filteredEvents = events.filter(event => {
        return eventsToIncludeInSearch.includes(event.uniqueString);
      });
      setFilteredEvents(filteredEvents);
      return;
    }

    let filteredEvents = events.filter(event => {
      const { title, description } = getTitleAndDescription(event.textContent, locale.locale);

      return (
        event.uniqueString.toLowerCase().includes(value.toLowerCase()) ||
        title.toLowerCase().includes(value.toLowerCase()) ||
        description.toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredEvents(filteredEvents);
  };

  const handleRegister = async (packageID, uniqueString, start, title, zoomLink, builderAllLink, setLoading) => {
    dispatch(fetchStart());
    setLoading(true);
    const moralisUserEthAddress = Moralis.User.current().get('ethAddress');
    const metamaskEthAddress = await ethereum.selectedAddress;
    if (moralisUserEthAddress !== metamaskEthAddress) {
      dispatch(fetchError('Metamask wallet address has changed!'));
      setLoading(false);
      return;
    }
    let request = await getTelegramLink(uniqueString, authUser.userID);
    if (request.error) {
      dispatch(fetchError(request.error));
      setLoading(false);
      return;
    }
    const telegramLink = request.telegramLink;

    const email = authUser.email;
    request = await registerUserToBuilderAllClass(uniqueString, email);
    if (request.error) {
      await revokeTelegramLink(uniqueString, authUser.userID, telegramLink);
      dispatch(fetchError(request.error));
      setLoading(false);
      return;
    }

    const registeredToBuilderAll = request.registeredToBuilderAll ? true : false;

    const { ticket, ticketHashed } = generateTicket();
    request = await registerToEvent(packageID, uniqueString, ticketHashed);
    if (request.error) {
      await revokeTelegramLink(uniqueString, authUser.userID, telegramLink);
      await deactivateUserFromBuilderAllClass(uniqueString, email);
      dispatch(fetchError(request.error));
      setLoading(false);
      return;
    }

    const transactionHash = request.transactionHash;
    request = await addUserToEventList(uniqueString, authUser.userAddress);
    if (request.error) {
      await revokeTelegramLink(uniqueString, authUser.userID, telegramLink);
      await deactivateUserFromBuilderAllClass(uniqueString, email);
      dispatch(fetchError(request.error));
      setLoading(false);
      return;
    }

    const emailData = {
      email,
      date: start,
      title,
      locale: locale.locale,
      ticket,
      zoomLink,
      telegramLink,
      builderAllLink,
      registeredToBuilderAll,
    };
    setModalData(emailData);
    request = sendRegisterConfirmationEmailToUser(emailData);
    if (request.error) {
      dispatch(fetchError(request.error));
    }
    const adminNotificationData = {
      ticketID: ticket,
      eventUID: uniqueString,
      transactionHash,
    };
    request = sendAdminNotificationEmailOnEventRegistration(adminNotificationData);
    if (request.error) {
      dispatch(fetchError(request.error));
    }
    const { events } = await getAllEvents();
    const updatedEvents = events.map(event => ({ ...event }));
    dispatch(loadEvents(updatedEvents));
    setLoading(false);
    setIsOpen(true);
    dispatch(fetchSuccess(<IntlMessages id={'success.regiteredToEvent'} />));
  };
  events = events.map((event, i) => {
    if (!filteredEvents.find(filteredEvent => filteredEvent.uniqueString === event.uniqueString)) {
      return null;
    }
    const now = moment().valueOf();
    const startTime = event.start ? moment(event.start).valueOf() : null;
    const isRegistered = event.registeredUsers.find(
      ethAddress => ethAddress.toLowerCase() === authUser.userAddress.toLowerCase(),
    );
    const enabled = !isRegistered ? true : false;

    const { title, description } = getTitleAndDescription(event.textContent, locale.locale);

    if (!event.start || now < startTime) {
      return (
        <Event
          title={title}
          languages={event.languages}
          uniqueString={event.uniqueString}
          description={description}
          start={event.start}
          packageID={event.packageID}
          zoomLink={event.zoomLink}
          builderAllLink={event.builderAllLink}
          handleRegister={handleRegister}
          key={i}
          userID={authUser.userID}
          enabled={enabled}
        />
      );
    }
  });
  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer heading={<IntlMessages id="pages.eventsPage" />} breadcrumbs={breadcrumbs}>
        {loadModals && (
          <RegisterModal
            setIsOpen={setIsOpen}
            modalIsOpen={modalIsOpen}
            email={modalData.email}
            date={modalData.start}
            title={modalData.title}
            ticket={modalData.ticket}
            zoomLink={modalData.zoomLink}
            telegramLink={modalData.telegramLink}
            registeredToBuilderAll={modalData.registeredToBuilderAll}
            builderAllLink={modalData.builderAllLink}
          />
        )}
        <GridContainer>
          <Grid item xs={12}>
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}>
              <IntlMessages id="pages.packagesPage.description" />
              <SearchPopover searchTerm={searchTerm} setSearchTerm={onSearchTermChange} />
            </div>
            <Divider />
            <div style={{ marginTop: 24 }}>{events}</div>
          </Grid>
        </GridContainer>
      </PageContainer>
    </div>
  );
};

export default EventsPage;
