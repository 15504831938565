import React, { useEffect, useState } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { getAllMarketingResources } from 'db/functions';
import { useDispatch } from 'react-redux';
import { fetchStart, fetchSuccess } from '../../../redux/actions';
import SearchPopover from '@jumbo/components/AppLayout/partials/SearchPopover';
import Resource from 'components/ResourcesPage/Resource';
import VideoBackground from 'components/Common/VideoBackground';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.resourcesPage'} />, isActive: true },
];

const ResourcesPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [marketingResources, setMarketingResources] = useState([]);
  const [filteredMarketingResources, setFilteredMarketingResources] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      dispatch(fetchStart());
      const { marketingResources } = await getAllMarketingResources();
      setMarketingResources(marketingResources);
      setFilteredMarketingResources(marketingResources);
      dispatch(fetchSuccess());
    };
    loadData();
  }, []);

  const onSearchTermChange = value => {
    const filter = marketingResources.filter(resource => {
      return resource.title.includes(value) || resource.description.includes(value);
    });
    setFilteredMarketingResources(filter);
    setSearchTerm(value);
  };

  const resources = filteredMarketingResources.map((resource, i) => {
    return (
      <Resource
        key={i}
        title={resource.title}
        description={resource.description}
        url={resource.url}
        base64={resource.base64}
        filetype={resource.filetype}
      />
    );
  });

  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer id="resources_container" heading={<IntlMessages id="pages.resourcesPage" />} breadcrumbs={breadcrumbs}>
        <GridContainer>
          <Grid item xs={12}>
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}>
              <IntlMessages id="pages.resourcesPage.description" />
              <SearchPopover searchTerm={searchTerm} setSearchTerm={onSearchTermChange} />
            </div>
            <Divider />
            <div style={{ marginTop: 24 }}>{resources}</div>
          </Grid>
        </GridContainer>
      </PageContainer>
    </div>
  );
};

export default ResourcesPage;
