import React from 'react';
import Criteria from 'components/Common/Pools/Criteria';
import IntlMessages from '@jumbo/utils/IntlMessages';

const JoinCriterias = ({ joinClaimConditionsInfo, activityPoolSettings, careerInfo }) => {
  const userBanned = !joinClaimConditionsInfo.noBanSatisfied;

  const criteria = [
    {
      text: (
        <div>
          <IntlMessages id={'component.careerPoints'} /> ({joinClaimConditionsInfo.nCareerPointsDiff}/
          {activityPoolSettings.careerPointsThreshold})
        </div>
      ),
      conditionMet: joinClaimConditionsInfo.careerPointsSatisfied,
    },
    {
      text: (
        <div>
          <IntlMessages id={'component.invitedUsers'} /> ({joinClaimConditionsInfo.nInvitedDiff}/
          {activityPoolSettings.invitedThreshold})
        </div>
      ),
      conditionMet: joinClaimConditionsInfo.invitedSatisfied,
    },
    {
      text: (
        <div>
          <IntlMessages id={'component.minimumRank'} /> ({careerInfo.currentActiveRank}/{activityPoolSettings.minJoinRank})
        </div>
      ),
      conditionMet: joinClaimConditionsInfo.minRankSatisfied,
    },
    {
      text: <IntlMessages id={'component.activePromoter'} />,
      conditionMet: joinClaimConditionsInfo.promoterActiveSatisfied,
    },
    {
      text: <IntlMessages id={'component.noClaimsPending'} />,
      conditionMet: joinClaimConditionsInfo.zeroClaimableCanRejoin,
    },
  ];

  return (
    <div>
      <h3>
        <IntlMessages id={'component.criteriaForJoining'} />
      </h3>
      {!userBanned ? (
        criteria.map((criterion, index) => (
          <Criteria key={index} text={criterion.text} conditionMet={criterion.conditionMet} />
        ))
      ) : (
        <Criteria text={<IntlMessages id={'component.userNotApproved'} />} conditionMet={false} />
      )}
    </div>
  );
};

export default JoinCriterias;
