import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LockSharp } from '@material-ui/icons';

const useRankItemStyles = makeStyles(theme => ({
  rankContainer: {
    position: 'relative',
    margin: '20px 10px',
  },
  lockIconContainer: {
    position: 'absolute',
    right: 0,
  },
  lockIconContainerUnlockAvailable: {
    position: 'absolute',
    right: 0,
    animation: '$flash 2s infinite',
    transition: 'height 0.2s ease-in-out',
    '&:hover': {
      right: -2,
      top: -28,
      cursor: 'pointer',
      fontSize: 60,
      animation: 'none',
    },
  },
  '@keyframes flash': {
    '0%, 100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.4,
    },
  },
  lockIcon: {
    color: 'black',
  },
  lockIconUnlockAvailable: {
    color: 'green',
  },
  rankImage: {
    height: 60,
    maxWidth: 'fit-content',
    transition: 'height 0.2s ease-in-out',
    '&:hover': {
      height: 120,
      cursor: 'pointer',
    },
  },
  rankImageSelected: {
    height: 120,
    maxWidth: 'fit-content',
  },
  rankImageOpacity: {
    height: 60,
    maxWidth: 'fit-content',
    opacity: 0.3,
    transition: 'height 0.2s ease-in-out',
    '&:hover': {
      height: 120,
      cursor: 'pointer',
    },
  },
}));

const RankItem = ({ index, rank, isSelected, upgradingRank, rankUnlocked, selectRank, onUpgradeRank, unlockAvailable }) => {
  const classes = useRankItemStyles();

  const [unlockClicked, setUnlockClicked] = useState(false);

  useEffect(() => {
    setUnlockClicked(false);
  }, [upgradingRank]);

  const generateRankImageClass = () => {
    if (isSelected) {
      return classes.rankImageSelected;
    } else if (rankUnlocked || unlockAvailable) {
      return classes.rankImage;
    } else {
      return classes.rankImageOpacity;
    }
  };

  const onUnlockClick = () => {
    setUnlockClicked(true);
    onUpgradeRank();
  };

  return (
    <div className={classes.rankContainer} onClick={selectRank}>
      {!rankUnlocked && !unlockClicked && (
        <div
          className={unlockAvailable ? classes.lockIconContainerUnlockAvailable : classes.lockIconContainer}
          onClick={() => (unlockAvailable ? onUnlockClick() : null)}>
          <LockSharp className={unlockAvailable ? classes.lockIconUnlockAvailable : classes.lockIcon} fontSize="large" />
        </div>
      )}
      <img className={generateRankImageClass()} src={`/images/career_ranks/rank_${index}.png`} alt={`Rank ${rank.name}`} />
    </div>
  );
};

export default RankItem;
