import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PurchaseAgainButton from './PurchaseAgainButton';
import SelectTokenButtons from './SelectTokenButtons';
import ApproveAndPurchaseButtons from './ApproveAndPurchaseButtons';
import { getUnlockedStatus } from 'smart_contract/functions';
import { getSingleDiscountsInfos } from 'smart_contract/services';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess, loadPackages, clearAlert } from '../../../redux/actions';
import { checkAllowance } from './Functions';
import { approve, buyPackage, buyPackagePWD, getAllPurchases, getUsersInfos } from 'smart_contract/functions';
import {
  assignCoupon,
  reverseReserveCoupon,
  getPurchasePackageEvData,
  sendOutCoupon,
  getCouponSettingByKeyword,
  getSingleUserInfo,
} from 'db/functions';
import { refreshNftMetaData } from 'db/functions';
import web3 from 'web3';
import Moralis from 'moralis';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants';
import { useLocation } from 'react-router-dom';
import IntlMessages from '@jumbo/utils/IntlMessages';
const { ethereum } = window;

const PurchaseButtons = ({
  method = CurrentAuthMethod,
  purchaseEnabled,
  purchasesCount,
  disableBUSD,
  disablePWD,
  token,
  setToken,
  setDiscountsInfos,
  setPrice,
  packagePrice,
  packageID,
  userAddress,
  price,
  couponKeyword,
  setCoupon,
  setTransactionHash,
  setPID,
  userEmail,
  setCouponSetting,
  setCouponLoaded,
  setCopytradingLink = undefined,
  rebuyEnabled,
  setPurchaseEnabled,
  setPurchaseConfirmationIsOpen,
  referralID = 0,
  redirectToDashboardIfSuccessful = false,
  overrideSettingToken = false,
  disableSelectTokenMessage = false,
  customTokenButtonMessages = undefined,
  setExpirationTimestamp,
  setPaymentIcon,
  setDisplayBUSDinPrice,
  setUseBackgroundImage = () => {},
}) => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const { authUser } = useSelector(({ auth }) => auth);
  const [purchasing, setPurchasing] = useState(false);
  const [settingToken, setSettingToken] = useState(overrideSettingToken);
  const [tokenLoading, setTokenLoading] = useState(false);
  const [approveEnabled, setApproveEnabled] = useState(false);
  const [buyEnabled, setBuyEnabled] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);

  const packageQuery = new URLSearchParams(search).get('packageQuery');
  const eventQuery = new URLSearchParams(search).get('eventQuery');
  const loadView = new URLSearchParams(search).get('loadView');

  const pwdOnly = disableBUSD && !disablePWD;
  const busdOnly = disablePWD && !disableBUSD;

  useEffect(() => {
    if (overrideSettingToken && pwdOnly) {
      onSetToken('pwd');
    }

    if (overrideSettingToken && busdOnly) {
      onSetToken('busd');
    }
  }, []);

  const queryString = () => {
    let queryString = '';

    if (packageQuery) {
      queryString += `packageQuery=${packageQuery}&`;
    }

    if (eventQuery) {
      queryString += `eventQuery=${eventQuery}&`;
    }

    if (loadView) {
      queryString += `loadView=${loadView}&`;
    }

    return queryString;
  };

  const onPurchase = async () => {
    setUseBackgroundImage(true);

    if (pwdOnly) {
      await onSetToken('pwd');
      return;
    }

    if (busdOnly) {
      await onSetToken('busd');
      return;
    }

    setSettingToken(true);
  };

  const onSetToken = async token => {
    dispatch(fetchStart());
    setUseBackgroundImage(true);
    setToken(token);
    setTokenLoading(true);
    let discountsInfos = [];
    if (token === 'busd') {
      setPrice(packagePrice);
      setDisplayBUSDinPrice(true);
    } else if (token === 'pwd') {
      try {
        const request = await getSingleDiscountsInfos(packageID);
        discountsInfos = request.parsedDiscountsInfos;
        setDiscountsInfos(discountsInfos);
      } catch (error) {
        dispatch(fetchError(error.message));
        return;
      }
      setPrice(discountsInfos.discountedPricePWD);
      setPaymentIcon('pwd_token.png');
    }
    const moralisUserEthAddress = userAddress;
    const metamaskEthAddress = await ethereum.selectedAddress;
    if (moralisUserEthAddress !== metamaskEthAddress) {
      dispatch(fetchError('Metamask wallet address has changed!'));
      setTokenLoading(false);
      return;
    }
    const needsApproval = await checkAllowance(token, packagePrice, discountsInfos.discountedPricePWD, userAddress);
    if (needsApproval) {
      setTokenLoading(false);
      setApproveEnabled(true);
    } else {
      setBuyEnabled(true);
    }

    setSettingToken(false);
    setTokenLoading(false);
    setPurchasing(true);
    dispatch(fetchSuccess());
  };

  const onApprove = async () => {
    dispatch(fetchStart());
    setApproveLoading(true);
    setApproveEnabled(false);
    const moralisUserEthAddress = userAddress;
    const metamaskEthAddress = await ethereum.selectedAddress;
    if (moralisUserEthAddress !== metamaskEthAddress) {
      dispatch(fetchError('Metamask wallet address has changed!'));
      setApproveEnabled(true);
      setApproveLoading(false);
      return;
    }

    let tokenAddr;
    let spender;
    if (token === 'busd') {
      tokenAddr = process.env.REACT_APP_BUSD_TOKEN_ADDRESS;
      spender = process.env.REACT_APP_POWERMADE_AFFILIATION_CONTRACT_ADDRESS;
    } else if (token === 'pwd') {
      tokenAddr = process.env.REACT_APP_PWD_TOKEN_ADDRESS;
      spender = process.env.REACT_APP_DISCOUNT_WRAPPER_STANDALONE_CONTRACT_ADDRESS;
    }
    let request = await approve(web3.utils.fromWei(price), tokenAddr, spender);
    if (request.error) {
      dispatch(fetchError(request.error));
      setApproveEnabled(true);
      setApproveLoading(false);
      return;
    }

    setBuyEnabled(true);
    setApproveLoading(false);
    dispatch(fetchSuccess(<IntlMessages id={'success.approved'} />));
  };

  const onBuy = async () => {
    // LOG RELATED CODE -- START
    const date = new Date();
    const id = date.getTime();
    const user = await Moralis.User.currentAsync();
    const userAddress = user.get('ethAddress');

    const messageObject = {
      method,
      purchaseEnabled,
      purchasesCount,
      disableBUSD,
      disablePWD,
      token,
      setToken,
      setDiscountsInfos,
      setPrice,
      packagePrice,
      packageID,
      userAddress,
      price,
      couponKeyword,
      setCoupon,
      setTransactionHash,
      setPID,
      userEmail,
      setCouponSetting,
      setCouponLoaded,
      rebuyEnabled,
      setPurchaseEnabled,
      setPurchaseConfirmationIsOpen,
      referralID,
      redirectToDashboardIfSuccessful,
      overrideSettingToken,
      disableSelectTokenMessage,
      customTokenButtonMessages,
    };

    let message = `Purchasing package 001: ${JSON.stringify(messageObject)}`;
    await Moralis.Cloud.run('addLogMessage', { id, message, userAddress });
    // LOG RELATED CODE -- END
    dispatch(fetchStart());
    setUseBackgroundImage(true);
    setBuyEnabled(false);
    setBuyLoading(true);
    const moralisUserEthAddress = Moralis.User.current().get('ethAddress');
    const metamaskEthAddress = await ethereum.selectedAddress;
    if (moralisUserEthAddress !== metamaskEthAddress) {
      dispatch(fetchError('Metamask wallet address has changed!'));
      setApproveEnabled(true);
      setBuyLoading(false);
      return;
    }
    let request;

    let coupon = undefined;
    if (couponKeyword) {
      const userID = authUser.userID;
      request = await assignCoupon(couponKeyword, userID);
      if (request.error) {
        dispatch(fetchError(request.error));
        setBuyEnabled(true);
        setBuyLoading(false);
        return;
      }
      coupon = request.coupon;
    }
    // LOG RELATED CODE -- START
    message = `Purchasing package 002`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END

    if (token === 'busd') {
      // LOG RELATED CODE -- START
      message = `Purchasing package 003 - ${JSON.stringify({
        referralID,
        packageID,
        price,
      })}`;
      await Moralis.Cloud.run('addLogMessage', { id, message });
      // LOG RELATED CODE -- END
      request = await buyPackage(referralID, packageID, price);
    } else if (token === 'pwd') {
      // LOG RELATED CODE -- START
      message = `Purchasing package 004 - ${JSON.stringify({
        referralID,
        packageID,
        price,
      })}`;
      await Moralis.Cloud.run('addLogMessage', { id, message });
      // LOG RELATED CODE -- END
      request = await buyPackagePWD(referralID, packageID, price);
    }
    if (request.error) {
      if (request.error === 'execution reverted: RebuybeD') {
        if (coupon && coupon.code) {
          await reverseReserveCoupon(coupon.code);
        }
        dispatch(fetchError('Please wait for the expiration before buying the package again'));
      } else {
        if (coupon && coupon.code) {
          await reverseReserveCoupon(coupon.code);
        }
        dispatch(fetchError(request.error));
      }
      setBuyEnabled(true);
      setBuyLoading(false);
      return;
    }
    // LOG RELATED CODE -- START
    message = `Purchasing package 005`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END
    const transactionHashToSet = request.response.transactionHash;
    setTransactionHash(request.response.transactionHash);
    // LOG RELATED CODE -- START
    message = `Purchasing package 006 - ${JSON.stringify({
      transactionHashToSet,
    })}`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END
    request = await getPurchasePackageEvData(transactionHashToSet);
    // LOG RELATED CODE -- START
    message = `Purchasing package 007 - ${JSON.stringify({ request })}`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END
    let PidToSet = '';
    let purchaseID;
    if (!request.error) {
      PidToSet = request.response.PID;
      purchaseID = PidToSet;
      setPID(PidToSet);
    }

    const copytradingPackageIds = process.env.REACT_APP_COPYTRADING_PACKAGE_IDs.split(',');
    if (copytradingPackageIds.some(copytradingPackageId => copytradingPackageId == packageID)) {
      const response = await Moralis.Cloud.run('getCopytradingPackageLink', { packageID: Number(packageID), userID: authUser.userID.toString() });
      if (response.success) {
        setCopytradingLink(response.link);
      }
    }

    // LOG RELATED CODE -- START
    message = `Purchasing package 008`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END
    if (coupon) {
      const userID = authUser.userID;
      await sendOutCoupon(coupon.code, userEmail, userID, purchaseID, packageID);
      if (!request.error) {
        request = await getCouponSettingByKeyword(couponKeyword);
        if (!request.error) {
          setCoupon(coupon);
          setCouponSetting(request.couponSetting);
          setCouponLoaded(true);
        }
      } else if (request.error && request.error.message === 'Could not verify') {
        coupon = undefined;
      }
    }

    if (packageID === process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID) {
      await refreshNftMetaData(authUser.userID);
    }

    // LOG RELATED CODE -- START
    message = `Purchasing package 009`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END

    const packages = JSON.parse(localStorage.getItem('packages'));
    if (packages) {
      const packageIndex = packages.findIndex(_package => _package.packageID === packageID);
      const updatedPackages = packages.map(_package => ({ ..._package }));
      updatedPackages[packageIndex].purchasesCount++;
      if (updatedPackages[packageIndex].availableUnits != -1) {
        updatedPackages[packageIndex].availableUnits--;
      }
      dispatch(loadPackages(updatedPackages));
    }
    dispatch(fetchSuccess(<IntlMessages id={'success.purchaseCompleted'} />));

    // LOG RELATED CODE -- START
    message = `Purchasing package 010`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END

    if (redirectToDashboardIfSuccessful) {
      // LOG RELATED CODE -- START
      message = `Purchasing package 011`;
      await Moralis.Cloud.run('addLogMessage', { id, message });
      // LOG RELATED CODE -- END
      request = await getUsersInfos(); // Checking if user is registered in SC
      // LOG RELATED CODE -- START
      message = `Purchasing package 012 - ${JSON.stringify({ request })}`;
      await Moralis.Cloud.run('addLogMessage', { id, message });
      // LOG RELATED CODE -- END
      if (request.error) {
        window.location.assign(`/?${queryString()}`);
      }
      let userID;
      let totalEarned;
      if (request.usersInfos) {
        userID = parseInt(request.usersInfos.IDs[1]._hex, 16);
        totalEarned = parseInt(request.usersInfos.amounts[0]._hex, 16);
      } else {
        window.location.assign(`/?${queryString()}`);
      }

      request = getSingleUserInfo();
      // LOG RELATED CODE -- START
      message = `Purchasing package 013 - ${JSON.stringify({ request })}`;
      await Moralis.Cloud.run('addLogMessage', { id, message });
      // LOG RELATED CODE -- END
      if (request.error) {
        dispatch(fetchError(request.error));
        return;
      }
      const user = request.userInfo;

      const userAuthData = {
        userAddress: userAddress,
        username: user.get('username'),
        email: user.get('email'),
        firstname: user.get('firstname'),
        lastname: user.get('lastname'),
        userID,
        totalEarned,
      };
      // LOG RELATED CODE -- START
      message = `Purchasing package 014 - ${JSON.stringify({ userAuthData })}`;
      await Moralis.Cloud.run('addLogMessage', { id, message });
      // LOG RELATED CODE -- END

      const awaitGetUsersInfos = async (retries = 10, delay = 10000) => {
        try {
          const isUserRegistered = await getUsersInfos();
          if (isUserRegistered.error) {
            if (isUserRegistered.error === 'execution reverted: userID') {
              if (retries > 0) {
                console.log(`User not found yet, retrying in ten seconds`);
                await new Promise(resolve => setTimeout(resolve, delay));
                await awaitGetUsersInfos(retries--, delay);
              } else {
                throw new Error('Max retry count reached.');
              }
            } else {
              dispatch(fetchError('Some other error occurred.'));
            }
          }
        } catch (error) {
          console.error('An unexpected error occurred:', error);
          throw error; // Re-throw the error to be caught by the higher-level code
        }
      };

      try {
        await awaitGetUsersInfos(10, 10000);
      } catch (error) {
        dispatch(AuhMethods[method].onLogout());
        return;
      }

      dispatch(AuhMethods[method].onLogin());
      // LOG RELATED CODE -- START
      message = `Purchasing package 015`;
      await Moralis.Cloud.run('addLogMessage', { id, message });
      // LOG RELATED CODE -- END
      return;
    }

    const response = await getAllPurchases(authUser.userID, packageID);
    if (!response.error) {
      const parsedPurchasesInfo = response.parsedPurchasesInfo[0];
      const expired = parsedPurchasesInfo.expired;
      const timestamp = parsedPurchasesInfo.timestamp;
      const duration = parsedPurchasesInfo.duration;
      const expirationTimestamp = Number(timestamp) + Number(duration);
      setExpirationTimestamp(expirationTimestamp * 1000);

      if (packageID == process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID) {
        const { unlockedStatus } = await getUnlockedStatus(
          authUser.userID,
          undefined,
          process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID,
        );
        dispatch(clearAlert('subscriptionPackageAboutToExpire'));
        const newAuthUser = { ...authUser };
        newAuthUser.subscriptionRenewedTimestamp = timestamp;
        newAuthUser.subscriptionExpired = expired;
        newAuthUser.expirationTimestamp = expirationTimestamp;
        newAuthUser.levelsUnlocked = unlockedStatus.levelsUnlocked;
        dispatch(AuhMethods[method].setAuthUser(newAuthUser));
      }
    }

    if (rebuyEnabled) {
      setPurchaseEnabled(true);
    }
    setPurchasing(false);
    setBuyLoading(false);
    setToken('busd');
    setDisplayBUSDinPrice(false);
    setUseBackgroundImage(false);
    setPrice(packagePrice);
    if (pwdOnly) {
      setPaymentIcon('pwd_token.png');
    } else {
      setPaymentIcon('money_pay.png');
    }

    setPurchaseConfirmationIsOpen(true);
    return;
  };

  return (
    <div>
      {!settingToken && !purchasing && (
        <PurchaseAgainButton purchaseEnabled={purchaseEnabled} onPurchase={onPurchase} purchasesCount={purchasesCount} />
      )}
      {settingToken && (
        <SelectTokenButtons
          disableBUSD={disableBUSD}
          disablePWD={disablePWD}
          tokenLoading={tokenLoading}
          token={token}
          onSetToken={onSetToken}
          disableSelectTokenMessage={disableSelectTokenMessage}
          customTokenButtonMessages={customTokenButtonMessages}
        />
      )}
      {purchasing && (
        <ApproveAndPurchaseButtons
          approveEnabled={approveEnabled}
          approveLoading={approveLoading}
          onApprove={onApprove}
          buyEnabled={buyEnabled}
          buyLoading={buyLoading}
          onBuy={onBuy}
        />
      )}
    </div>
  );
};

export default PurchaseButtons;
