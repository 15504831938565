import React from 'react';
import {
  getCareerRankUpgradeAvailableAlert,
  getActivityPoolTimeRoundEndingAlert,
  getActivityPoolJoinCriteriaMetButNotJoinedAlert,
  getActivityPoolClaimAvailableAlert,
  getInfinityPoolClaimAvailableAlert,
  getSubscriptionAboutToExpireAlert,
  getNewDownlineUsersAlert,
} from './helpers';

const loadAlerts = async userAddress => {
  const alerts = [];

  const alertsToCheck = [
    getCareerRankUpgradeAvailableAlert,
    getActivityPoolTimeRoundEndingAlert,
    getActivityPoolJoinCriteriaMetButNotJoinedAlert,
    getActivityPoolClaimAvailableAlert,
    getInfinityPoolClaimAvailableAlert,
    getSubscriptionAboutToExpireAlert,
    getNewDownlineUsersAlert,
  ];

  for (const alertToCheck of alertsToCheck) {
    const alert = await alertToCheck(userAddress);
    if (alert) {
      alerts.push(alert);
    }
  }

  return alerts;
};

export { loadAlerts };
