import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ExpiresWidget from 'components/Common/ExpiresWidget/ExpiresWidget';
import SubscriptionPurchaseButton from 'components/Common/SubcriptionPurchaseButton/SubscriptionPurchaseButton';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  navHeader: {
    marginBottom: 5,
    marginRight: 5,
    position: 'relative',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 15,
    letterSpacing: 1.5,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  button: {
    marginTop: 10,
    background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
    textShadow: '2px 2px 1px #311b1b',
    color: '#FFFFFF',
    backgroundColor: '#e0e0e0',
  },
  alertIcon: {
    animation: '$fadeInOut 1s infinite',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    width: 28,
    height: 28,
    borderRadius: 14,
    boxShadow: '0px 0px 16px red',
    marginRight: 2.5,
    marginLeft: 2.5,
  },
  '@keyframes fadeInOut': {
    '0%, 100%': { opacity: 1 },
    '50%': { opacity: 0.6 },
  },
}));

const SubscriptionInfo = ({ alerts }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  let expiration;
  let renewed;
  if (authUser && authUser.expirationTimestamp) {
    expiration = authUser.expirationTimestamp * 1000;
  }

  if (authUser && authUser.subscriptionRenewedTimestamp) {
    renewed = authUser.subscriptionRenewedTimestamp * 1000;
  }

  return (
    <div className={classes.root} id={'subscription-expiration'}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.navHeader}>
          <IntlMessages id={'sidebar.subscription'} />
        </Typography>
        {alerts &&
          alerts.length > 0 &&
          alerts.map(alert => {
            return <div className={classes.alertIcon}>{alert.icon}</div>;
          })}
        </div>
      <div style={{ marginBottom: 10 }}>
        {authUser && renewed ? (
          <div>
            <Typography component="h4" variant="h4" style={{ whiteSpace: 'nowrap' }}>
              <IntlMessages id={'component.lastRenewed'} /> {moment(renewed).format('YYYY-MM-DD')}
            </Typography>
          </div>
        ) : null}
        {authUser && expiration ? (
          <div>
            <Typography component="h4" variant="h4" style={{ whiteSpace: 'nowrap' }}>
              <ExpiresWidget expirationTimestamp={expiration} />
            </Typography>
          </div>
        ) : null}
        <SubscriptionPurchaseButton />
      </div>
    </div>
  );
};

export default SubscriptionInfo;
