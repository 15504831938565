const getTitleAndDescription = (textContent, locale) => {
  const textInLocaleIndex = textContent.findIndex(obj => obj.language === locale);
  
  let index;
  if (textInLocaleIndex > -1) {
    index = textInLocaleIndex;
  } else {
    index = 0;
  }

  const title = textContent[index].title;
  const description = textContent[index].description;
  return { title, description };
}

export { getTitleAndDescription };
