import React from 'react';
import CountdownTimerSubscriptionPackage from './CountdownTimerSubscriptionPackage';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';

const ExpiresWidget = ({ expirationTimestamp }) => {
  // expirationTimestamp = expirationTimestamp + 400000; // For testing
  const momentObj = moment(expirationTimestamp);

  const now = moment();
  const twentyFourHoursBeforeMomentObj = momentObj.clone().subtract(24, 'hours');

  let expired = false;
  if (momentObj.isBefore(now)) {
    expired = true;
  }

  let expiresTimer = false;
  if (momentObj.isAfter(now) && twentyFourHoursBeforeMomentObj.isBefore(now)) {
    expiresTimer = true;
  }

  const formatExpirationTimestamp = expirationTimestamp => {
    return moment(expirationTimestamp).format('YYYY-MM-DD');
  };

  return (
    <div>
      {!expiresTimer && expirationTimestamp && !expired && (
        <p>
          <IntlMessages id={'component.expiresOn'} /> <strong>{formatExpirationTimestamp(expirationTimestamp)}</strong>
        </p>
      )}
      {!expiresTimer && expirationTimestamp && expired && (
        <p style={{ color: 'red' }}>
          <IntlMessages id={'component.expiredOn'} /> <strong>{formatExpirationTimestamp(expirationTimestamp)}</strong>
        </p>
      )}
      {expiresTimer && expirationTimestamp && !expired && (
        <div>
          <CountdownTimerSubscriptionPackage targetTimestamp={expirationTimestamp} />
        </div>
      )}
    </div>
  );
};

export default ExpiresWidget;
