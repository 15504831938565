import React from 'react';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchSuccess } from '../../redux/actions';
import { QRCodeCanvas } from 'qrcode.react';
import calculateSideMarginsToCenterModal from 'components/Functions/calculateSideMarginsToCenterModal';
import IntlMessages from '@jumbo/utils/IntlMessages';

const RegisterModal = ({
  setIsOpen,
  modalIsOpen,
  title,
  ticket,
  zoomLink,
  telegramLink,
  registeredToBuilderAll,
  builderAllLink,
}) => {
  Modal.setAppElement('.Cmt-appMainContent');
  const screenWidth = window.innerWidth;
  const dispatch = useDispatch();
  const modalStyles = {
    content: {
      position: screenWidth > 910 ? 'relative' : 'none',
      zIndex: 1,
      marginTop: 120,
      marginRight: calculateSideMarginsToCenterModal(800).marginRight,
      marginLeft: calculateSideMarginsToCenterModal(800).marginLeft,
      maxHeight: 600,
      width: 800,
      maxWidth: '100%',
      overflowY: 'scroll',
    },
  };
  const contentStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginBottom: 500,
  };
  const QRStyles = {
    position: 'relative',
    alignSelf: 'center',
  };
  const paragraphStyles = {
    marginBottom: 5,
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const generateCopyData = () => {
    let copyData = `Event: ${title}\nTicket ID: ${ticket}`;
    if (zoomLink) {
      copyData += `\nZoom Link: ${zoomLink}`;
    }

    if (telegramLink) {
      copyData += `\nTelegram Link: ${telegramLink}`;
    }

    if (registeredToBuilderAll && builderAllLink) {
      copyData += `\nBuilder All Link: ${builderAllLink}`;
    }

    return copyData;
  };

  return (
    <Modal style={modalStyles} isOpen={modalIsOpen} contentLabel={'Register Modal'} onRequestClose={handleModalClose}>
      <Button onClick={handleModalClose}>Close</Button>
      <CmtCardContent style={contentStyles}>
        <h2>
          <IntlMessages id={'component.registrationConfirmation'} />
        </h2>
        <p>
          <IntlMessages id={'component.registerModal.part1'} /> {title}
        </p>
        <p>
          <IntlMessages id={'component.registerModal.part2'} />: <strong>{ticket}</strong>
        </p>
        <QRCodeCanvas value={ticket} includeMargin={true} style={QRStyles} size={200} />
        {zoomLink && (
          <p style={paragraphStyles}>
            <IntlMessages id={'component.registerModal.part3'} />
            {': '}
            <a href={zoomLink} target="_blank" rel="noreferrer">
              {zoomLink}
            </a>
          </p>
        )}
        {telegramLink && (
          <p style={paragraphStyles}>
            <IntlMessages id={'component.registerModal.part4'} />
            {': '}
            <a href={telegramLink} target="_blank" rel="noreferrer">
              {telegramLink}
            </a>
          </p>
        )}
        {registeredToBuilderAll && (
          <p style={paragraphStyles}>
            <IntlMessages id={'component.registerModal.part5'} />
          </p>
        )}
        {registeredToBuilderAll && builderAllLink && (
          <p style={paragraphStyles}>
            <IntlMessages id={'component.registerModal.part6'} />
            {': '}
            <a href={builderAllLink} target="_blank" rel="noreferrer">
              {builderAllLink}
            </a>
          </p>
        )}
        <Button
          onClick={() => {
            const copyData = generateCopyData();
            navigator.clipboard.writeText(copyData);
            dispatch(fetchSuccess(<IntlMessages id={'component.copied'} />));
          }}>
          <IntlMessages id={'component.copyInformation'} />
        </Button>
        <p style={{ textAlign: 'center' }}>
          <strong>
            <IntlMessages id={'component.registerModal.part7'} />
          </strong>
        </p>
      </CmtCardContent>
    </Modal>
  );
};

export default RegisterModal;
