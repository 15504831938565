const getQueryParams = (search, restrict = []) => {
  const params = [
    { ref: new URLSearchParams(search).get('ref') },
    { packageQuery: new URLSearchParams(search).get('packageQuery') },
    { eventQuery: new URLSearchParams(search).get('eventQuery') },
    { loadView: new URLSearchParams(search).get('loadView') },
    { popup: new URLSearchParams(search).get('popup') },
  ];

  let paramsToSet = {};

  params.forEach(param => {
    const key = Object.keys(param)[0];
    const value = Object.values(param)[0];
    if (value && !restrict.find(element => element === key)) {
      paramsToSet[key] = value;
    }
  });

  return paramsToSet;
};

const getQueryString = queryParams => {
  let queryString = '?';

  for (const [key, value] of Object.entries(queryParams)) {
    if (key) {
      queryString += `${key}=${value}&`;
    }
  }
  return queryString.slice(0, queryString.length - 1);
};

export { getQueryParams, getQueryString };
