import React from 'react';
import { Typography } from '@material-ui/core';
import Xarrow, { Xwrapper } from 'react-xarrows';
import User from './User';
import Navigator from './Navigator';
import NetworkStats from './NetworkStats';
import IntlMessages from '@jumbo/utils/IntlMessages';

const NetworkTreeDesktop = ({
  networkUsers,
  handleGoBack,
  handleGoForward,
  handleGoMe,
  handleSearch,
  downlineUsers,
  invitedUsers,
  downlineXarrowAnchors,
  usernames,
  subscriptionExpirations,
}) => {
  return (
    <Xwrapper>
      <div
        style={{
          marginTop: 24,
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap-reverse',
        }}>
        {usernames && subscriptionExpirations && (
          <div style={{ minWidth: '50%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginTop: 40 }}>
                    <User
                      role={'Source'}
                      username={networkUsers.source ? usernames[networkUsers.source] : undefined}
                      subscriptionExpiration={networkUsers.source ? subscriptionExpirations[networkUsers.source] : undefined}
                      arrowID={'source'}
                      userID={networkUsers.source}
                    />
                  </div>
                  {networkUsers.fullNetwork && (
                    <div id="stats" style={{ marginLeft: 50 }}>
                      <NetworkStats fullNetwork={networkUsers.fullNetwork} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            minWidth: '50%',
            position: 'absolute',
            top: 250,
            right: 50,
          }}>
          <Navigator
            handleGoBack={handleGoBack}
            handleGoForward={handleGoForward}
            handleGoMe={handleGoMe}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      {downlineUsers.length > 0 && (
        <div style={{ marginBottom: 60, display: 'flex', justifyContent: 'center' }}>
          <Typography component="h1" variant="h1" style={{ color: 'white' }}>
            <IntlMessages id={'component.downline'} />: {downlineUsers.length}/5
          </Typography>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          overflowX: 'scroll',
          marginTop: 40,
        }}>
        {downlineUsers}
      </div>
      {invitedUsers && invitedUsers.length > 0 && (
        <div style={{ marginTop: 100 }}>
          <Typography component="h1" variant="h1">
            <IntlMessages id={'component.directUsersList'} />
          </Typography>
          <div style={{ display: 'flex', overflowX: 'scroll' }}>{invitedUsers}</div>
        </div>
      )}
      {invitedUsers && invitedUsers.length > 0 && (
        <div>
          <Xarrow
            animateDrawing={true}
            color="white"
            path={'straight'}
            startAnchor={'right'}
            endAnchor={'left'}
            start="source"
            end="stats"
          />
          {downlineXarrowAnchors}
        </div>
      )}
    </Xwrapper>
  );
};

export default NetworkTreeDesktop;
