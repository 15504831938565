import React from 'react';
import PackagesPage from 'components/PackagesPage';

const GiftCardsPage = () => {
  return (
    <div>
      <PackagesPage
        category={'gift_cards'}
        messageID={'pages.marketplace.giftCardsPage'}
        descriptionID={'pages.marketplace.giftCardsPage.description'}
      />
    </div>
  );
};

export default GiftCardsPage;
