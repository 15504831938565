import React from 'react';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchSuccess } from '../../redux/actions';
import { QRCodeCanvas } from 'qrcode.react';
import moment from 'moment';
import calculateSideMarginsToCenterModal from 'components/Functions/calculateSideMarginsToCenterModal';
import IntlMessages from '@jumbo/utils/IntlMessages';

const CouponModal = ({ setIsOpen, modalIsOpen, code, url, expiration, description, type, duration, forceCouponCode }) => {
  Modal.setAppElement('.Cmt-appMainContent');
  const screenWidth = window.innerWidth;
  const dispatch = useDispatch();
  const modalStyles = {
    content: {
      position: screenWidth > 910 ? 'relative' : 'none',
      zIndex: 1,
      marginTop: 120,
      marginRight: calculateSideMarginsToCenterModal(800).marginRight,
      marginLeft: calculateSideMarginsToCenterModal(800).marginLeft,
      maxHeight: 600,
      width: 800,
      maxWidth: '100%',
      overflowY: 'scroll',
    },
  };
  const contentStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  };
  const anchorStyles = {
    fontWeight: 'bolder',
  };
  const QRStyles = {
    position: 'relative',
    alignSelf: 'center',
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const generateCopyData = () => {
    let copyData = `Description: ${description}\nCode: ${code}`;

    if (url) {
      copyData += `\nUrl: ${url}`;
    }

    if (duration) {
      copyData += `\nService duration: ${duration}`;
    }

    if (expiration) {
      copyData += `\nValid until: ${moment(expiration)
        .utc()
        .format('MMM DD YYYY HH:mm:ss')} GMT+0000 (Coordinated Universal Time)}`;
    }

    return copyData;
  };

  const urlText = () => {
    if (!url) {
      return '';
    }

    if (forceCouponCode) {
      return `Link to ${type}`;
    } else {
      return `Click here to redeem the ${type}`;
    }
  };

  const qrContent = () => {
    if (forceCouponCode || !url) {
      return code;
    } else {
      return url;
    }
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={modalIsOpen}
      contentLabel={'Coupon Confirmation Modal'}
      onRequestClose={handleModalClose}>
      <Button onClick={handleModalClose}>
        <IntlMessages id={'component.close'} />
      </Button>
      <CmtCardContent style={contentStyles}>
        <h2>{type.toUpperCase()} INFORMATION</h2>
        {description && (
          <p>
            <IntlMessages id={'component.description'} />: {description}
          </p>
        )}
        {code && (
          <p>
            <IntlMessages id={'component.code'} />: {code}
          </p>
        )}
        {url && (
          <a style={anchorStyles} href={url} target="_blank" rel="noreferrer">
            {urlText()}
          </a>
        )}
        {code && <QRCodeCanvas value={qrContent()} includeMargin={true} style={QRStyles} size={200} />}
        {duration && (
          <p>
            <IntlMessages id={'component.serviceDuration'} />: {duration} <IntlMessages id={'component.days'} />
          </p>
        )}
        {expiration && (
          <p>
            <IntlMessages id={'component.validUntil'} />
            {': '}
            {`${moment(expiration)
              .utc()
              .format('MMM DD YYYY HH:mm:ss')} GMT+0000 (Coordinated Universal Time)`}
          </p>
        )}
        <Button
          onClick={() => {
            const copyData = generateCopyData();
            navigator.clipboard.writeText(copyData);
            dispatch(fetchSuccess(<IntlMessages id={'success.copied'} />));
          }}>
          <IntlMessages id={'component.copyInformation'} />
        </Button>
      </CmtCardContent>
    </Modal>
  );
};

export default CouponModal;
