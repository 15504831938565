import React, { cloneElement, isValidElement, useContext, useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { List } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarThemeContext from '../../CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import LayoutContext from '@coremat/CmtLayouts/LayoutContext';

const useStyles = makeStyles(theme => ({
  navMenuItem: {
    padding: '0 16px 0 0',
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
    margin: '5px 0 5px 0',
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
    color: 'white',
    '&:hover, &:focus': {
      color: props => props.sidebarTheme.textColor,
      background: props => props.sidebarTheme.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textColor,
      },
    },
    '&.active': {
      color: props => props.sidebarTheme.textActiveColor,
      background: props => props.sidebarTheme.navActiveBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: props => props.sidebarTheme.textActiveColor,
        },
      },
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navSubMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    background: 'url(/images/gray_bg.jpg)',
    color: '#b08a54',
    fontWeight: 'bold',
    '&:hover, &:focus': {
      color: props => props.sidebarTheme.textColor,
      background: props => props.sidebarTheme.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textColor,
      },
    },
    '&.active': {
      color: props => props.sidebarTheme.textActiveColor,
      background: props => props.sidebarTheme.navActiveBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: props => props.sidebarTheme.textActiveColor,
        },
      },
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: 20,
    whiteSpace: 'nowrap',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 30,
    animation: 'bounce 1s infinite',
    color: '#275398',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
  alertIcon: {
    animation: '$fadeInOut 1s infinite',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    width: 28,
    height: 28,
    borderRadius: 14,
    boxShadow: '0px 0px 16px red',
    marginRight: 2.5,
    marginLeft: 2.5,
  },
  '@keyframes fadeInOut': {
    '0%, 100%': { opacity: 1 },
    '50%': { opacity: 0.6 },
  },
}));

const NavMenuItem = props => {
  const { name, icon, link, subMenu = false, alerts } = props;
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const { setSidebarOpen } = useContext(LayoutContext);
  const classes = useStyles({ sidebarTheme });

  const [filteredAlerts, setFilteredAlerts] = useState(undefined);

  const filterAlerts = alerts => {
    const filteredAlerts = alerts.filter(alert => alert.path.some(path => path === link));
    return filteredAlerts.slice(0, 5); // Limit results to 5 so they all fit in the menu row
  };

  useEffect(() => {
    const result = filterAlerts(alerts);
    setFilteredAlerts(result);
  }, [alerts]);

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  return (
    <List component="div" className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}>
      <NavLink
        onClick={() => setSidebarOpen(false)}
        className={clsx(subMenu ? classes.navSubMenuLink : classes.navMenuLink, 'Cmt-nav-menu-link')}
        to={link}>
        {/* Display an icon if any */}
        {renderIcon()}
        <span className={clsx(classes.navText, 'Cmt-nav-text')}>{name}</span>
        {filteredAlerts &&
          filteredAlerts.length > 0 &&
          filteredAlerts.map(alert => {
            return <div className={classes.alertIcon}>{alert.icon}</div>;
          })}
      </NavLink>
      <style>{`
        @keyframes bounce {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-5px);
          }
          100% {
            transform: translateY(0);
          }
        }
      `}</style>
    </List>
  );
};

export default NavMenuItem;
