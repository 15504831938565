import React, { useState, useEffect } from 'react';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import LoadButton from 'components/Common/LoadButton';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { useLocation } from 'react-router-dom';
import { getQueryParams, getQueryString } from 'routes/queryFunctions';

const { ethereum } = window;

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [loading, setLoading] = useState(false);
  const [chainChanged, setChainChanged] = useState(false);
  const [authorizedChain, setAuthorizedChain] = useState(true);
  const search = useLocation().search;
  const queryParams = getQueryParams(search);

  const dispatch = useDispatch();
  const error = useSelector(({ common }) => common.error);
  const isUserRegistered = error !== 'Registration needed before proceeding';
  const classes = useStyles({ variant });

  useEffect(() => {
    const acceptedChain = async () => {
      setChainChanged(false);
      const currentChain = await ethereum.request({ method: 'eth_chainId' });
      currentChain === process.env.REACT_APP_CHAIN_ID ? setAuthorizedChain(true) : setAuthorizedChain(false);
    };
    acceptedChain();
  }, [chainChanged]);

  if (ethereum) {
    ethereum.on('chainChanged', () => {
      setChainChanged(true);
    });

    ethereum.on('accountsChanged', () => {
      ethereum.isConnected() ? setChainChanged(true) : setAuthorizedChain(false);
    });
  }

  const onSubmit = async () => {
    setLoading(true);
    dispatch(AuhMethods[method].onLogin(setLoading));
  };

  const isMetamaskInstalled = () => {
    return ethereum && ethereum.isMetaMask;
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      {!isUserRegistered ? window.location.assign(`/signup${getQueryString(queryParams)}`) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo-white.png'} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mb={5}>
          {isMetamaskInstalled() && authorizedChain ? (
            <LoadButton
              loading={loading}
              disabled={loading}
              onClick={() => {
                if (!loading) {
                  onSubmit();
                }
              }}
              messageID={'appModule.metamask.connect'}
            />
          ) : (
            <Button
              onClick={() => (!isMetamaskInstalled() ? window.open('https://metamask.io/download/', '_blank') : null)}
              variant="contained"
              disabled={!authorizedChain}
              style={{
                background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
                textShadow: '2px 2px 1px #311b1b',
                color: 'white',
                zIndex: 1,
              }}>
              {!isMetamaskInstalled() ? (
                <IntlMessages id="appModule.metamask.download" />
              ) : (
                <IntlMessages id="appModule.metamask.notConnected" />
              )}
            </Button>
          )}
        </Box>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
