import React from 'react';
import PackagesPage from 'components/PackagesPage';

const PowerFirePage = () => {
  return (
    <div>
      <PackagesPage
        category={'power_fire'}
        messageID={'pages.pack.powerFirePage'}
        descriptionID={'pages.pack.powerFirePage.description'}
      />
    </div>
  );
};

export default PowerFirePage;
