import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';

const FilterInterface = ({ defaultValue, filter, setFilter }) => {
  return (
    <FormControl>
      <FormLabel>
        <IntlMessages id={'component.filter'} />
      </FormLabel>
      <RadioGroup defaultValue={defaultValue} row value={filter} onChange={value => setFilter(value.currentTarget.value)}>
        <FormControlLabel value={'all'} control={<Radio color="default" />} label={<IntlMessages id={'component.all'} />} />
        <FormControlLabel
          value={'participated'}
          control={<Radio color="default" />}
          label={<IntlMessages id={'component.participated'} />}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default FilterInterface;
