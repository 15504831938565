import React from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import ClipLoader from 'react-spinners/ClipLoader';

const LoadButton = ({ loading, disabled, onClick, messageID, customStyle, customMessage = undefined }) => {
  if (!disabled) {
    customStyle = {
      ...customStyle,
      background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
      textShadow: '2px 2px 1px #311b1b',
    };
  }

  return (
    <Button style={customStyle} disabled={disabled} onClick={onClick} variant="contained" color="primary">
      {customMessage ? customMessage : <IntlMessages id={messageID} />}
      {loading && <div style={{ width: 10 }} />}
      <ClipLoader loading={loading} />
    </Button>
  );
};

export default LoadButton;
