import React, { cloneElement, isValidElement, useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { Collapse, List, ListItem } from '@material-ui/core';
import UserWallet from './UserWallet';
import UserInfo from './UserInfo';
import EarningsInfo from './EarningsInfo';
import LevelsInfo from './LevelsInfo/LevelsInfo';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import makeStyles from '@material-ui/core/styles/makeStyles';

import NavSection from './NavSection';
import NavMenuItem from './NavMenuItem';
import SidebarThemeContext from '../../CmtLayouts/SidebarThemeContext/SidebarThemeContext';

import AlertIcon from './AlertIcon';

const useStyles = makeStyles(theme => ({
  navCollapseBtn: {
    position: 'relative',
    padding: '0 16px 0 0',
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
  },
  navCollapse: {
    position: 'relative',
    marginTop: 5,
    '&.open': {
      '& .Cmt-navCollapseBtn': {
        color: 'white',
      },
      '& .Cmt-iconRoot': {
        color: theme.palette.primary.main,
      },
    },
    '& .Cmt-navHeader': {
      paddingLeft: 36,
    },
  },

  navCollapseBtnInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
    '&:hover, &:focus': {
      color: '#b08a54',
      background: 'white',
      '& .Cmt-iconRoot': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
    '.Cmt-miniLayout &': {
      paddingRight: 13,
      paddingLeft: 13,
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      paddingRight: 16,
      paddingLeft: 32,
    },
  },
  navSubCollapseBtnInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    background: 'url(/images/gray_bg.jpg)',
    color: '#b08a54',
    fontWeight: 'bold',
    '&:hover, &:focus': {
      background: 'white',
      '& .Cmt-iconRoot': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
    '.Cmt-miniLayout &': {
      paddingRight: 13,
      paddingLeft: 13,
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      paddingRight: 16,
      paddingLeft: 32,
    },
  },
  navSubCollapseBtnInnerGold: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
    color: 'white',
    fontWeight: 'bold',
    '&:hover, &:focus': {
      background: 'white',
      '& .Cmt-iconRoot': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
    '.Cmt-miniLayout &': {
      paddingRight: 13,
      paddingLeft: 13,
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      paddingRight: 16,
      paddingLeft: 32,
    },
    '&:hover': {
      color: '#b08a54',
    },
  },
  navText: {
    flex: 1,
    fontSize: 20,
    letterSpacing: 0.25,
    whiteSpace: 'nowrap',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 30,
    color: '#275398',
    animation: 'bounce 1s infinite',
  },
  navArrow: {
    position: 'absolute',
    left: 8,
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    fontSize: 16,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  navCollapseItem: {
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
    right: 20,
  },
  navCollapseItemPadded: {
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
    right: 20,
    paddingLeft: 20,
  },
}));

const NavCollapse = props => {
  const history = useHistory();
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const { name, icon, children = [], subMenu = false, type, alerts } = props;
  const isExpandable = children.length;
  const [open, setOpen] = React.useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);

  const shouldDisplayAlert = alerts => {
    if (!alerts || alerts.length === 0) {
      return false;
    }
    return alerts.some(alert => {
      return children.find(child => {
        if (!child.children) {
          return alert.path.some(path => path === child.link);
        } else {
          return alert.path === child.link || child.children.some(innerChild => alert.path.some(path => path === innerChild.link));
        }
      });
    });
  };

  useEffect(() => {
    setOpen(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result = shouldDisplayAlert(alerts);
    setDisplayAlert(result);
  }, [alerts]);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderIcon = useCallback(() => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: classes.iconRoot,
      });
    }
    return null;
  }, [icon, classes]);

  const navCollapseClass = () => {
    let result = {};
    if (subMenu && type !== 'collapse') {
      result = classes.navSubCollapseBtnInner;
    } else if (subMenu && type === 'collapse') {
      result = classes.navSubCollapseBtnInnerGold;
    } else {
      result = classes.navCollapseBtnInner;
    }
    return result;
  };

  const MenuCollapse = (
    <ListItem className={clsx(classes.navCollapseBtn, 'Cmt-navCollapseBtn')} button onClick={handleClick}>
      <span className={navCollapseClass()}>
        {/* Display the expand menu if the item has children */}
        {isExpandable && !open && <IconExpandMore className={classes.navArrow} />}
        {isExpandable && open && <IconExpandLess className={classes.navArrow} />}
        {/* Display an icon if any */}
        {renderIcon()}
        <span className={classes.navText}>{name}</span>
        {displayAlert && !open && <AlertIcon />}
      </span>
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      className={type === 'collapse' ? classes.navCollapseItemPadded : classes.navCollapseItem}
      in={open}
      timeout="auto">
      <List component="div" disablePadding>
        {children.map((item, index) => {
          switch (item.type) {
            case 'section':
              return <NavSection {...item} key={index} alerts={alerts} />;
            case 'collapse':
              return <NavCollapse {...item} key={index} subMenu={true} alerts={alerts} />;
            case 'item':
              return <NavMenuItem {...item} key={index} subMenu={true} alerts={alerts} />;
            case 'wallet':
              return <UserWallet key={index} />;
            case 'userInfo':
              return <UserInfo {...item} key={index} alerts={alerts} />;
            case 'earningsInfo':
              return <EarningsInfo key={index} />;
            case 'levelsInfo':
              return <LevelsInfo {...item} key={index} alerts={alerts} />;
            default:
              return null;
          }
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <div className={clsx(classes.navCollapse, `${open ? 'open' : ''}`)}>
      {MenuCollapse}
      {MenuItemChildren}
      <style>{`
        @keyframes bounce {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-5px);
          }
          100% {
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default NavCollapse;
