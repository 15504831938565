import React, { useEffect, useState, useContext } from 'react';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { fetchError, fetchStart, fetchSuccess } from '../../../../../redux/actions';
import { getSinglePackage } from 'db/functions';
import { getUsersInfos } from 'smart_contract/functions';
import PurchaseButtons from 'components/Common/PurchaseButtons/PurchaseButtons';
import { isPWD } from 'components/PackagesPage/functions';
import web3 from 'web3';
import BuyPwdModal from './BuyPwdModal/BuyPwdModal';
const imagesFolder = '/images/packages';
const defaultPaymentIcon = 'money_pay.png';
import Description from 'components/Common/Description';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  button: {
    background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
    textShadow: '2px 2px 1px #311b1b',
    color: 'white',
    marginBottom: 20,
    padding: '6px 16px 6px 16px',
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  textCursive: {
    fontStyle: 'italic',
  },
  referralDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
const iconStyles = {
  height: 55,
  width: 55,
  marginRight: 10,
};

const PageThree = ({ variant = 'default', referralID, setReferralID, user, userAddress }) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const classes = useStyles({ variant });
  const [basePackage, setBasePackage] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [token, setToken] = useState('busd');
  const [price, setPrice] = useState('0');
  const [purchaseEnabled, setPurchaseEnabled] = useState(true);
  const [discountsInfos, setDiscountsInfos] = useState({});
  const [paymentIcon, setPaymentIcon] = useState(defaultPaymentIcon);
  const [displayBUSDinPrice, setDisplayBUSDinPrice] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalLoaded, setModalLoaded] = useState(false);

  const { locale } = useContext(AppContext);

  const search = useLocation().search;
  const packageQuery = new URLSearchParams(search).get('packageQuery');
  const eventQuery = new URLSearchParams(search).get('eventQuery');
  const loadView = new URLSearchParams(search).get('loadView');
  const queryString = () => {
    let queryString = '';

    if (packageQuery) {
      queryString += `packageQuery=${packageQuery}&`;
    }

    if (eventQuery) {
      queryString += `eventQuery=${eventQuery}&`;
    }

    if (loadView) {
      queryString += `loadView=${loadView}&`;
    }

    return queryString;
  };

  useEffect(() => {
    const fetchAsyncData = async () => {
      dispatch(fetchStart());
      const request = await getUsersInfos();
      if (!request.error) {
        window.location.assign('/network');
      }
      const response = await getSinglePackage('1');
      if (response.error) {
        dispatch(fetchError(response.error));
        return;
      }
      const _package = response._package;

      const pwdOnly = _package.disableBUSD && !_package.disablePWD;
      if (pwdOnly) {
        setPaymentIcon('pwd_token.png');
      }

      setBasePackage(_package);
      setPrice(_package.price);
      setModalLoaded(true);

      dispatch(fetchSuccess());
    };
    fetchAsyncData();
    if (referralID) {
      setIsDisabled(true);
    }
  }, [dispatch]);

  return (
    <form className="form">
      {modalLoaded && <BuyPwdModal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen} />}
      {authUser && <Redirect to={`/home?${queryString()}`} />}
      {basePackage && (
        <Box mb={2}>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            {basePackage && basePackage.title[locale.locale]}
          </Typography>
          {basePackage && <Description description={basePackage.description[locale.locale]} />}
          <div className={classes.referralDiv}>
            <TextField
              disabled={isDisabled}
              label={<IntlMessages id="appModule.referral" />}
              fullWidth
              onChange={event => setReferralID(event.target.value)}
              value={referralID}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              onBlur={() => (referralID ? setIsDisabled(true) : null)}
            />
            <Button
              style={{ opacity: isDisabled ? 100 : 0, marginBottom: 40 }}
              onClick={() => setIsDisabled(false)}
              disabled={!isDisabled}>
              <IntlMessages id="appModule.incorrectReferral" />
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 50,
              color: 'black',
            }}>
            <img style={iconStyles} src={`${imagesFolder}/${paymentIcon}`} alt="" />
            {isPWD(token, discountsInfos) ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <h1>
                  ~{Number(web3.utils.fromWei(discountsInfos.discountedPricePWD)).toFixed(2)} <i>PWD</i>
                </h1>
                <h3>
                  ( {Number(web3.utils.fromWei(discountsInfos.discountedPriceBUSD)).toFixed(2)} <i>BUSD</i> )
                </h3>
              </div>
            ) : (
              <h1>
                {Number(web3.utils.fromWei(price)).toFixed(2)} <i>${displayBUSDinPrice ? 'BUSD' : null}</i>
              </h1>
            )}
          </div>
          <div>
            <Button className={classes.button} onClick={() => setModalIsOpen(true)}>
              <IntlMessages id={'component.buyPWD'} />
            </Button>
          </div>
          <PurchaseButtons
            purchaseEnabled={purchaseEnabled}
            purchasesCount={undefined}
            disableBUSD={basePackage ? basePackage.disableBUSD : true}
            disablePWD={basePackage ? basePackage.disablePWD : true}
            token={token}
            setToken={setToken}
            setDiscountsInfos={setDiscountsInfos}
            setPrice={setPrice}
            packagePrice={basePackage ? basePackage.price : undefined}
            packageID={'1'}
            userAddress={userAddress}
            price={price}
            couponKeyword={basePackage ? basePackage.couponKeyword : undefined}
            setCoupon={undefined}
            setTransactionHash={() => {}}
            setPID={() => {}}
            userEmail={user.email}
            setCouponSetting={() => {}}
            setCouponLoaded={() => {}}
            rebuyEnabled={true}
            setPurchaseEnabled={setPurchaseEnabled}
            setPurchaseConfirmationIsOpen={() => {}}
            referralID={referralID}
            redirectToDashboardIfSuccessful={true}
            overrideSettingToken={true}
            disableSelectTokenMessage={true}
            customTokenButtonMessages={'register'}
            setPaymentIcon={setPaymentIcon}
            setDisplayBUSDinPrice={setDisplayBUSDinPrice}
          />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ sm: 'center' }}
        justifyContent={{ sm: 'space-between' }}
        mb={3}
        mt={10}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          3/3
        </Typography>
      </Box>
    </form>
  );
};

export default PageThree;
