import React from 'react';
import { makeStyles } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
}));

const LastPool = ({ poolInfo }) => {
  const classes = useStyles();

  return (
    <div className={classes.marginBottom}>
      <h2>
        <IntlMessages id="component.lastPool" />
      </h2>
      <p>
        <IntlMessages id={'component.cycleID'} />: {poolInfo.globals.lastCompletedCycleID}
      </p>
      <div className={classes.marginTop}>
        <strong>
          <p>
            <IntlMessages id={'component.totalPool'} />
            {': '}
            {poolInfo.lastCycleStats.poolBalance.toFixed(2)} USDT
          </p>
          <p>
            <IntlMessages id={'component.participants'} />
            {': '}
            {poolInfo.lastCycleStats.numberParticipants}
          </p>
          <p>
            <IntlMessages id={'component.rewardUser'} />
            {': '}
            {poolInfo.lastCycleStats.perUserReward.toFixed(2)} USDT
          </p>
        </strong>
      </div>
      <div className={classes.marginTop}>
        <p>
          <IntlMessages id={'component.joined'} />
          {': '}
          {poolInfo.lastCycleStats.userJoined ? <IntlMessages id={'component.yes'} /> : <IntlMessages id={'component.no'} />}
        </p>
        <p>
          <IntlMessages id={'component.reward'} />
          {': '}
          {poolInfo.lastCycleStats.userReward.toFixed(2)} USDT
        </p>
      </div>
    </div>
  );
};

export default LastPool;
