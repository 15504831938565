import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import LoadButton from 'components/Common/LoadButton';
import JoinCriterias from './JoinCriterias';
import NextRoundCriterias from './NextRoundCriterias';
import { Done, Error } from '@material-ui/icons';
import CountdownTimer from 'components/Common/ExpiresWidget/CountdownTimer';
import CountdownWithSeconds from 'components/Common/Pools/CountdownWithSeconds';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 10,
  },
  joinedContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  joinButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
}));

const CurrentPool = ({ poolInfo, joinClaimConditionsInfo, activityPoolSettings, careerInfo, onJoin, joining }) => {
  const classes = useStyles();

  const [allCriteriaMet, setAllCriteriaMet] = useState(false);

  useEffect(() => {
    if (
      joinClaimConditionsInfo.noBanSatisfied &&
      joinClaimConditionsInfo.careerPointsSatisfied &&
      joinClaimConditionsInfo.invitedSatisfied &&
      joinClaimConditionsInfo.minRankSatisfied &&
      joinClaimConditionsInfo.promoterActiveSatisfied &&
      joinClaimConditionsInfo.zeroClaimableCanRejoin
    ) {
      setAllCriteriaMet(true);
    }
  }, [joinClaimConditionsInfo]);

  return (
    <div className={classes.marginBottom}>
      <h2>
        <IntlMessages id={'component.currentPool'} />
      </h2>
      <p>
        <IntlMessages id={'component.cycleID'} />: {poolInfo.globals.currentViewedCycleID}
      </p>
      <div className={classes.marginTop}>
        <CountdownWithSeconds
          heading={
            !poolInfo.currentCycleStats.userJoined ? (
              <IntlMessages id={'component.timeLeftToJoin'} />
            ) : (
              <IntlMessages id={'component.poolEndTime'} />
            )
          }
          targetTimestamp={poolInfo.globals.currentViewedEndTimestamp * 1000}
        />
        <strong>
          <h3>
            <IntlMessages id={'component.totalPool'} />
            {': '}
            {poolInfo.currentCycleStats.poolBalance.toFixed(2)} USDT
          </h3>
          <h3>
            <IntlMessages id={'component.participants'} />
            {': '}
            {poolInfo.currentCycleStats.numberParticipants}
          </h3>
          <h3>
            <IntlMessages id={'component.rewardUser'} />
            {': '}
            {poolInfo.currentCycleStats.perUserReward.toFixed(2)} USDT
          </h3>
        </strong>
      </div>
      <div className={classes.marginTop}>
        <p>
          <strong>
            {poolInfo.currentCycleStats.userJoined ? (
              <div className={classes.joinedContainer}>
                <p className={classes.marginRight}>
                  <IntlMessages id={'component.alreadyJoined'} />
                </p>
                <Done style={{ color: 'green' }} />
              </div>
            ) : (
              <div className={classes.joinedContainer}>
                <p className={classes.marginRight}>
                  <IntlMessages id={'component.notJoined'} />
                </p>
                <Error style={{ color: 'red' }} />
              </div>
            )}
          </strong>
        </p>
        <p>
          <IntlMessages id={'component.estimatedRewardIfJoined'} />
          {': '}
          {poolInfo.currentCycleStats.userReward.toFixed(2)} USDT
        </p>
      </div>
      <div className={classes.marginTop} />
      {!poolInfo.currentCycleStats.userJoined ? (
        <div className={classes.marginTop}>
          <JoinCriterias
            joinClaimConditionsInfo={joinClaimConditionsInfo}
            activityPoolSettings={activityPoolSettings}
            careerInfo={careerInfo}
          />
          <div className={classes.joinButtonContainer}>
            <LoadButton
              loading={joining}
              disabled={!allCriteriaMet || joining}
              onClick={onJoin}
              messageID={'button.join'}
              customStyle={{ width: '100%', height: 50 }}
            />
          </div>
        </div>
      ) : (
        <div className={classes.marginTop}>
          <NextRoundCriterias
            joinClaimConditionsInfo={joinClaimConditionsInfo}
            activityPoolSettings={activityPoolSettings}
          />
        </div>
      )}
    </div>
  );
};

export default CurrentPool;
