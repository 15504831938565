import { LOAD_PACKAGES } from '../../smart_contract/constants/ActionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case LOAD_PACKAGES: {
      return action.payload;
    }
    default:
      return state;
  }
};
