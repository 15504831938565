import { Moralis } from 'moralis';
import { getAddedPackagesUserInfo } from '../smart_contract/functions';

// Gests a single Package Obj with it's data from the Moralis DB
// Args: packageID
const getSinglePackage = async packageID => {
  try {
    const PackageInfo = Moralis.Object.extend('PackageInfo');
    const query = new Moralis.Query(PackageInfo);
    query.equalTo('packageID', packageID);
    const result = await query.first();
    const _package = {
      description: {
        en: result.get('description_en'),
        es: result.get('description_es'),
        it: result.get('description_it'),
      },
      title: {
        en: result.get('title_en'),
        es: result.get('title_es'),
        it: result.get('title_it'),
      },
      packageID: result.get('packageID'),
      enabled: result.get('enabled'),
      price: result.get('price'),
      duration: result.get('duration'),
      rebuyEnabled: result.get('rebuy_enabled'),
      hasPrerequisites: result.get('has_prerequisites'),
      availableUnits: result.get('available_units'),
      disableBUSD: result.get('disable_busd'),
      disablePWD: result.get('disable_pwd'),
      adminEmail: result.get('admin_email'),
      sendConfirmation: result.get('send_confirmation'),
      couponKeyword: result.get('coupon_keyword'),
      notifyAdmin: result.get('notify_admin'),
    };
    return { error: false, _package };
  } catch (error) {
    return { error: error.message };
  }
};

// Returns all Package Objects stored in the local storage
const getAllPackages = async category => {
  try {
    const PackageInfo = Moralis.Object.extend('PackageInfo');
    const query = new Moralis.Query(PackageInfo);
    query.equalTo();
    const count = await query.count();
    query.limit(count);
    let queryResult = await query.find();
    queryResult = JSON.stringify(queryResult);
    queryResult = JSON.parse(queryResult);
    if (category === 'other') {
      queryResult = queryResult.filter(item => item.categories.length === 0);
    } else if (category) {
      queryResult = queryResult.filter(item => item.categories.includes(category));
    }
    let packages = [];
    const { userID } = JSON.parse(localStorage.getItem('user'));

    let request = await getAddedPackagesUserInfo(userID, 0, queryResult.length);
    if (request.error) {
      return { error: request.error };
    }
    const { parsedAddedPackagesUserInfo } = request;
    queryResult.forEach(async (_package, i) => {
      const matchedParsedAddedPackagesUserInfo = parsedAddedPackagesUserInfo.find(addedPackagesUserInfo => {
        return addedPackagesUserInfo.packageID.toString() === _package.packageID.toString();
      });

      const languages = _package.languages;
      const textContent = [];

      languages.forEach(language => {
        textContent.push({
          language,
          title: _package[`title_${language}`],
          description: _package[`description_${language}`],
        });
      });
      
      packages.push({
        textContent,
        languages,
        packageID: _package.packageID,
        enabled: _package.enabled,
        price: _package.price,
        duration: _package.duration,
        rebuyEnabled: _package.rebuy_enabled,
        hasPrerequisites: _package.has_prerequisites,
        availableUnits: _package.available_units,
        displayWeight: _package.display_weight || 0,
        disablePWD: _package.disable_pwd,
        disableBUSD: _package.disable_busd,
        couponKeyword: _package.coupon_keyword,
        couponExpiration: _package.expiration_date,
        customIcon: _package.custom_icon,
        totalEarnedPack: matchedParsedAddedPackagesUserInfo ? matchedParsedAddedPackagesUserInfo.totalEarnedPack : null,
        purchasesCount: matchedParsedAddedPackagesUserInfo ? matchedParsedAddedPackagesUserInfo.purchasesCount : null,
        lastPID: matchedParsedAddedPackagesUserInfo ? matchedParsedAddedPackagesUserInfo.lastPID : null,
        lastTimestamp: matchedParsedAddedPackagesUserInfo ? matchedParsedAddedPackagesUserInfo.lastTimestamp : null,
      });
    });

    packages = packages.sort((a, b) => {
      if (a.displayWeight > b.displayWeight) {
        return -1;
      } else if (a.displayWeight < b.displayWeight) {
        return 1;
      } else {
        return 0;
      }
    });

    return { error: false, packages };
  } catch (error) {
    return { error: error.message };
  }
};

const getAllMarketingResources = async () => {
  try {
    const MarketingResource = Moralis.Object.extend('MarketingResource');
    const query = new Moralis.Query(MarketingResource);
    query.equalTo();
    const count = await query.count();
    query.limit(count);
    const queryResult = await query.find();
    let marketingResources = [];

    queryResult.forEach(resource => {
      marketingResources.push({
        title: resource.get('title'),
        description: resource.get('description'),
        url: resource.get('url'),
        base64: resource.get('base64'),
        filetype: resource.get('filetype'),
      });
    });

    return { error: false, marketingResources };
  } catch (error) {
    return { error: error.message };
  }
};

const getSingleUserInfo = async => {
  try {
    const user = Moralis.User.current();
    if (!user) {
      throw Error('No records found in Moralis DB, registration needed');
    }
    return { error: false, userInfo: user };
  } catch (error) {
    return { error: error.message };
  }
};

const getUsernames = async userIDs => {
  try {
    const result = await Moralis.Cloud.run('fetchUsernames', { userIDs });
    if (!result) {
      return {};
    }

    const usernames = {};
    result.forEach(user => {
      const userID = user.get('userID');
      const username = user.get('username');
      usernames[userID] = username;
    });
    return { error: false, usernames };
  } catch (error) {
    return { error: error.message };
  }
};

const getSubscriptionExpirations = async userIDs => {
  try {
    const result = await Moralis.Cloud.run('fetchSubscriptionExpirations', {
      userIDs,
    });
    if (!result) {
      return {};
    }

    const subscriptionExpirations = {};
    result.forEach(user => {
      const userID = user.get('userID');
      const subScriptionExpiration = user.get('subscription_expiration');
      subscriptionExpirations[userID] = subScriptionExpiration;
    });
    return { error: false, subscriptionExpirations };
  } catch (error) {
    return { error: error.message };
  }
};

const getDirectOrSpillover = async userIDs => {
  try {
    const result = await Moralis.Cloud.run('fetchDirectOrSpillover', {
      userIDs,
    });
    if (!result) {
      return {};
    }

    const directOrSpillover = result;

    return { error: false, directOrSpillover };
  } catch (error) {
    return { error: error.message };
  }
};

const getNetworkTree = async userID => {
  try {
    const UserNetworkInfo = Moralis.Object.extend('UserNetworkInfo');
    const query = new Moralis.Query(UserNetworkInfo);
    query.equalTo('userID', userID.toString());
    const networkUser = await query.first();
    const networkTree = {
      source: userID,
      downline: networkUser.get('downlineIDs'),
      nestedDownlines: {},
      invitedUsers: networkUser.get('invitedUsersIDs'),
      fullNetwork: [],
    };

    const downlineLength = networkTree.downline.length;
    let i = 0;

    while (i < downlineLength) {
      const userID = networkTree.downline[i];

      query.equalTo('userID', userID.toString());
      const networkUser = await query.first();
      const nestedDownline = networkUser.get('downlineIDs');

      networkTree.nestedDownlines[userID] = nestedDownline;

      i++;
    }

    networkTree.fullNetwork = await Moralis.Cloud.run('getFullNetwork', {
      userID,
    });

    return { error: false, networkTree };
  } catch (error) {
    return { error: error.message };
  }
};

const getFullNetwork = async userID => {
  return await Moralis.Cloud.run('getFullNetwork', { userID });
};

const addUserInfo = async userData => {
  // LOG RELATED CODE -- START
  const date = new Date();
  const id = date.getTime();
  const user = await Moralis.User.currentAsync();
  const userAddress = user.get('ethAddress');

  let message = 'Registering user: 001';
  await Moralis.Cloud.run('addLogMessage', { id, message, userAddress });
  // LOG RELATED CODE -- END
  const { email, username, acceptedDataPolicy, telegram, firstname, lastname, password, phone } = userData;
  // LOG RELATED CODE -- START
  let messageVariables = JSON.stringify({
    email,
    username,
    acceptedDataPolicy,
    telegram,
    firstname,
    lastname,
    phone,
  });
  message = `Registering user: 002: ${messageVariables}`;
  await Moralis.Cloud.run('addLogMessage', { id, message });
  // LOG RELATED CODE -- END
  try {
    const user = Moralis.User.current();
    // LOG RELATED CODE -- START
    message = `Registering user: 003: ${JSON.stringify(user)}`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END
    user.set('email', email.trim());
    user.set('username', username.trim());
    user.set('acceptedDataPolicy', acceptedDataPolicy);
    user.set('telegram', telegram.trim());
    user.set('firstname', firstname.trim());
    user.set('lastname', lastname.trim());
    user.set('phone', phone.trim());
    if (password) {
      user.set('password', password);
    }
    await user.save();
    // LOG RELATED CODE -- START
    message = `Registering user: 004`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END
    return { error: false };
  } catch (error) {
    // LOG RELATED CODE -- START
    message = `Registering user: 005 ${JSON.stringify(error)}`;
    await Moralis.Cloud.run('addLogMessage', { id, message });
    // LOG RELATED CODE -- END
    return { error: error.message };
  }
};

const editUserInfo = async userData => {
  try {
    const user = Moralis.User.current();
    for (const property in userData) {
      user.set(property, userData[property]);
      await user.save();
      return { error: false };
    }
  } catch (error) {
    return { error: error.message };
  }
};

const getAllEvents = async () => {
  try {
    let { eventsArray } = await Moralis.Cloud.run('getAllEvents');
    eventsArray = eventsArray.sort((a, b) => {
      if (a.displayWeight > b.displayWeight) {
        return -1;
      } else if (a.displayWeight < b.displayWeight) {
        return 1;
      } else {
        return 0;
      }
    });
    return { error: false, events: eventsArray };
  } catch (error) {
    return { error: error.message };
  }
};

const getTelegramLink = async (uniqueString, userID) => {
  try {
    let telegramLinkRequest = await Moralis.Cloud.run('getTelegramLink', {
      uniqueString,
      userID,
    });
    let telegramLink = telegramLinkRequest.telegramLink;
    let attempts = 1;
    while (telegramLink === undefined && attempts < 3) {
      telegramLinkRequest = await Moralis.Cloud.run('getTelegramLink', {
        uniqueString,
        userID,
      });
      telegramLink = telegramLinkRequest.telegramLink;
      attempts++;
    }
    if (!telegramLink && attempts === 3) {
      return { error: "Couldn't fetch telegram link" };
    }
    return { error: false, telegramLink };
  } catch (error) {
    return { error: error.message };
  }
};

const revokeTelegramLink = async (uniqueString, userID, telegramLink) => {
  try {
    await Moralis.Cloud.run('revokeTelegramLink', {
      uniqueString,
      userID,
      inviteLink: telegramLink,
    });
    return { error: false };
  } catch (error) {
    return { error: error.message };
  }
};

const registerUserToBuilderAllClass = async (uniqueString, email) => {
  try {
    const request = await Moralis.Cloud.run('registerUserToBuilderAllClass', {
      uniqueString,
      email,
    });
    if (request.error || request.error === undefined) {
      return { error: request.error || 'Could not register user to e-class' };
    } else {
      return {
        error: false,
        registeredToBuilderAll: request.registeredToBuilderAll,
      };
    }
  } catch (error) {
    return { error: error.message };
  }
};

const deactivateUserFromBuilderAllClass = async (uniqueString, email) => {
  try {
    const request = await Moralis.Cloud.run('deactivateUserFromBuilderAllClass', {
      uniqueString,
      email,
    });
    return { error: false };
  } catch (error) {
    return { error: error.message };
  }
};

const addUserToEventList = async (uniqueString, userAddress) => {
  try {
    await Moralis.Cloud.run('addUserToEventList', { uniqueString, userAddress });
    return { error: false };
  } catch (error) {
    return { error: error.message };
  }
};

const isEmailAvailable = async email => {
  try {
    const response = await Moralis.Cloud.run('isEmailAvailable', email);
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const isUsernameAvailable = async username => {
  try {
    const response = await Moralis.Cloud.run('isUsernameAvailable', username);
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const isTelegramAvailable = async telegram => {
  try {
    const response = await Moralis.Cloud.run('isTelegramAvailable', telegram);
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const isEthAddressAvailable = async ethAddress => {
  try {
    const response = await Moralis.Cloud.run('isEthAddressAvailable', ethAddress);
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const sendRegisterConfirmationEmailToUser = params => {
  try {
    Moralis.Cloud.run('sendRegisterConfirmationEmailToUser', params);
    return { error: false };
  } catch (error) {
    return { error: error.message };
  }
};

const sendAdminNotificationEmailOnEventRegistration = params => {
  try {
    Moralis.Cloud.run('sendAdminNotificationEmailOnEventRegistration', params);
    return { error: false };
  } catch (error) {
    return { error: error.message };
  }
};

const registerNetworkData = async tree => {
  try {
    const user = Moralis.User.current();
    for (const property in tree) {
      user.set(property, tree[property]);
      await user.save();
      return { error: false };
    }
  } catch (error) {
    return { error: error.message };
  }
};

const getPurchasePackageEvData = async (transactionHash, tries = 5) => {
  try {
    const response = await Moralis.Cloud.run('getPurchasePackageEvData', {
      transactionHash,
    });
    console.log(`Transactionhash found: ${transactionHash}`);
    return { error: false, response };
  } catch (error) {
    if (tries === 0) {
      return { error: error.message };
    }
    console.log(`No purchase package ev found with transactionhash: ${transactionHash}. Retrying in 10 seconds`);
    return new Promise(resolve => {
      setTimeout(async () => {
        const result = await getPurchasePackageEvData(transactionHash, tries - 1);
        resolve(result);
      }, 10000);
    });
  }
};

const getPurchasePackageEvByUserID = async userID => {
  try {
    const response = await Moralis.Cloud.run('getPurchasePackageEvByUserID', {
      userID,
    });
    let parsedPurchasePackageEv = JSON.stringify(response);
    parsedPurchasePackageEv = JSON.parse(parsedPurchasePackageEv);
    const sortedPurchasePackageEv = parsedPurchasePackageEv.sort((a, b) => {
      const dateA = Number(a.timestamp_days) + Number(a.timestamp_hour);
      const dateB = Number(b.timestamp_days) + Number(b.timestamp_hour);

      return dateB - dateA;
    });
    return { error: false, sortedPurchasePackageEv };
  } catch (error) {
    return { error: error.message };
  }
};

const getCustomTokenInfos = async tokenAddress => {
  try {
    const response = await Moralis.Cloud.run('getCustomTokenInfos', {
      tokenAddress,
    });
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const assignCoupon = async (keyword, userID) => {
  try {
    const response = await Moralis.Cloud.run('assignCoupon', {
      keyword,
      userID,
    });
    if (response.error) {
      return { error: response.error };
    }
    const coupon = {
      code: response.coupon.get('code'),
      expiration: response.coupon.get('expiration'),
      keyword: response.coupon.get('keyword'),
      url: response.coupon.get('url'),
      duration: response.coupon.get('duration'),
    };
    return { error: false, coupon };
  } catch (error) {
    return { error: error.message };
  }
};

const reverseReserveCoupon = async code => {
  try {
    await Moralis.Cloud.run('reverseReserveCoupon', {
      code,
    });
    return { error: false };
  } catch (error) {
    return { error: error.message };
  }
};

const sendOutCoupon = async (code, email, userID, purchaseID, packageID) => {
  try {
    const request = await Moralis.Cloud.run('sendOutCoupon', {
      code,
      email,
      userID,
      purchaseID,
      packageID,
    });
    if (!request.error) {
      return { error: false };
    } else {
      return { error: true, message: request.error.message };
    }
  } catch (error) {
    return { error: error.message };
  }
};

const getCouponSettingByKeyword = async keyword => {
  try {
    const couponSetting = await Moralis.Cloud.run('getCouponSettingByKeywordExternal', {
      keyword,
    });
    return { error: false, couponSetting };
  } catch (error) {
    return { error: error.message };
  }
};

const addCouponsFromJSON = async (
  couponsJSON,
  keyword,
  password,
  codeFieldName,
  urlOverwrite,
  urlFieldName,
  expirationOverwrite,
  expirationFieldName,
  durationOverwrite,
  durationFieldName,
) => {
  try {
    const response = await Moralis.Cloud.run('addCouponsFromJSON', {
      couponsJSON,
      keyword,
      password,
      codeFieldName,
      urlOverwrite,
      urlFieldName,
      expirationOverwrite,
      expirationFieldName,
      durationOverwrite,
      durationFieldName,
    });
    if (response.error) {
      return { error: response.error };
    }
    return { error: false };
  } catch (error) {
    return { error: error.message };
  }
};

const getSingleUserRegisterEv = async userID => {
  try {
    const { userRegisterEv } = await Moralis.Cloud.run('getSingleUserRegisterEv', { userID });
    return { error: false, userRegisterEv };
  } catch (error) {
    return { error: error.message };
  }
};

const addRankUpgradeTransaction = async (userAddress, transactionHash, rank) => {
  try {
    await Moralis.Cloud.run('addRankUpgradeTransaction', {
      userAddress,
      transactionHash,
      rank,
    });
    return { error: false };
  } catch (error) {
    return { error: error.message };
  }
};

const getRankUpgradeTransactionsByUserAddress = async userAddress => {
  try {
    const response = await Moralis.Cloud.run('getRankUpgradeTransactionsByUserAddress', { userAddress });
    const transactions = response.map(transaction => {
      return {
        createdAt: transaction.get('createdAt'),
        rank: transaction.get('rank'),
        transactionHash: transaction.get('transactionHash'),
        updatedAt: transaction.get('updatedAt'),
        userAddress: transaction.get('userAddress'),
      };
    });
    return { error: false, transactions };
  } catch (error) {
    return { error: error.message };
  }
};

const getActivityPoolSettings = async () => {
  try {
    const response = await Moralis.Cloud.run('getActivityPoolSettings');
    const activityPoolSettings = {
      careerPointsBurnAll: response.get('careerPointsBurnAll'),
      careerPointsThreshold: response.get('careerPointsThreshold'),
      claimPWDpercentage: response.get('claimPWDpercentage'),
      invitedBurnAll: response.get('invitedBurnAll'),
      invitedThreshold: response.get('invitedThreshold'),
      minJoinRank: response.get('minJoinRank'),
      minPWDeqUSDamount: response.get('minPWDeqUSDamount'),
      poolPeriodMonths: response.get('poolPeriodMonths'),
      warningExpirationMonth: response.get('warningExpirationMonth'),
    };
    return { error: false, activityPoolSettings };
  } catch (error) {
    return { error: error.message };
  }
};

const saveLocalePreferenceToDB = async locale => {
  try {
    await Moralis.Cloud.run('saveLocalePreference', { locale });
  } catch (error) {}
};

const getSponsorAndParentOfUser = async userID => {
  try {
    return await Moralis.Cloud.run('getSponsorAndParentOfUser', {
      userID: userID.toString(),
    });
  } catch (error) {
    console.log(error);
  }
};

const getAllUsersAsAdmin = async () => {
  try {
    return await Moralis.Cloud.run('getAllUsersAsAdmin');
  } catch (error) {}
};

const getAllUsersAsDirector = async () => {
  try {
    const response = await Moralis.Cloud.run('getAllUsersAsDirector');
    return response;
  } catch (error) {}
}

const getSalesListAsAdmin = async () => {
  try {
    return await Moralis.Cloud.run('getSalesListAsAdmin');
  } catch (error) {}
};

const getSalesListAsDirector = async () => {
  try {
    return await Moralis.Cloud.run('getSalesListAsDirector');
  } catch (error) {}
};

const getEarningsListAsAdmin = async () => {
  try {
    return await Moralis.Cloud.run('getEarningsListAsAdmin');
  } catch (error) {}
};

const refreshNftMetaData = async userID => {
  try {
    const response = await Moralis.Cloud.run('refreshNftMetaData', { userID });
    console.log({ openSeaApiResponse: response });
    return response;
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

const getChangelogData = async () => {
  try {
    const Changelog = Moralis.Object.extend('Changelog');
    const query = new Moralis.Query(Changelog);
    query.equalTo();
    query.descending('createdAt');
    const count = await query.count();
    query.limit(count);
    let queryResult = await query.find();
    queryResult = JSON.stringify(queryResult);
    return JSON.parse(queryResult);
  } catch (error) {
    console.log(error);
    return { error: true };
  }
}

export {
  getAllPackages,
  getSinglePackage,
  getAllMarketingResources,
  getSingleUserInfo,
  getUsernames,
  getSubscriptionExpirations,
  getDirectOrSpillover,
  getNetworkTree,
  getFullNetwork,
  addUserInfo,
  editUserInfo,
  getAllEvents,
  getTelegramLink,
  revokeTelegramLink,
  registerUserToBuilderAllClass,
  deactivateUserFromBuilderAllClass,
  addUserToEventList,
  isEmailAvailable,
  isUsernameAvailable,
  isTelegramAvailable,
  isEthAddressAvailable,
  sendRegisterConfirmationEmailToUser,
  sendAdminNotificationEmailOnEventRegistration,
  registerNetworkData,
  getPurchasePackageEvData,
  getPurchasePackageEvByUserID,
  getCustomTokenInfos,
  assignCoupon,
  reverseReserveCoupon,
  sendOutCoupon,
  getCouponSettingByKeyword,
  addCouponsFromJSON,
  getSingleUserRegisterEv,
  addRankUpgradeTransaction,
  getRankUpgradeTransactionsByUserAddress,
  getActivityPoolSettings,
  saveLocalePreferenceToDB,
  getSponsorAndParentOfUser,
  getAllUsersAsAdmin,
  getAllUsersAsDirector,
  getSalesListAsAdmin,
  getSalesListAsDirector,
  getEarningsListAsAdmin,
  refreshNftMetaData,
  getChangelogData,
};
