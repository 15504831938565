import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 16px 10px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  navHeader: {
    marginBottom: 5,
    position: 'relative',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 15,
    letterSpacing: 1.5,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
}));

const EarningsInfo = ({ openEarningsModal }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  let totalEarned;
  if (authUser && authUser.totalEarned !== undefined) {
    totalEarned = authUser.totalEarned / 10 ** 18;
    totalEarned = Number(totalEarned.toFixed(2));
  }

  return (
    <div className={classes.root} id={'earning'}>
      <div onClick={null} style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Typography className={classes.navHeader}>
            <IntlMessages id={'sidebar.totalEarnings'} />
          </Typography>
          {authUser && authUser.totalEarned !== undefined ? (
            <div style={{ display: 'flex', whiteSpace: 'nowrap', marginBottom: 10 }}>
              <Typography component="h4" variant="h4">
                {`${Number(totalEarned).toFixed(2)} USDT`}
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EarningsInfo;
