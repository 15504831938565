import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import VideoBackground from 'components/Common/VideoBackground';
import UsersInfo from 'components/Admin/UsersInfo';
import TotalEarnings from 'components/Admin/TotalEarnings';
import TotalSales from 'components/Admin/TotalSales';
import Logout from 'components/Admin/Logout';
import Changelog from 'components/Admin/Changelog';
import { AuhMethods } from 'services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import { checkIfAdminAccount } from 'services/auth/moralis/functions';

const AdminPage = () => {
  const dispatch = useDispatch();
  const [checkingIsAdmin, setCheckingIsAdmin] = useState(true);

  useEffect(() => {
    const asyncFunction = async () => {
      const isAdmin = await checkIfAdminAccount();
      if (!isAdmin) {
        dispatch(AuhMethods[CurrentAuthMethod].onLogout());
      }
      setCheckingIsAdmin(false);
    };
    asyncFunction();
  }, []);

  if (checkingIsAdmin) return <div />;

  return (
    <div>
      <VideoBackground videoSrc="/background.mp4" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 10,
          marginLeft: 10,
        }}>
        <Logout />
        <Changelog />
        <UsersInfo />
        <TotalEarnings />
        <TotalSales />
      </div>
    </div>
  );
};

export default AdminPage;
