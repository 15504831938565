import React from 'react';
import moment from 'moment';

const generateChartData = data => {
  const chartData = { xAxis: [], series: [] };
  if (!data || data.length < 1) {
    return chartData;
  }

  const dates = data.map(item => moment.unix(item.timestamp));
  const sortedDates = dates.sort((a, b) => a.diff(b));
  const earliestDate = sortedDates[0];
  const latestDate = sortedDates[sortedDates.length - 1];

  // Determine the difference in months and years
  const monthsDiff = latestDate.diff(earliestDate, 'months');

  let span;
  if (monthsDiff > 12) {
    // Data spans several years
    span = 'yearly';
  } else if (monthsDiff > 1) {
    // Data spans different months within a year
    span = 'monthly';
  } else {
    // Data is within the same month
    span = 'daily';
  }

  if (span === 'yearly') {
    let currentDate = moment(earliestDate).startOf('year');
    while (currentDate.isBefore(latestDate, 'year') || currentDate.format('YYYY') === latestDate.format('YYYY')) {
      const yearData = data.filter(item => moment.unix(item.timestamp).format('YYYY') === currentDate.format('YYYY'));
      const sumPriceForYear = yearData.reduce((acc, item) => acc + parseFloat(item.price) / 10 ** 18, 0).toFixed(2);

      chartData.xAxis.push(currentDate.format('YYYY'));
      chartData.series.push(Number(sumPriceForYear));

      currentDate.add(1, 'year');
    }
  }

  if (span === 'monthly') {
    let currentDate = moment(earliestDate).startOf('month');
    while (currentDate.isBefore(latestDate, 'month') || currentDate.format('YYYY-MM') === latestDate.format('YYYY-MM')) {
      const monthData = data.filter(item => moment.unix(item.timestamp).format('YYYY-MM') === currentDate.format('YYYY-MM'));
      const sumPriceForMonth = monthData.reduce((acc, item) => acc + parseFloat(item.price) / 10 ** 18, 0).toFixed(2);

      chartData.xAxis.push(currentDate.format('YYYY-MM'));
      chartData.series.push(Number(sumPriceForMonth));

      currentDate.add(1, 'month');
    }
  }

  if (span === 'daily') {
    const monthStart = moment(earliestDate).startOf('month');
    const monthEnd = moment(earliestDate).endOf('month');

    let currentDate = moment(monthStart);
    while (currentDate.isBefore(monthEnd) || currentDate.format('YYYY-MM-DD') === monthEnd.format('YYYY-MM-DD')) {
      const dayData = data.filter(
        item => moment.unix(item.timestamp).format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD'),
      );
      const sumPriceForDay = dayData.reduce((acc, item) => acc + parseFloat(item.price) / 10 ** 18, 0).toFixed(2);

      chartData.xAxis.push(currentDate.format('YYYY-MM-DD'));
      chartData.series.push(Number(sumPriceForDay));

      currentDate.add(1, 'day');
    }
  }

  return chartData;
};

export { generateChartData };
