import React from 'react';
import { getCareerInfo, getGlobalCareerSettings } from 'smart_contract/career/functions';
import {
  getPoolInfo as getPoolInfoActivity,
  getJoinClaimConditionsInfo as getJoinClaimConditionsInfoActivity,
} from 'smart_contract/activity_pool/functions';
import { getPoolInfo as getPoolInfoInfinity } from 'smart_contract/infinity_pool/functions';
import { getMoralisUser } from 'services/auth/moralis/functions';
import { getFullNetwork } from 'db/functions';
import { handleLoadNewDownlineUsers } from 'components/Functions/NetworkPage/functions';
import { LockSharp, WatchLaterSharp, CheckBoxSharp, MoneySharp, GroupAdd } from '@material-ui/icons';
import moment from 'moment';

const getCareerRankUpgradeAvailableAlert = async userAddress => {
  let response = await getCareerInfo(undefined, userAddress, false);
  if (response.error) {
    return;
  }
  const careerInfo = response.careerInfo;

  response = await getGlobalCareerSettings();
  if (response.error) {
    return;
  }
  const globalCareerSettings = response.globalCareerSettings;

  const upgradeAvailable = globalCareerSettings.ranks.some((rank, index) => {
    return (
      (index === 0 && !careerInfo.careerStarted) ||
      (careerInfo.currentActiveRank < index && careerInfo.careerPoints >= rank.threshold)
    );
  });
  if (upgradeAvailable) {
    return {
      message: 'Career rank upgrade available',
      name: 'careerRankUpgradeAvailable',
      path: ['/career'],
      icon: <LockSharp style={{ fontSize: 16, color: 'white' }} />,
    };
  }
};

const getActivityPoolTimeRoundEndingAlert = async userAddress => {
  let response = await getPoolInfoActivity(userAddress);
  if (response.error) {
    return;
  }
  const poolInfo = response.poolInfo;

  const currentTimestamp = moment().unix();
  const endTimestamp = poolInfo.globals.currentViewedEndTimestamp;

  const hoursLeft = moment.unix(endTimestamp).diff(moment.unix(currentTimestamp), 'hours', true);

  if (hoursLeft < 12) {
    // 12 hours limit to show the alert
    return {
      message: 'Activity pool round ending',
      name: 'activityPoolRoundEnding',
      path: ['/pools/activity'],
      icon: <WatchLaterSharp style={{ fontSize: 16, color: 'white' }} />,
    };
  }
};

const getActivityPoolJoinCriteriaMetButNotJoinedAlert = async userAddress => {
  let response = await getPoolInfoActivity(userAddress);
  if (response.error) {
    return;
  }
  const poolInfo = response.poolInfo;

  if (!poolInfo.currentCycleStats.userJoined) {
    response = await getJoinClaimConditionsInfoActivity(userAddress);
    if (response.error) {
      return;
    }
    const joinClaimConditionsInfo = response.joinClaimConditionsInfo;
    if (
      joinClaimConditionsInfo.noBanSatisfied &&
      joinClaimConditionsInfo.careerPointsSatisfied &&
      joinClaimConditionsInfo.invitedSatisfied &&
      joinClaimConditionsInfo.minRankSatisfied &&
      joinClaimConditionsInfo.promoterActiveSatisfied &&
      joinClaimConditionsInfo.zeroClaimableCanRejoin
    ) {
      return {
        message: 'Criteria to join activity pool met, but user has not joined',
        name: 'activityPoolJoinCriteriaMetButNotJoined',
        path: ['/pools/activity'],
        icon: <CheckBoxSharp style={{ fontSize: 16, color: 'white' }} />,
      };
    }
  }
};

const getActivityPoolClaimAvailableAlert = async userAddress => {
  let response = await getPoolInfoActivity(userAddress);
  if (response.error) {
    return;
  }
  const poolInfo = response.poolInfo;
  if (poolInfo.claimInfo.claimBalanceUSDT > 0) {
    return {
      message: 'User has an available claim in the activity pool',
      name: 'activityPoolClaimAvailable',
      path: ['/pools/activity'],
      icon: <MoneySharp style={{ fontSize: 16, color: 'white' }} />,
    };
  }
};

const getInfinityPoolClaimAvailableAlert = async userAddress => {
  let response = await getPoolInfoInfinity(userAddress);
  if (response.error) {
    return;
  }
  const poolInfo = response.poolInfo;
  if (poolInfo.claimInfo.userClaimableAmountUSDT > 0) {
    return {
      message: 'User has an available claim in the infinity pool',
      name: 'infinityPoolClaimAvailable',
      path: ['/pools/infinity'],
      icon: <MoneySharp style={{ fontSize: 16, color: 'white' }} />,
    };
  }
};

const getSubscriptionAboutToExpireAlert = async () => {
  let response = await getMoralisUser();
  if (response.error) {
    return;
  }
  const moralisUser = response.moralisUser;
  const expirationTimestamp = moralisUser.subscription_expiration;

  const expirationMoment = moment.unix(expirationTimestamp);
  const currentMoment = moment();
  const oneMonthLater = moment().add(1, 'months');

  if (expirationMoment.isBefore(currentMoment) || expirationMoment.isBefore(oneMonthLater)) {
    return {
      message: 'Subscription package is expiring in less than a month or has expired',
      name: 'subscriptionPackageAboutToExpire',
      path: ['/userInfo', '/levelsInfo'],
      icon: <WatchLaterSharp style={{ fontSize: 16, color: 'white' }} />,
    };
  }
};

const getNewDownlineUsersAlert = async () => {
  let response = await getMoralisUser();
  if (response.error) {
    return;
  }
  const moralisUser = response.moralisUser;
  const userID = moralisUser.userID;

  const fullNetwork = await getFullNetwork(userID);
  const newDownlineUsers = handleLoadNewDownlineUsers(fullNetwork, false);

  if (newDownlineUsers.length > 0) {
    return {
      message: 'New downline users available',
      name: 'NewDownlineUsers',
      path: ['/network'],
      icon: <GroupAdd style={{ fontSize: 16, color: 'white' }} />,
    };
  }
}

export {
  getCareerRankUpgradeAvailableAlert,
  getActivityPoolTimeRoundEndingAlert,
  getActivityPoolJoinCriteriaMetButNotJoinedAlert,
  getActivityPoolClaimAvailableAlert,
  getInfinityPoolClaimAvailableAlert,
  getSubscriptionAboutToExpireAlert,
  getNewDownlineUsersAlert,
};
