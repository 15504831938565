import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { LockOutlined, LockOpen } from '@material-ui/icons';
import LevelsModal from 'components/Sidebar/LevelsModal';
import SubscriptionInfo from '../SubscriptionInfo';
import './style.css';
import { addClassToModalOverlayAndContent } from './addClassToModalOverlayAndContent';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 16px 10px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  navHeader: {
    marginBottom: 5,
    position: 'relative',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 15,
    letterSpacing: 1.5,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
}));

const LevelsInfo = props => {
  const { link, alerts } = props;
  const { authUser } = useSelector(({ auth }) => auth);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  const [filteredAlerts, setFilteredAlerts] = useState(undefined);

  const filterAlerts = alerts => {
    const filteredAlerts = alerts.filter(alert => alert.path.some(path => path === link));
    return filteredAlerts.slice(0, 5); // Limit results to 5 so they all fit in the menu row
  };

  useEffect(() => {
    const result = filterAlerts(alerts);
    setFilteredAlerts(result);
  }, [alerts]);

  const [modalsLoaded, setModalsLoaded] = useState(false);
  const [hoveredLevels, setHoveredLevels] = useState(
    authUser && authUser.levelsUnlocked && Array(authUser.levelsUnlocked.length).fill(false),
  );
  const [clickedLevels, setClickedLevels] = useState(
    authUser && authUser.levelsUnlocked && Array(authUser.levelsUnlocked.length).fill(false),
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalLevel, setModalLevel] = useState(undefined);

  const openModal = level => {
    setModalIsOpen(false);
    setModalLevel(level);
    setTimeout(() => {
      setModalIsOpen(true);
    }, 200);
    setTimeout(() => {
      addClassToModalOverlayAndContent();
    }, 240);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    setModalsLoaded(true);
  }, []);

  const handleClick = i => {
    const newClickedLevels = [...clickedLevels];
    newClickedLevels[i] = true;
    setClickedLevels(newClickedLevels);

    openModal(i);

    setTimeout(() => {
      const resetClickedLevels = [...clickedLevels];
      resetClickedLevels[i] = false;
      setClickedLevels(resetClickedLevels);
    }, 300);
  };

  let levelsUnlocked;
  if (authUser && authUser.levelsUnlocked !== undefined) {
    levelsUnlocked = authUser.levelsUnlocked.map((unlocked, i) => {
      const isHovered = hoveredLevels[i];
      const isClicked = clickedLevels[i];

      const handleMouseOver = () => {
        const newHoveredLevels = [...hoveredLevels];
        newHoveredLevels[i] = true;
        setHoveredLevels(newHoveredLevels);
      };

      const handleMouseOut = () => {
        const newHoveredLevels = [...hoveredLevels];
        newHoveredLevels[i] = false;
        setHoveredLevels(newHoveredLevels);
      };

      const fontSize = isHovered ? 30 : 25;
      const cursor = isHovered ? 'pointer' : 'default';

      if (unlocked) {
        return (
          <div
            onClick={() => handleClick(i)}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            key={i}
            className={`level-item ${isClicked ? 'level-item-clicked' : ''}`}
            style={{
              cursor,
              display: 'flex',
              marginTop: 10,
              marginBottom: 10,
              alignItems: 'center',
            }}>
            <Typography component="h1" variant="h1" style={{ fontSize, whiteSpace: 'nowrap' }}>
              <IntlMessages id={'component.level'} /> {i + 1}
            </Typography>
            <LockOpen style={{ marginLeft: 10, fontSize }} />
            <p style={{ marginLeft: 10 }}>
              (<IntlMessages id={'component.UNLOCKED'} />)
            </p>
          </div>
        );
      } else {
        return (
          <div
            onClick={() => handleClick(i)}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            key={i}
            className={`level-item ${isClicked ? 'level-item-clicked' : ''}`}
            style={{
              cursor,
              display: 'flex',
              marginTop: 10,
              marginBottom: 10,
              alignItems: 'center',
            }}>
            <Typography component="h1" variant="h1" style={{ color: '#b5b5b5', fontSize, whiteSpace: 'nowrap' }}>
              <IntlMessages id={'component.level'} /> {i + 1}
            </Typography>
            <LockOutlined style={{ marginLeft: 10, fontSize, color: '#b5b5b5' }} />
            <p style={{ marginLeft: 10 }}>
              (<IntlMessages id={'component.LOCKED'} />)
            </p>
          </div>
        );
      }
    });
  }
  return (
    <div className={classes.root} id={'levels'}>
      {authUser && modalsLoaded && (
        <LevelsModal
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          level={modalLevel}
          levelsUnlocked={authUser.levelsUnlocked}
        />
      )}
      <div onClick={null} style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Typography className={classes.navHeader}>
            <IntlMessages id={'sidebar.levelsUnlocked'} />
          </Typography>
          {authUser && authUser.levelsUnlocked !== undefined ? levelsUnlocked : null}
          <SubscriptionInfo alerts={filteredAlerts} />
        </div>
      </div>
    </div>
  );
};

export default LevelsInfo;
