import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import UsersInfoTable from './UsersInfoTable';
import UserInfo from './UserInfo';
import { getAllUsersAsAdmin } from 'db/functions';

const useStyles = makeStyles(() => ({}));

const UsersInfo = () => {
  const classes = useStyles();
  const [view, setView] = useState('UsersInfoTable');
  const [selectedUserID, setSelectedUserID] = useState(undefined);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const asyncFunction = async () => {
      const users = await getAllUsersAsAdmin();
      setUsers(users);
    };
    asyncFunction();
  }, []);

  const goToUserView = userID => {
    setSelectedUserID(userID);
    setView('UserInfo');
  };

  const goToUsersInfoTable = () => {
    setSelectedUserID(undefined);
    setView('UsersInfoTable');
  };

  if (view === 'UsersInfoTable') return <UsersInfoTable users={users} goToUserView={goToUserView} />;
  if (view === 'UserInfo')
    return <UserInfo users={users} goToUsersInfoTable={goToUsersInfoTable} selectedUserID={selectedUserID} />;
};

export default UsersInfo;
