import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Notifications, NotificationsActive } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    width: 30,
    height: 30,
    borderRadius: 15,
    boxShadow: '0px 0px 20px red',
  },
  vibrate: {
    animation: '$vibrate 1s linear',
  },
  '@keyframes vibrate': {
    '0%, 100%': { transform: 'rotate(0)' },
    '25%': { transform: 'rotate(-15deg)' },
    '50%': { transform: 'rotate(15deg)' },
    '75%': { transform: 'rotate(-15deg)' },
  },
}));

const AlertIcon = () => {
  const classes = useStyles();
  const [active, setActive] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setActive(prev => !prev); // Toggle the active state every second
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <div className={`${classes.container} ${active ? classes.vibrate : ''}`}>
      {active ? <NotificationsActive /> : <Notifications />}
    </div>
  );
};

export default AlertIcon;
