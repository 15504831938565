import React from 'react';
import PackagesPage from 'components/PackagesPage';

const NftPage = () => {
  return (
    <div>
      <PackagesPage
        category={'nft'}
        messageID={'pages.products.nftPage'}
        descriptionID={'pages.products.nftPage.description'}
      />
    </div>
  );
};

export default NftPage;
