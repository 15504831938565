import React from 'react';
import Criteria from 'components/Common/Pools/Criteria';
import IntlMessages from '@jumbo/utils/IntlMessages';

const Criterias = ({ poolInfo }) => {
  const userBanned = !poolInfo.claimFlags.banned;

  const criteria = [
    {
      text: (
        <div>
          <IntlMessages id={'component.activePromoter'} />
        </div>
      ),
      conditionMet: poolInfo.claimFlags.promoterActive,
    },
    {
      text: (
        <div>
          <IntlMessages id={'component.equivalentUsdInPwd'} />
          {': '}${poolInfo.claimInfo.usdtEquivalentOfPdwOwned.toFixed(2)}/$
          {poolInfo.claimInfo.usdtThresholdToUnlockTheClaim.toFixed(2)}
        </div>
      ),
      conditionMet: poolInfo.claimFlags.pwdUsdEquivalentSatisfied,
    },
  ];

  return (
    <div>
      <h3>
        <IntlMessages id={'component.criteriaForClaiming'} />
      </h3>
      {!userBanned ? (
        criteria.map((criterion, index) => (
          <Criteria key={index} text={criterion.text} conditionMet={criterion.conditionMet} />
        ))
      ) : (
        <Criteria text={<IntlMessages id={'component.userNotApproved'} />} conditionMet={false} />
      )}
    </div>
  );
};

export default Criterias;
