import React from 'react';
import { Done, Error } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    marginLeft: 10,
  },
}));

const Criteria = ({ text, conditionMet }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p>{text}</p>
      <div className={classes.iconContainer}>
        {conditionMet ? <Done style={{ color: 'green' }} /> : <Error style={{ color: 'red' }} />}
      </div>
    </div>
  );
};

export default Criteria;
