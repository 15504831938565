import React from 'react';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Button from '@material-ui/core/Button';
import { downloadAsset } from './functions';
import { AttachFile, CloudDownload } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';

const Resource = ({ title, description, url, base64, filetype }) => {
  return (
    <CmtCard style={{ marginBottom: '20px' }}>
      <CmtCardHeader style={{ zIndex: 0 }} title={title ? `${title}.${filetype}` : 'No title'} icon={<AttachFile />} />
      <CmtCardContent style={{ zIndex: 0, paddingTop: 0 }}>
        <Typography>{description ? description : 'No description'}</Typography>
      </CmtCardContent>
      <CmtCardContent style={{ zIndex: 0, paddingTop: 0 }}>
        <Button
          style={{
            background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
            textShadow: '2px 2px 1px #311b1b',
          }}
          onClick={() => downloadAsset(url, base64, title, filetype)}
          variant="contained"
          color="primary">
          <IntlMessages id={'component.buttonGroup.download'} />
          <CloudDownload style={{ marginLeft: 10 }} />
        </Button>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Resource;
