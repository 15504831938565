import React, { useContext, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import RegistrationInfo from './RegistrationInfo';
import SubscriptionInfo from './SubscriptionInfo';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '10px 0 10px 10px',
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
    whiteSpace: 'nowrap',
  },
}));

const UserInfo = props => {
  const { link, alerts } = props;
  const { authUser } = useSelector(({ auth }) => auth);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  const [filteredAlerts, setFilteredAlerts] = useState(undefined);

  const filterAlerts = alerts => {
    const filteredAlerts = alerts.filter(alert => alert.path.some(path => path === link));
    return filteredAlerts.slice(0, 5); // Limit results to 5 so they all fit in the menu row
  };
  
  useEffect(() => {
    const result = filterAlerts(alerts);
    setFilteredAlerts(result);
  }, [alerts]);

  return (
    <div className={classes.wrapper}>
      <div>
        {authUser && authUser.firstname && authUser.lastname ? (
          <Typography className={classes.userSubTitle}>{`${authUser.firstname} ${authUser.lastname}`}</Typography>
        ) : null}
        <Typography className={classes.userSubTitle}>{authUser && authUser.email}</Typography>
      </div>
      <RegistrationInfo />
      <SubscriptionInfo alerts={filteredAlerts} />
    </div>
  );
};

export default UserInfo;
