import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchStart, fetchSuccess, fetchError } from 'redux/actions';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import web3 from 'web3';
import { isPWD, setDisplayPercentageDecimal } from './functions';
import { getParsedConfiguredDiscounts } from 'smart_contract/services';
import LevelDetailedInfoModal from './LevelDetailedInfoModal';
import QuantityDetailedInfoModal from './QuantityDetailedInfoModal';
import IconModal from 'components/Common/IconModal';
import calculateSideMarginsToCenterModal from 'components/Functions/calculateSideMarginsToCenterModal';
import PurchaseButtons from 'components/Common/PurchaseButtons/PurchaseButtons';
import Description from '../Common/Description';
import './styles.css'
const imagesFolder = '/images/packages';
const defaultIcon = 'package.svg';
const defaultPaymentIcon = 'money_pay.png';

const PackageDesktop = ({ props }) => {
  const {
    isDisabled,
    title,
    description,
    languages,
    duration,
    purchasesCount,
    availableUnits,
    disablePWD,
    disableBUSD,
    purchaseEnabled,
    packagePrice,
    packageID,
    userAddress,
    couponKeyword,
    setCoupon,
    setTransactionHash,
    setPID,
    userEmail,
    setCouponSetting,
    setCouponLoaded,
    setCopytradingLink,
    rebuyEnabled,
    setPurchaseConfirmationIsOpen,
    setPurchaseEnabled,
    expirationTimestamp,
    setExpirationTimestamp,
    ExpiresWidget,
    customIcon,
  } = props;
  const pwdOnly = disableBUSD && !disablePWD;

  const modalStyles = {
    content: {
      zIndex: 1,
      left: '8vw',
      marginTop: 120,
      marginRight: calculateSideMarginsToCenterModal(900).marginRight,
      marginLeft: calculateSideMarginsToCenterModal(900).marginLeft,
      width: 800,
      maxWidth: 800,
      overflowY: 'scroll',
    },
    body: {
      withData: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        textAlign: 'center',
      },
      noData: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#7a7b7d',
      },
      iconModal: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#7a7b7d',
      },
    },
    tableDiv: {
      width: '100%',
      marginTop: 20,
      table: {
        width: '100%',
        border: '2px solid black',
        borderCollapse: 'collapse',
        td: {
          height: 50,
          border: '2px solid black',
          borderCollapse: 'collapse',
          div: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
        textSmall: {
          fontSize: 10,
        },
        logo: {
          width: 25,
          height: 25,
          marginRight: 5,
        },
        currentIndex: {
          backgroundColor: '#f7cb78',
        },
      },
    },
  };

  const iconStyles = {
    height: 55,
    width: 55,
    marginRight: 10,
  };
  const dispatch = useDispatch();

  const [loadModals, setLoadModals] = useState(false);
  const [levelModalOpen, setLevelModalOpen] = useState(false);
  const [quantityModalOpen, setQuantityModalOpen] = useState(false);
  const [iconModalOpen, setIconModalOpen] = useState(false);
  const [configuredDiscounts, setConfiguredDiscounts] = useState({});
  const [cursor, setCursor] = useState('default');
  const [token, setToken] = useState('busd');
  const [price, setPrice] = useState(packagePrice);
  const [discountsInfos, setDiscountsInfos] = useState({});
  const [paymentIcon, setPaymentIcon] = useState(defaultPaymentIcon);
  const [displayBUSDinPrice, setDisplayBUSDinPrice] = useState(false);
  const [useBackgroundImage, setUseBackgroundImage] = useState(false);

  useEffect(() => {
    setLoadModals(true);
    if (pwdOnly) {
      setPaymentIcon('pwd_token.png');
    }
  }, []);

  const onModalOpen = async type => {
    dispatch(fetchStart());

    let setModalOpen;
    if (type === 'level') {
      setModalOpen = setLevelModalOpen;
    } else if (type === 'quantity') {
      setModalOpen = setQuantityModalOpen;
    }

    const request = await getParsedConfiguredDiscounts(packageID);
    if (request.error) {
      dispatch(fetchError(request.error));
      return;
    }
    setConfiguredDiscounts(request.parsedConfiguredDiscounts);
    dispatch(fetchSuccess());
    setModalOpen(true);
  };

  const onIconModalOpen = () => {
    setIconModalOpen(true);
  };

  const languageFlags = languages.map(language => {
    return (
      <img style={{ width: 30, margin: '0 5px', }} key={language} src={`/images/flag/packages/${language}.svg`} />
    )
  });

  return (
    <CmtCard
      id={`package_element_${packageID}`}
      style={{
        background: useBackgroundImage ? 'url(/images/gray_bg.jpg)' : null,
        backgroundSize: useBackgroundImage ? 'cover' : null,
        marginBottom: '20px',
        opacity: isDisabled ? '60%' : null,
      }}>
      {loadModals && customIcon && (
        <IconModal
          modalStyles={modalStyles}
          setIsOpen={setIconModalOpen}
          modalIsOpen={iconModalOpen}
          appElement={`#package_element_${packageID}`}
          icon={customIcon}
        />
      )}
      {loadModals && Object.keys(configuredDiscounts).length > 0 && (
        <div>
          <LevelDetailedInfoModal
            modalStyles={modalStyles}
            setIsOpen={setLevelModalOpen}
            modalIsOpen={levelModalOpen}
            packageID={packageID}
            configuredDiscounts={configuredDiscounts}
            currentIndex={discountsInfos.index}
          />
          <QuantityDetailedInfoModal
            modalStyles={modalStyles}
            setIsOpen={setQuantityModalOpen}
            modalIsOpen={quantityModalOpen}
            packageID={packageID}
            configuredDiscounts={configuredDiscounts}
          />
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', position: 'absolute', top: 20, right: 15 }}>
          {languageFlags}
        </div>
        <CmtCardHeader
          style={{ zIndex: 0 }}
          title={title ? title : 'No title'}
          subTitle={`ID: ${packageID}`}
          icon={<img src={customIcon ? customIcon : `${imagesFolder}/${defaultIcon}`} style={{ height: 90 }} alt="" />}
          onIconModalOpen={customIcon ? onIconModalOpen : undefined}
          setCursor={customIcon ? setCursor : undefined}
          cursor={customIcon ? cursor : 'default'}
        />
        {isPWD(token, discountsInfos) && (
          <CmtCardContent
            style={{
              paddingTop: 24,
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              zIndex: 0,
            }}>
            <img style={iconStyles} src={`${imagesFolder}/price_label.png`} alt="" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <h2>
                {Number(web3.utils.fromWei(discountsInfos.priceBUSD)).toFixed(2)} <i>$BUSD</i>
              </h2>
              <h3>
                ( ~{Number(web3.utils.fromWei(discountsInfos.pricePWD)).toFixed(2)} <i>PWD</i> )
              </h3>
            </div>
          </CmtCardContent>
        )}
      </div>
      <CmtCardContent style={{ zIndex: 0, paddingTop: 0 }}>
        <Description description={description} />
      </CmtCardContent>
      <CmtCardContent
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: isPWD(token, discountsInfos) ? -70 : null,
          whiteSpace: 'nowrap',
          zIndex: 0,
        }}>
        <div style={{ display: 'flex', alignItems: 'center', zIndex: 0 }}>
          <img style={iconStyles} src={`${imagesFolder}/${paymentIcon}`} alt="" />
          {isPWD(token, discountsInfos) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <h1>
                ~{Number(web3.utils.fromWei(discountsInfos.discountedPricePWD)).toFixed(2)} <i>PWD</i>
              </h1>
              <h3>
                ( {Number(web3.utils.fromWei(discountsInfos.discountedPriceBUSD)).toFixed(2)} <i>USDT</i> )
              </h3>
            </div>
          ) : (
            <h1>
              {Number(web3.utils.fromWei(price)).toFixed(2)} <i>${displayBUSDinPrice ? 'BUSD' : null}</i>
            </h1>
          )}
          {isPWD(token, discountsInfos) && (
            <div style={{ marginLeft: 30, display: 'flex', alignItems: 'center' }}>
              <img style={iconStyles} src={`${imagesFolder}/discounted_label.png`} alt="" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                <h2 style={{ fontWeight: 'bold' }}>TOTAL DISCOUNT</h2>
                <h1>
                  <span style={{ fontWeight: 'bold' }}>{setDisplayPercentageDecimal(discountsInfos.totalPercentage)}</span>
                  <span style={{ fontWeight: 'lighter' }}> %</span>
                </h1>
              </div>
            </div>
          )}
        </div>
        {isPWD(token, discountsInfos) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <div
              style={{ display: 'flex', alignItems: 'center', cursor }}
              onClick={() => onModalOpen('level')}
              onMouseOver={() => setCursor('pointer')}>
              <img style={iconStyles} src={`${imagesFolder}/levels_discount.png`} alt="" />
              <div>
                <h2>
                  <span style={{ fontWeight: 'lighter' }}>LEVEL</span>
                  <span style={{ fontWeight: 'bold' }}> {discountsInfos.level}</span>
                </h2>
                <h2>
                  <span style={{ fontWeight: 'bold' }}>{setDisplayPercentageDecimal(discountsInfos.discountLevel)}</span>
                  <span style={{ fontWeight: 'ligher' }}> %</span>
                </h2>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor,
                marginTop: 10,
              }}
              onClick={() => onModalOpen('quantity')}
              onMouseOver={() => setCursor('pointer')}>
              <img style={iconStyles} src={`${imagesFolder}/stack_discount.png`} alt="" />
              <h2>
                <span style={{ fontWeight: 'bold' }}>{setDisplayPercentageDecimal(discountsInfos.quantityDiscount)}</span>
                <span style={{ fontWeight: 'lighter' }}> %</span>
              </h2>
            </div>
          </div>
        )}
      </CmtCardContent>
      <CmtCardContent style={{ zIndex: 0, width: 'fit-content' }}>
        {availableUnits != -1 && <p style={{ marginBottom: 25 }}>Available: {availableUnits}</p>}
        <PurchaseButtons
          purchaseEnabled={purchaseEnabled}
          purchasesCount={purchasesCount}
          disableBUSD={disableBUSD}
          disablePWD={disablePWD}
          token={token}
          setToken={setToken}
          setDiscountsInfos={setDiscountsInfos}
          setPrice={setPrice}
          packagePrice={packagePrice}
          packageID={packageID}
          userAddress={userAddress}
          price={price}
          couponKeyword={couponKeyword}
          setCoupon={setCoupon}
          setTransactionHash={setTransactionHash}
          setPID={setPID}
          userEmail={userEmail}
          setCouponSetting={setCouponSetting}
          setCouponLoaded={setCouponLoaded}
          setCopytradingLink={setCopytradingLink}
          rebuyEnabled={rebuyEnabled}
          setPurchaseEnabled={setPurchaseEnabled}
          setPurchaseConfirmationIsOpen={setPurchaseConfirmationIsOpen}
          setExpirationTimestamp={setExpirationTimestamp}
          setPaymentIcon={setPaymentIcon}
          setDisplayBUSDinPrice={setDisplayBUSDinPrice}
          setUseBackgroundImage={setUseBackgroundImage}
        />
        {purchasesCount > 0 && duration > 0 && expirationTimestamp ? (
          <div style={{ marginTop: 25 }}>
            <ExpiresWidget expirationTimestamp={expirationTimestamp} />
          </div>
        ) : null}
      </CmtCardContent>
      {isPWD(token, discountsInfos) && (
        <CmtCardContent style={{ zIndex: 0, paddingTop: 0 }}>
          <p style={{ marginTop: 10 }}>
            Note: the conversion rate will be recalculated when pressing the BUY button. A slippage up to 1% can be applied
            due to the internal PWD/BUSD conversions.
          </p>
        </CmtCardContent>
      )}
    </CmtCard>
  );
};

export default PackageDesktop;
