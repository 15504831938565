import React, { useState } from 'react';
import { Typography, Paper, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import IntlMessages from '@jumbo/utils/IntlMessages';

const ReferralLink = ({ linkName, referralLink }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(referralLink);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 5000);
  };

  return (
    <Paper elevation={8} style={{ padding: '20px' }}>
      <p
        style={{
          fontSize: 17,
          marginBottom: 5,
          textAlign: 'left',
          fontWeight: 'bold',
        }}>
        {linkName}:
      </p>
      <Typography component="p" variant="h6" style={{ textAlign: 'left' }}>
        {referralLink}
      </Typography>
      {!linkCopied ? (
        <Button onClick={handleCopyLink}>{<IntlMessages id={'component.CopyLink'} />}</Button>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CheckIcon style={{ fill: 'green' }} />
          <Typography component="p" variant="h6">
            <IntlMessages id={'component.LinkCopied'} />
          </Typography>
        </div>
      )}
    </Paper>
  );
};

export default ReferralLink;
