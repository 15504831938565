import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Moralis } from 'moralis';
import IntlMessages from '../../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import LoadButton from 'components/Common/LoadButton';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../../../redux/actions';
import { editUserInfo } from '../../../../../db/functions';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  textCursive: {
    fontStyle: 'italic',
  },
  buttonStyle: {
    background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
    textShadow: '2px 2px 1px #311b1b',
  },
}));

const PageTwo = ({ variant = 'default', setCurrentPage, email }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const classes = useStyles({ variant });
  const dispatch = useDispatch();

  const checkIfEmailIsVerified = async errorMessage => {
    const emailIsVerified = await Moralis.Cloud.run('isEmailVerified');
    if (emailIsVerified) {
      setButtonLoading(false);
      setCurrentPage(3);
      dispatch(fetchSuccess());
    } else {
      setButtonLoading(false);
      dispatch(fetchError(errorMessage));
    }
  };
  setInterval(() => {
    checkIfEmailIsVerified('');
  }, 5000);

  return (
    <form>
      <Box mb={2}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id={'component.verifyYourEmail'} />
        </Typography>
        <Typography component="div" variant="subtitle1" className={classes.titleRoot}>
          <IntlMessages id={'component.pageTwo.string1'} />
        </Typography>
        <LoadButton
          loading={buttonLoading}
          disabled={buttonLoading}
          onClick={() => {
            dispatch(fetchStart());
            setButtonLoading(true);
            checkIfEmailIsVerified(<IntlMessages id={'error.emailNotVerified'} />);
          }}
          messageID={'appModule.checkVerification'}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ sm: 'center' }}
        justifyContent={{ sm: 'space-between' }}
        mb={3}
        mt={10}>
        <Box mb={{ xs: 2, sm: 0 }}>
          <Box display="flex">
            <Button className={classes.buttonStyle} onClick={() => setCurrentPage(1)} variant="contained" color="primary">
              <IntlMessages id="appModule.changeEmail" />
            </Button>
            <Button
              className={classes.buttonStyle}
              style={{ marginLeft: '20px' }}
              onClick={() => {
                editUserInfo({ email });
                dispatch(fetchSuccess(<IntlMessages id={'success.emailSent'} />));
              }}
              variant="contained"
              color="primary">
              <IntlMessages id="appModule.resendEmail" />
            </Button>
          </Box>
          <Typography component="div" variant="h1" className={classes.titleRoot} style={{ marginTop: 20 }}>
            2/3
          </Typography>
        </Box>
      </Box>
    </form>
  );
};

export default PageTwo;
