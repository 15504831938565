import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, IconButton, Popover } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ShareIcon from '@material-ui/icons/Share';
import Logo from '../Logo';
import ReferralLink from '../../../../../components/Header/ReferralLink';
import LanguageSwitcher from '../LanguageSwitcher';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    background: 'linear-gradient(to bottom left, #2958A0 0%, #020305 100%)',
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      backgroundColor: alpha(theme.palette.common.dark, 0.38),
      color: alpha(theme.palette.common.white, 0.7),
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
      '&:focus': {
        backgroundColor: alpha(theme.palette.common.dark, 0.58),
        color: alpha(theme.palette.common.white, 0.7),
      },
    },
  },
  langRoot: {
    borderLeft: `solid 1px ${alpha(theme.palette.common.dark, 0.15)}`,
    minHeight: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: 64,
    },
  },
  iconBtn: {
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  headerLogo: {
    width: 180,
  },
}));

const Header = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const referralLink = authUser ? `${process.env.REACT_APP_REFERRAL_LINK}${authUser.userID}` : null;
  const peopleCollectionReferralLink = authUser ? `https://people.powermade.io?ref=${authUser.userID}` : null;

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <Logo className={classes.headerLogo} ml={2} color="white" />
      <Box flex={1} />
      <LanguageSwitcher />
      <IconButton onClick={handlePopoverOpen}>
        <ShareIcon style={{ color: 'white' }} />
      </IconButton>
      {isOpen && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <ReferralLink linkName={<IntlMessages id={'component.mainReferralLink'} />} referralLink={referralLink} />
          <ReferralLink
            linkName={<IntlMessages id={'component.peopleReferralLink'} />}
            referralLink={peopleCollectionReferralLink}
          />
        </Popover>
      )}
    </Toolbar>
  );
};

export default Header;
