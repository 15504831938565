import React from 'react';
import LoadButton from '../LoadButton';

const SelectTokenButtons = ({
  disableBUSD,
  disablePWD,
  tokenLoading,
  token,
  onSetToken,
  disableSelectTokenMessage,
  customTokenButtonMessages,
}) => {
  const iconStyles = {
    height: 55,
    width: 55,
    marginRight: 10,
  };
  const imagesFolder = '/images/packages';

  const getTokenButtonMessage = token => {
    if (token === 'busd') {
      switch (customTokenButtonMessages) {
        case 'register':
          return 'component.buttonGroup.payWithBUSD';
          break;
        default:
          return 'component.buttonGroup.useBUSD';
      }
    }

    if (token === 'pwd') {
      switch (customTokenButtonMessages) {
        case 'register':
          return 'component.buttonGroup.payWithPWD';
          break;
        default:
          return 'component.buttonGroup.usePWD';
      }
    }
  };

  return (
    <div>
      {!disableSelectTokenMessage && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
            marginTop: -10,
          }}>
          <img style={iconStyles} src={`${imagesFolder}/wallet_icon.png`} alt="" />
          <p style={{ fontWeight: 'bold' }}>
            Select the token to use: pay with BUSD stablecoin, or use the Powermade PWD with discounts up to 20% depending on
            the amount you hold in your wallet. Click on the "Level" and "Quantity" icons to see the available discounts and
            requirements.
          </p>
        </div>
      )}
      <div style={{ display: 'flex' }}>
        {!disableBUSD && (
          <LoadButton
            customStyle={{ marginRight: 5 }}
            disabled={tokenLoading || disableBUSD}
            loading={tokenLoading && token === 'busd'}
            onClick={() => {
              if (!tokenLoading && !disableBUSD) {
                onSetToken('busd');
              }
            }}
            messageID={getTokenButtonMessage('busd')}
          />
        )}
        {!disablePWD && (
          <LoadButton
            customStyle={{
              background: !disablePWD && !tokenLoading ? 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)' : null,
              textShadow: !disablePWD && !tokenLoading ? '2px 2px 1px #311b1b' : null,
              color: disablePWD || tokenLoading ? '#aaaaaa' : null,
              backgrounColor: disablePWD || tokenLoading ? '#e0e0e0' : null,
            }}
            disabled={tokenLoading || disablePWD}
            loading={tokenLoading && token === 'pwd'}
            onClick={() => {
              if (!tokenLoading && !disablePWD) {
                onSetToken('pwd');
              }
            }}
            messageID={getTokenButtonMessage('pwd')}
          />
        )}
      </div>
    </div>
  );
};

export default SelectTokenButtons;
