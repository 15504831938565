import { SET_ALERTS, CLEAR_ALERT } from '../../@jumbo/constants/ActionTypes';

export const setAlerts = alerts => {
  return dispatch => {
    dispatch({
      type: SET_ALERTS,
      payload: alerts,
    });
  };
};

export const clearAlert = name => {
  return dispatch => {
    dispatch({
      type: CLEAR_ALERT,
      payload: name,
    });
  };
};
