import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import validator from 'validator';
import { fetchError, fetchStart, fetchSuccess } from '../../../../../redux/actions';
import IntlMessages from '../../../../utils/IntlMessages';
import { AuhMethods } from '../../../../../services/auth';
import ContentLoader from '../../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../../constants/AppConstants';
import AuthWrapper from '../AuthWrapper';
import { useLocation } from 'react-router-dom';
import Moralis from 'moralis';
import { getQueryParams, getQueryString } from 'routes/queryFunctions';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageThree from './PageThree';
import { useCookies } from 'react-cookie';
import {
  isEmailAvailable,
  isUsernameAvailable,
  isEthAddressAvailable,
  isTelegramAvailable,
  getSingleUserInfo,
} from 'db/functions';
const { ethereum } = window;

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  errorMessage: {
    backgroundColor: '#e86181',
    color: 'white',
    padding: 10,
    textAlign: 'center',
  },
}));

//variant = 'default', 'standard', 'bgColor'
const SignUp = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const classes = useStyles({ variant });
  const search = useLocation().search;
  const queryParams = getQueryParams(search);
  const dispatch = useDispatch();

  const [userAddress] = useState(ethereum.selectedAddress);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [telegram, setTelegram] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [acceptedDataPolicy, setAcceptedDataPolicy] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState('');
  const [cookies, setCookie] = useCookies(['ref']);

  Moralis.onWeb3Deactivated(() => {
    window.location.assign(`/signin${getQueryString(queryParams)}`);
  });
  Moralis.onChainChanged(() => {
    window.location.assign(`/signin${getQueryString(queryParams)}`);
  });
  Moralis.onAccountChanged(() => {
    window.location.assign(`/signin${getQueryString(queryParams)}`);
  });

  useEffect(() => {
    dispatch(fetchStart());
    const request = getSingleUserInfo();
    if (request.error) {
      dispatch(fetchError(request.error));
    }
    const user = request.userInfo;
    setUser(user);
    if (!user.get('username') || !user.get('email') || !user.get('acceptedDataPolicy')) {
      setCurrentPage(1);
      dispatch(fetchSuccess());
    } else if (!user.get('emailVerified')) {
      setEmail(user.get('email'));
      setUsername(user.get('username'));
      setTelegram(user.get('telegram'));
      setFirstname(user.get('firstname'));
      setLastname(user.get('lastname'));
      setPhone(user.get('phone'));
      setAcceptedDataPolicy(user.get('acceptedDataPolicy'));
      setCurrentPage(2);
      dispatch(fetchSuccess());
    } else {
      setCurrentPage(3);
    }
    const referralParam = new URLSearchParams(search).get('ref');
    if (referralParam && validator.isNumeric(referralParam)) {
      handleReferralIDChange(referralParam);
    }
  }, [dispatch, search]);

  const handleEmailChange = state => {
    setEmail(state);
  };

  const handleUsernameChange = state => {
    setUsername(state);
  };

  const handleTelgramChange = state => {
    setTelegram(state);
  };

  const handleFirstnameChange = state => {
    setFirstname(state);
  };

  const handleLastnameChange = state => {
    setLastname(state);
  };

  const handleConfirmPasswordChange = state => {
    setConfirmPassword(state);
  };

  const handlePasswordChange = state => {
    setPassword(state);
  };

  const handlePhoneChange = state => {
    setPhone(state);
  };

  const handleReferralIDChange = state => {
    if (validator.isNumeric(state) || state === '') {
      const seventyTwoHoursInSeconds = '259200';
      setCookie('ref', state, {
        path: '/signup',
        maxAge: seventyTwoHoursInSeconds,
      });
    }
  };

  const handleAcceptedDataPolicyChange = state => {
    setAcceptedDataPolicy(state);
  };

  const handlePageChange = async state => {
    setCurrentPage(state);
  };

  const handleError = state => {
    setError(state);
    setTimeout(() => {
      setError('');
    }, 3000);
  };

  const handleRegistration = async setButtonLoading => {
    setButtonLoading(true);
    const validateEthereumAddress = async () => {
      const isEthereumAddress = validator.isEthereumAddress(userAddress);
      if (!isEthereumAddress) {
        handleError('Wallet address is not valid');
        return false;
      }
      const ethAddressIsAvailable = await isEthAddressAvailable({
        ethAddress: userAddress,
      });
      if (!ethAddressIsAvailable.response) {
        handleError('Ethereum address is already used by someone else');
        return false;
      }
      return true;
    };
    const validEthereumAddress = await validateEthereumAddress();

    const validateEmail = async () => {
      const isEmail = validator.isEmail(email);
      if (!isEmail) {
        handleError('Please provide a valid email');
        return false;
      }
      const emailIsAvailable = await isEmailAvailable({ email });
      if (!emailIsAvailable.response) {
        handleError('Email is already used by someone else');
        return false;
      }
      return true;
    };
    const validEmail = await validateEmail();

    const validateUsername = async () => {
      const isAlphanumeric = validator.isAlphanumeric(username);
      if (!isAlphanumeric) {
        handleError('Username can only contain Alphabetic characters');
        return false;
      }
      const lengthApproved = validator.isLength(username, { min: 3, max: 15 });
      if (!lengthApproved) {
        handleError('Username needs to be between 1 - 15 characters long');
        return false;
      }
      const usernameIsAvailable = await isUsernameAvailable({ username });
      if (!usernameIsAvailable.response) {
        handleError('Username is already used by someone else');
        return false;
      }
      return true;
    };
    const validUsername = await validateUsername();

    const validateTelegram = async () => {
      if (telegram === '') {
        return true;
      }
      const isAlphanumeric = validator.isAlphanumeric(telegram);
      if (!isAlphanumeric) {
        handleError('TelegramID can only contain Alphanumeric characters');
        return false;
      }
      const lengthApproved = validator.isLength(telegram, { min: 5, max: 30 });
      if (!lengthApproved) {
        handleError('TelegramID needs to be between 5 - 30 characters long');
        return false;
      }
      const telegramIsAvailable = await isTelegramAvailable({ telegram });
      if (!telegramIsAvailable.response) {
        handleError('TelegramID is already used by someone else');
        return false;
      }
      return true;
    };
    const validTelegram = await validateTelegram();

    const validateFirstname = async () => {
      if (firstname === '') {
        return true;
      }
      const isAlpha = validator.isAlpha(firstname, ['en-US'], {
        ignore: " -'.äëïöüÿÄËÏÖÜŸáćéíńóśúýźÁĆÉÍŃÓŚÚÝŹőűŐŰàèìòùÀÈÌÒÙâêîôûÂÊÎÔÛãñõÃÑÕčďěǧňřšťžČĎĚǦŇŘŠŤŽđĐåůÅŮąęĄĘæÆøØçÇłŁßþżŻ",
      });
      if (!isAlpha) {
        handleError('Surname can only contain alphabetic characters');
        return false;
      }
      const lengthApproved = validator.isLength(firstname, { min: 1, max: 30 });
      if (!lengthApproved) {
        handleError('Surname needs to be between 5 - 30 characters long');
        return false;
      }
      return true;
    };
    const validFirstname = await validateFirstname();

    const validateLastname = async () => {
      if (lastname === '') {
        return true;
      }
      const isAlpha = validator.isAlpha(lastname, ['en-US'], {
        ignore: " -'.äëïöüÿÄËÏÖÜŸáćéíńóśúýźÁĆÉÍŃÓŚÚÝŹőűŐŰàèìòùÀÈÌÒÙâêîôûÂÊÎÔÛãñõÃÑÕčďěǧňřšťžČĎĚǦŇŘŠŤŽđĐåůÅŮąęĄĘæÆøØçÇłŁßþżŻ",
      });
      if (!isAlpha) {
        handleError('Lastname can only contain alphabetic characters');
        return false;
      }
      const lengthApproved = validator.isLength(lastname, { min: 1, max: 30 });
      if (!lengthApproved) {
        handleError('Lastname needs to be between 5 - 30 characters long');
        return false;
      }
      return true;
    };
    const validLastname = await validateLastname();

    const validatePassword = async () => {
      if (password === '') {
        handleError('Plesase select a password');
        return false;
      }
      const lengthApproved = validator.isLength(password, { min: 8, max: 50 });
      if (!lengthApproved) {
        handleError('Password needs to be at least 8 characters long');
        return false;
      }
      const confirmedPassword = password === confirmPassword;
      if (!confirmedPassword) {
        handleError('Your passwords do not match');
        return false;
      }
      return true;
    };
    const validPassword = await validatePassword();

    const validatePhone = async () => {
      if (phone === '') {
        return true;
      }
      const isMobilePhone = validator.isMobilePhone(phone);
      if (!isMobilePhone) {
        handleError('Phone needs to be a valid mobile phone number');
        return false;
      }
      return true;
    };
    const validPhone = await validatePhone();

    if (
      !validEthereumAddress ||
      !validEmail ||
      !validUsername ||
      !validTelegram ||
      !validFirstname ||
      !validLastname ||
      !validPassword ||
      !validPhone
    ) {
      setButtonLoading(false);
      return;
    }

    if (!acceptedDataPolicy) {
      handleError('You must accept the data policy to register');
      setButtonLoading(false);
      return;
    }
    const registrationData = {
      userAddress,
      email,
      username,
      telegram,
      firstname,
      lastname,
      password,
      phone,
      acceptedDataPolicy,
    };
    dispatch(AuhMethods[method].onRegister(registrationData));
    setCurrentPage(2);
  };

  return (
    <AuthWrapper variant={wrapperVariant} white={true}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/sign-up-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          {currentPage === 1 && <IntlMessages id="appModule.welcome" />}
        </Typography>
        <Typography component="div" variant="subtitle1" className={classes.titleRoot}>
          {currentPage === 1 && <IntlMessages id="appModule.registerYourInformation" />}
          {currentPage === 3 && <IntlMessages id="appModule.inOrderToAccessInfo" />}
        </Typography>
        {error && (
          <Typography component="div" variant="subtitle1" className={classes.errorMessage}>
            Error: {error}
          </Typography>
        )}
        {currentPage === 1 && (
          <PageOne
            userAddress={userAddress}
            email={email}
            setEmail={handleEmailChange}
            username={username}
            setUsername={handleUsernameChange}
            telegram={telegram}
            setTelegram={handleTelgramChange}
            fistname={firstname}
            setFirstname={handleFirstnameChange}
            lastname={lastname}
            setLastname={handleLastnameChange}
            password={password}
            setPassword={handlePasswordChange}
            confirmPassword={confirmPassword}
            setConfirmPassword={handleConfirmPasswordChange}
            phone={phone}
            setPhone={handlePhoneChange}
            setCurrentPage={handlePageChange}
            handleError={handleError}
            handleRegistration={handleRegistration}
            setAcceptedDataPolicy={handleAcceptedDataPolicyChange}
            acceptedDataPolicy={acceptedDataPolicy}
          />
        )}
        {currentPage === 2 && (
          <PageTwo
            referralID={cookies.ref}
            setReferralID={handleReferralIDChange}
            setCurrentPage={handlePageChange}
            handleError={handleError}
            handleRegistration={handleRegistration}
            email={email}
          />
        )}
        {currentPage === 3 && (
          <PageThree
            referralID={cookies.ref}
            setReferralID={handleReferralIDChange}
            setCurrentPage={handlePageChange}
            handleError={handleError}
            handleRegistration={handleRegistration}
            user={user}
            userAddress={userAddress}
          />
        )}
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;
