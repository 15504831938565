import {
  smartContractFunctionCall,
  smartContractFunctionCallGasLimit,
  parseAddedPackagesMainInfo,
  parseAddedPackagesUserInfo,
  parseGetAllPurchases,
  parseUnlockedStatus,
} from './services';
import web3 from 'web3';
const { ethereum } = window;

const getUsersInfos = async (userID = 0, userAddress, packageID = 0, invitedStartIndex = 0, invitedStopIndex = 999999) => {
  try {
    if (!userAddress) {
      userAddress = ethereum.selectedAddress;
    }
    const params = {
      tUserAddr: userAddress,
      tUserID: userID,
      packageID: packageID,
      invited_start_index: invitedStartIndex,
      invited_stop_index: invitedStopIndex,
    };

    const contractAdress = process.env.REACT_APP_POWERMADE_AFFILIATION_CONTRACT_ADDRESS;
    const usersInfos = await smartContractFunctionCall(contractAdress, 'getUsersInfos', 'PowerMadeAffiliationABI', params);
    return { error: false, usersInfos };
  } catch (error) {
    return { error: error.message };
  }
};

const getAllPurchases = async (userID, packageID, onlyLast = true, startIndex = 0, stopIndex = 999999) => {
  try {
    const params = {
      userID,
      packageID,
      only_last: onlyLast,
      start_index: startIndex,
      stop_index: stopIndex,
    };

    const contractAdress = process.env.REACT_APP_POWERMADE_AFFILIATION_CONTRACT_ADDRESS;
    const allPurchasesInfo = await smartContractFunctionCall(
      contractAdress,
      'getAllPurchases',
      'PowerMadeAffiliationABI',
      params,
    );
    const parsedPurchasesInfo = parseGetAllPurchases(allPurchasesInfo);
    return { error: false, parsedPurchasesInfo };
  } catch (error) {
    return { error: error.message };
  }
};

const approve = async (amount, tokenAddr, spender) => {
  try {
    if (tokenAddr === process.env.REACT_APP_PWD_TOKEN_ADDRESS) {
      const tenPercent = Number(amount) * 0.1;
      amount = Number(amount) + tenPercent;
      amount = web3.utils.toWei(amount.toString());
    } else if (tokenAddr === process.env.REACT_APP_BUSD_TOKEN_ADDRESS) {
      amount = web3.utils.toWei(amount);
    }
    const params = {
      spender,
      amount,
    };
    const approved = await smartContractFunctionCall(tokenAddr, `approve`, 'BEP20TokenABI', params, true);
    return { error: false, approved };
  } catch (error) {
    return { error: error.message };
  }
};

const allowance = async (tokenOwner, tokenAddr, spender) => {
  try {
    const params = {
      spender,
      owner: tokenOwner,
    };
    let allowance = await smartContractFunctionCall(tokenAddr, `allowance`, 'BEP20TokenABI', params);
    allowance = web3.utils.fromWei(allowance._hex);
    return { error: false, allowance };
  } catch (error) {
    return { error: error.message };
  }
};

const buyPackage = async (sponsorID, packageID) => {
  try {
    const contractAddress = process.env.REACT_APP_POWERMADE_AFFILIATION_CONTRACT_ADDRESS;

    let response;
    if (process.env.REACT_APP_BUSD_GAS_LIMIT.toLowerCase() === 'true') {
      const factor = process.env.REACT_APP_BUSD_GAS_LIMIT_FACTOR;
      const params = [sponsorID, packageID];
      response = await smartContractFunctionCallGasLimit(
        contractAddress,
        'BuyPackage(uint256,uint16)',
        'PowerMadeAffiliationABI',
        params,
        undefined,
        factor,
      );
    } else {
      const params = {
        sponsorID,
        packageID,
      };
      response = await smartContractFunctionCall(contractAddress, 'BuyPackage', 'PowerMadeAffiliationABI', params, true);
    }
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const buyPackagePWD = async (sponsorID, packageID) => {
  try {
    const contractAddress = process.env.REACT_APP_DISCOUNT_WRAPPER_STANDALONE_CONTRACT_ADDRESS;

    let response;
    if (process.env.REACT_APP_PWD_GAS_LIMIT.toLowerCase() === 'true') {
      const factor = process.env.REACT_APP_PWD_GAS_LIMIT_FACTOR;
      const params = [sponsorID, packageID];
      response = await smartContractFunctionCallGasLimit(
        contractAddress,
        'BuyPackagePWD(uint256,uint16)',
        'DiscountWrapperStandaloneABI',
        params,
        undefined,
        factor,
      );
    } else {
      const params = {
        sponsorID,
        packageID,
      };
      response = await smartContractFunctionCall(
        contractAddress,
        'BuyPackagePWD',
        'DiscountWrapperStandaloneABI',
        params,
        true,
      );
    }
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const getDiscountsInfos = async (userID, userAddress, packageID) => {
  try {
    const contractAddress = process.env.REACT_APP_DISCOUNT_WRAPPER_STANDALONE_CONTRACT_ADDRESS;

    const params = {
      userID,
      user_address: userAddress,
      packageID,
    };
    const response = await smartContractFunctionCall(
      contractAddress,
      'getDiscountsInfos',
      'DiscountWrapperStandaloneABI',
      params,
    );
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const getConfiguredDiscounts = async packageID => {
  try {
    const contractAddress = process.env.REACT_APP_DISCOUNT_WRAPPER_STANDALONE_CONTRACT_ADDRESS;

    const params = {
      packageID,
    };
    const response = await smartContractFunctionCall(
      contractAddress,
      'getConfiguredDiscounts',
      'DiscountWrapperStandaloneABI',
      params,
    );
    return { error: false, response };
  } catch (error) {
    return { error: error.message };
  }
};

const getAddedPackagesMainInfo = async (startIndex, stopIndex) => {
  try {
    const contractAddress = process.env.REACT_APP_POWERMADE_AFFILIATION_CONTRACT_ADDRESS;

    const params = {
      start_index: startIndex,
      stop_index: stopIndex,
    };

    const addedPackagesMainInfo = await smartContractFunctionCall(
      contractAddress,
      'getAddedPackagesMainInfo',
      'PowerMadeAffiliationABI',
      params,
    );
    const parsedAddedPackagesMainInfo = parseAddedPackagesMainInfo(addedPackagesMainInfo);
    return { error: false, parsedAddedPackagesMainInfo };
  } catch (error) {
    return { error: error.message };
  }
};

const getAddedPackagesUserInfo = async (userID, startIndex, stopIndex) => {
  try {
    const contractAddress = process.env.REACT_APP_POWERMADE_AFFILIATION_CONTRACT_ADDRESS;

    const params = {
      userID,
      start_index: startIndex,
      stop_index: stopIndex,
    };

    const addedPackagesUserInfo = await smartContractFunctionCall(
      contractAddress,
      'getAddedPackagesUserInfo',
      'PowerMadeAffiliationABI',
      params,
    );
    const parsedAddedPackagesUserInfo = parseAddedPackagesUserInfo(addedPackagesUserInfo);

    return { error: false, parsedAddedPackagesUserInfo };
  } catch (error) {
    return { error: error.message };
  }
};

const registerToEvent = async (packageID, eventUID, ticketHashed) => {
  try {
    const contractAddress = process.env.REACT_APP_POWERMADE_AFFILIATION_CONTRACT_ADDRESS_2;

    const params = {
      packageID,
      eventUID,
      ticket_hashed: ticketHashed,
    };

    const response = await smartContractFunctionCall(
      contractAddress,
      'registerToEvent',
      'PowermadeEventAclABI',
      params,
      true,
    );
    const transactionHash = response.transactionHash;

    return { error: false, transactionHash };
  } catch (error) {
    return { error: error.message };
  }
};

const getUnlockedStatus = async (userID = '0', userAddress = '0x0000000000000000000000000000000000000000', packageID) => {
  try {
    const contractAddress = process.env.REACT_APP_EXTERNAL_THRESHOLD_RULES_PROXY_CONTRACT_ADDRESS;

    const params = {
      userID,
      user_address: userAddress,
      packageID,
    };

    const response = await smartContractFunctionCall(
      contractAddress,
      'getUnlockedStatus',
      'ExternalThresholdRulesProxyABI',
      params,
    );
    const unlockedStatus = parseUnlockedStatus(response);
    return { error: false, unlockedStatus };
  } catch (error) {
    return { error: error.message };
  }
};

export {
  getUsersInfos,
  getAllPurchases,
  approve,
  allowance,
  buyPackage,
  buyPackagePWD,
  getDiscountsInfos,
  getConfiguredDiscounts,
  getAddedPackagesMainInfo,
  getAddedPackagesUserInfo,
  registerToEvent,
  getUnlockedStatus,
};
