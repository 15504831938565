import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import LoadButton from 'components/Common/LoadButton';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ClaimCriterias from './ClaimCriterias';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
  claimButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Claims = ({ poolInfo, joinClaimConditionsInfo, activityPoolSettings, onClaim, claiming }) => {
  const [allCriteriaMet, setAllCriteriaMet] = useState(false);

  useEffect(() => {
    if (
      joinClaimConditionsInfo.noBanSatisfied &&
      joinClaimConditionsInfo.promoterActiveSatisfied &&
      joinClaimConditionsInfo.pwdUsdSatisfied &&
      poolInfo.claimInfo.claimBalanceUSDT > 0
    ) {
      setAllCriteriaMet(true);
    }
  }, [joinClaimConditionsInfo, poolInfo]);

  const classes = useStyles();
  return (
    <div className={classes.marginBottom}>
      <h2>
        <IntlMessages id={'component.claims'} />
      </h2>
      {poolInfo.claimInfo.claimableCycleID > 0 && (
        <div className={classes.marginTop}>
          <p>
            <IntlMessages id={'component.claimableCycleID'} />: {poolInfo.claimInfo.claimableCycleID}
          </p>
        </div>
      )}
      <div className={classes.marginTop}>
        <ClaimCriterias joinClaimConditionsInfo={joinClaimConditionsInfo} activityPoolSettings={activityPoolSettings} />
      </div>
      <div className={classes.marginTop}>
        <div className={classes.marginBottom}>
          <p>
            <strong>
              <IntlMessages id={'component.claimBalance'} />: {poolInfo.claimInfo.claimBalanceUSDT.toFixed(2)} USDT (
              {poolInfo.claimInfo.pwdConversionPercentage}% in PWD)
            </strong>
          </p>
        </div>
        <div className={classes.claimButtonContainer}>
          <LoadButton
            customStyle={{ width: '100%', height: 50 }}
            loading={claiming}
            disabled={!allCriteriaMet || claiming}
            onClick={onClaim}
            messageID={'button.claim'}
          />
        </div>
        {poolInfo.claimInfo.claimExpirationTimestamp > 0 && (
          <p style={{ color: poolInfo.claimInfo.claimExpired ? 'red' : 'black' }}>
            {poolInfo.claimInfo.claimExpired ? (
              <IntlMessages id={'component.claimExpiredOn'} />
            ) : (
              <IntlMessages id={'component.claimExpiresOn'} />
            )}
            {': '}
            {moment(poolInfo.claimInfo.claimExpirationTimestamp * 1000).format('YYYY-MM-DD')}
          </p>
        )}
      </div>
      <div className={classes.marginTop}>
        {poolInfo.claimInfo.totalWithdrawn > 0 && (
          <p>
            <IntlMessages id={'component.totalWithdrawn'} />: {poolInfo.claimInfo.totalWithdrawn.toFixed(2)} USDT
          </p>
        )}
        {poolInfo.claimInfo.totalLost > 0 && (
          <p>
            <IntlMessages id={'component.totalLost'} />: {poolInfo.claimInfo.totalLost.toFixed(2)} USDT
          </p>
        )}
      </div>
    </div>
  );
};

export default Claims;
