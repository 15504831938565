import React, { useEffect, useRef, useState } from 'react';
import TelegramIcon from '@material-ui/icons/Telegram';
import CloseIcon from '@material-ui/icons/Close';

const TelegramFloat = () => {
  const closedTelegramFLoat = sessionStorage.getItem('closedTelegramFloat') ? true : false;

  const telegramIconRef = useRef(null);
  const [showFloat, setShowFloat] = useState(!closedTelegramFLoat);
  const [cursor, setCursor] = useState('default');

  const styles = {
    main: {
      display: 'flex',
      position: 'fixed',
      alignItems: 'center',
      right: 50,
      bottom: 30,
      color: 'white',
      fontWeight: 'bold',
      background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
      textShadow: '2px 2px 1px #311b1b',
      padding: '10px 25px 10px 25px',
      borderRadius: 15,
      cursor,
      zIndex: '1',
    },
    clickable: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      filter: 'drop-shadow(2px 2px 1px rgb(49,27,27))',
    },
    closeButton: {
      position: 'absolute',
      top: '0',
      right: '5px',
      cursor: 'pointer',
      padding: '4px',
      color: 'gray',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  useEffect(() => {
    const bounceInterval = setInterval(() => {
      if (telegramIconRef.current) {
        telegramIconRef.current.style.animation = 'bounce 1s forwards';

        setTimeout(() => {
          telegramIconRef.current.style.animation = '';
        }, 1000); // Adjust the duration of the bounce animation here (in milliseconds)
      }
    }, 5000); // Adjust the interval between bounces here (in milliseconds)

    return () => {
      clearInterval(bounceInterval);
    };
  }, []);

  const handleRedirect = () => {
    handleFloatClose();
    window.location.href = '/events?eventQuery=UID:0x623bE5881fC92f21597335Fe21D4E55C7004FB66';
  };

  const handleMouseOver = () => {
    setCursor('pointer');
  };

  const handleFloatClose = () => {
    sessionStorage.setItem('closedTelegramFloat', 'true');
    setShowFloat(false);
  };

  return (
    <div>
      {showFloat && (
        <div style={styles.main} onMouseOver={handleMouseOver}>
          <div onClick={handleRedirect} style={styles.clickable}>
            <p>Powermade PRO Community</p>
            <div ref={telegramIconRef} style={styles.icon}>
              <TelegramIcon style={{ animation: 'bounce 1s infinite' }} />
            </div>
          </div>
          <div style={styles.closeButton} onClick={handleFloatClose}>
            <CloseIcon style={{ fontSize: '14px' }} />
          </div>
        </div>
      )}
      <style>{`
        @keyframes bounce {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-5px);
          }
          100% {
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default TelegramFloat;
