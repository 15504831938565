import React from 'react';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, updateLoadUser } from '../../../redux/actions/Auth';
import { isMetamaskInstalled, moralisConnect, getMoralisUser, moralisLogOut } from './functions';
import { getAllPurchases, getUsersInfos, getUnlockedStatus } from 'smart_contract/functions';
import { addUserInfo, getSingleUserRegisterEv } from 'db/functions';
import Moralis from 'moralis';
import moment from 'moment';
import { getDefaultLng, setDefaultLng } from '@jumbo/components/contextProvider/AppContextProvider/defaultLng';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { checkIfAdminAccount } from './functions';

const MoralisAuth = {
  // ************ STANDARD FUNCTIONS THAT ARE NOT BEING USED CURRENTLY ************
  //
  // onForgotPassword: () => {
  //   return dispatch => {
  //     dispatch(fetchStart());

  //     setTimeout(() => {
  //       dispatch(setForgetPassMailSent(true));
  //       dispatch(fetchSuccess());
  //     }, 300);
  //   };
  // },
  // ************ STANDARD FUNCTIONS THAT THAT ARE NOT BEING USED CURRENTLY ************

  onRegister: ({ email, username, telegram, firstname, lastname, password, phone, acceptedDataPolicy }) => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(async () => {
        let user = {
          email,
          username,
          telegram,
          firstname,
          lastname,
          phone,
          acceptedDataPolicy,
          password,
          totalEarned: 0,
        };
        const addedToMoralis = await addUserInfo(user);
        if (addedToMoralis.error) {
          dispatch(fetchError(addedToMoralis.error));
          return;
        }
        dispatch(fetchSuccess());
      }, 300);
    };
  },

  onLogin: setLoading => {
    return dispatch => {
      // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
      try {
        const isInstalled = isMetamaskInstalled(); // Check if metamask is installed and active
        // If error message exists:
        if (isInstalled.error) {
          dispatch(fetchError(isInstalled.error)); // Display error message on screen
          setLoading(false);
          return; // Abort log in
        }
        dispatch(fetchStart());
        setTimeout(async () => {
          await moralisLogOut();
          const isMoralisConnected = await moralisConnect(); // Check if connection exists or connect
          // If error message exists:
          if (isMoralisConnected.error) {
            dispatch(fetchError(isMoralisConnected.error)); // Display error message on screen
            setLoading(false);
            return; // Abort log in
          }

          let request = await getMoralisUser();
          // If error message exists:
          if (request.error) {
            dispatch(fetchError(request.error)); // Display error message on screen
            setLoading(false);
            return; // Abort log in
          }
          const { moralisUser } = request;

          const isAdmin = await checkIfAdminAccount();
          const defaultLng = await getDefaultLng();
          setDefaultLng(defaultLng);
          let isUserRegistered;
          let userID;
          let totalEarned;
          let registrationTimestamp;
          let subscriptionInfo;
          let unlockedStatus;
          let subscriptionRenewedTimestamp;

          if (!isAdmin) {
            isUserRegistered = await getUsersInfos();
            if (isUserRegistered.error) {
              if (isUserRegistered.error === 'execution reverted: userID') {
                dispatch(fetchError('Registration needed before proceeding'));
                return;
              }
              dispatch(fetchError(isUserRegistered.error));
              setLoading(false);
              return;
            }

            const emailIsVerified = await Moralis.Cloud.run('isEmailVerified');
            if (!emailIsVerified || !moralisUser.email || !moralisUser.acceptedDataPolicy) {
              dispatch(fetchError('Registration needed before proceeding'));
              return;
            }
            const { usersInfos } = isUserRegistered;
            userID = parseInt(usersInfos.IDs[1]._hex, 16);
            totalEarned = parseInt(usersInfos.amounts[0]._hex, 16);

            const { userRegisterEv } = await getSingleUserRegisterEv(userID);
            if (typeof userRegisterEv.blockTimestamp === 'number') {
              registrationTimestamp = userRegisterEv.blockTimestamp;
            } else {
              registrationTimestamp = moment(userRegisterEv.blockTimestamp).unix();
            }

            const { parsedPurchasesInfo } = await getAllPurchases(
              userID,
              process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID,
              false,
            );
            const lastIndex = parsedPurchasesInfo.length - 1;
            subscriptionInfo = parsedPurchasesInfo[lastIndex];

            if (parsedPurchasesInfo.length > 1) {
              subscriptionRenewedTimestamp = parsedPurchasesInfo[lastIndex].timestamp;
            }

            unlockedStatus = await getUnlockedStatus(userID, undefined, process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID)
              .getUnlockedStatus;
          }

          // Set user object that will be stored in redux Auth
          const user = {
            userAddress: moralisUser.ethAddress,
            username: moralisUser.username,
            email: moralisUser.email,
            userID,
            totalEarned,
            registrationTimestamp,
            subscriptionRenewedTimestamp,
            subscriptionExpired: subscriptionInfo ? subscriptionInfo.expired : undefined,
            expirationTimestamp: subscriptionInfo ? subscriptionInfo.timestamp + subscriptionInfo.duration : undefined,
            levelsUnlocked: unlockedStatus ? unlockedStatus.levelsUnlocked : undefined,
            isAdmin,
          };
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('show-announcement', true);
          dispatch(fetchSuccess(<IntlMessages id={'success.authencticated'} />));
          dispatch(setAuthUser(user)); // Store logged in user in redux and confirm successful login process
        }, 300);
      } catch (error) {
        // Display any error message that occurs outside of planned scope
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        setTimeout(async () => {
          await moralisLogOut(); // Log out user from moralis back end
          dispatch(fetchSuccess());
          localStorage.removeItem('user');
          dispatch(setAuthUser(null));
        }, 300);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(async () => {
        let request = await getMoralisUser();
        if (request.error) {
          dispatch(setAuthUser(null));
          dispatch(fetchError());
          return;
        }
        const { moralisUser } = request;
        const isAdmin = await checkIfAdminAccount();

        let userID;
        let totalEarned;
        let registrationTimestamp;
        let subscriptionInfo;
        let subscriptionRenewedTimestamp;
        let unlockedStatus;
        if (!isAdmin) {
          request = await getUsersInfos(); // Checking if user is registered in SC
          if (request.error) {
            dispatch(setAuthUser(null));
            dispatch(fetchError(request.error));
            return;
          }
          if (request.usersInfos) {
            userID = parseInt(request.usersInfos.IDs[1]._hex, 16);
            totalEarned = parseInt(request.usersInfos.amounts[0]._hex, 16);
          } else {
            dispatch(setAuthUser(null));
            return;
          }
          if (!userID) {
            dispatch(setAuthUser(null));
            return;
          }
          if (!moralisUser.email || !moralisUser.username || !moralisUser.emailVerified || !moralisUser.acceptedDataPolicy) {
            dispatch(setAuthUser(null));
            dispatch(fetchError());
            return;
          }

          const { userRegisterEv } = await getSingleUserRegisterEv(userID);
          if (typeof userRegisterEv.blockTimestamp === 'number') {
            registrationTimestamp = userRegisterEv.blockTimestamp;
          } else {
            registrationTimestamp = moment(userRegisterEv.blockTimestamp).unix();
          }

          const { parsedPurchasesInfo } = await getAllPurchases(
            userID,
            process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID,
            false,
          );
          const lastIndex = parsedPurchasesInfo.length - 1;
          subscriptionInfo = parsedPurchasesInfo[lastIndex];

          if (parsedPurchasesInfo.length > 1) {
            subscriptionRenewedTimestamp = parsedPurchasesInfo[lastIndex].timestamp;
          }

          unlockedStatus = (await getUnlockedStatus(userID, undefined, process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID))
            .unlockedStatus;
        }

        const user = {
          userAddress: moralisUser.ethAddress,
          username: moralisUser.username,
          email: moralisUser.email,
          firstname: moralisUser.firstname || '',
          lastname: moralisUser.lastname || '',
          userID,
          totalEarned,
          registrationTimestamp,
          subscriptionRenewedTimestamp,
          subscriptionExpired: subscriptionInfo ? subscriptionInfo.expired : undefined,
          expirationTimestamp: subscriptionInfo ? subscriptionInfo.timestamp + subscriptionInfo.duration : undefined,
          levelsUnlocked: unlockedStatus ? unlockedStatus.levelsUnlocked : undefined,
        };
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('show-announcement', true);
        dispatch(fetchSuccess());
        dispatch(setAuthUser(user));
      }, 300);
    };
  },

  setAuthUser: user => {
    return dispatch => {
      setTimeout(async () => {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('show-announcement', true);
        dispatch(fetchSuccess());
        dispatch(setAuthUser(user));
      }, 300);
    };
  },
};

export default MoralisAuth;
