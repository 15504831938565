const handleLoadNewDownlineUsers = (fullNetwork, setLocalStorage) => {
  const storedNetworkUsers = JSON.parse(localStorage.getItem('storedNetworkUsers')) || [];
  const allNetworkUsers = fullNetwork.flat();
  if (storedNetworkUsers.length === 0) {
    localStorage.setItem('storedNetworkUsers', JSON.stringify(allNetworkUsers));
  }
  const newDownlineUsers = allNetworkUsers.filter(allUser => !storedNetworkUsers.some(newUser => newUser === allUser));
  if (setLocalStorage) {
    localStorage.setItem('storedNetworkUsers', JSON.stringify(allNetworkUsers));
  }

  return newDownlineUsers;
}

export { handleLoadNewDownlineUsers }
