import Web3 from 'web3';

const parseCareerInfo = careerInfo => {
  let metaData = {};
  if (careerInfo.metadata_json) {
    const parsedMetaData = JSON.parse(careerInfo.metadata_json);
    metaData = {
      ...parsedMetaData,
      attributes: {
        rank: parsedMetaData.attributes[0].value,
        customized: parsedMetaData.attributes[1].value,
        team: parsedMetaData.attributes[2].value,
      },
    };
  }
  return {
    banned: careerInfo.banned,
    careerPoints: Number(Web3.utils.fromWei(careerInfo.career_points._hex)) * 10 ** 18,
    careerStarted: careerInfo.career_started,
    currentActiveRank: careerInfo.current_active_rank,
    eligibleRank: careerInfo.eligible_rank,
    metaData,
    nextRank: careerInfo.next_rank,
    nextRankPointsLeft: Number(Web3.utils.fromWei(careerInfo.next_rank_points_left._hex)) * 10 ** 18,
    totalPaidPrizes: Number(Web3.utils.fromWei(careerInfo.total_paid_prizes._hex)) * 10 ** 18,
  };
};

const parseGlobalCareerSettings = globalCareerSettings => {
  const ranks = [];
  const rankNames = globalCareerSettings.rank_names;
  const rankPwdPercentages = globalCareerSettings.rank_PWD_percentage;
  const rankImageUris = globalCareerSettings.rank_image_uri;
  const rankLogoUris = globalCareerSettings.rank_logo_uri;
  const rankPrizes = globalCareerSettings.rank_prizes;
  const rankThresholds = globalCareerSettings.rank_thresholds;
  rankNames.forEach((rankName, i) => {
    ranks.push({
      name: rankName,
      pwdPercentage: rankPwdPercentages[i],
      imageURI: rankImageUris[i],
      logoURI: rankLogoUris[i],
      prize: Number(Web3.utils.fromWei(rankPrizes[i]._hex)) * 10 ** 18,
      threshold: Number(Web3.utils.fromWei(rankThresholds[i]._hex)) * 10 ** 18,
    });
  });

  return {
    checkUserActiveExternal: globalCareerSettings.checkUserActiveExternal,
    sbtDescription: globalCareerSettings.sbt_description,
    sbtTitle: globalCareerSettings.sbt_title,
    subscriptionPackageID: globalCareerSettings.subscriptionPackageID,
    ranks,
  };
};

export { parseCareerInfo, parseGlobalCareerSettings };
