import { Moralis } from 'moralis';
const { ethereum } = window;

// Function that will check if metamask is installed and active on the device
const isMetamaskInstalled = () => {
  try {
    // If object and attribute exists
    if (ethereum && ethereum.isMetaMask) {
      return { error: false }; // Return object indicating no errors have been encountered
    } else {
      //If error: return object containing error message
      return { error: 'Metamask needs to be installed or activated!' };
    }
  } catch (error) {
    //If any other errors are caught, return object containing error message
    return { error: error.message };
  }
};

// ********* FUNCTION NOT NEEDED IF moralisConnect IS USED *********
//
// const metamaskConnect = async () => {
//   try {
//     await ethereum.enable();
//     return { error: false };
//   } catch (error) {
//     return { error: error.message };
//   }
// };
//
// ********* FUNCTION NOT NEEDED IF moralisConnect IS USED *********

// ********* FUNCTION IS CURRENTLY NOT BEING USED *********
//
// const metamaskGetSelectedAddress = async () => {
//   try {
//     const address = await ethereum.selectedAddress;
//     if (!address) {
//       throw new Error('Error when fetching address');
//     }
//     return { error: false, address };
//   } catch (error) {
//     return { error: error.message };
//   }
// };
//
// ********* FUNCTION IS CURRENTLY NOT BEING USED *********

// Function that will connect the user of fail
const moralisConnect = async () => {
  try {
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });

    if (accounts.length === 0) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    }

    let connection = await Moralis.User.currentAsync(); // See if current connection exists
    // If no connection:
    if (!connection) {
      const { message } = await Moralis.Cloud.run('requestMessage', {
        address: ethereum.selectedAddress,
        chain: parseInt(process.env.REACT_APP_CHAIN_ID, 16),
        network: 'evm',
      });

      // Prompt user to sign in with metamask
      connection = await Moralis.authenticate({ signingMessage: message });
    }
    // If still no connection:
    if (!connection) {
      // Return object containing error message
      return { error: 'Could not connect to Moralis' };
    }
    // If no errors encountered; return object with no error and connection information
    return { error: false, connection };
  } catch (error) {
    // Catch any other unforseen error that can occur
    return { error: error.message };
  }
};

// Function that will fetch and parse user data from Moralis back end
const getMoralisUser = async () => {
  try {
    // Try fetching user data from current authenticated user
    let moralisUser = await Moralis.User.currentAsync();
    if (!moralisUser) {
      // If no user fetched
      return { error: 'Could not get user info' }; // Return object containing error message
    }
    // ------- Parse user info ------- //
    moralisUser = JSON.stringify(moralisUser);
    moralisUser = JSON.parse(moralisUser);
    // ------- Parse user info ------- //
    return { error: false, moralisUser }; // return object with no error and parsed user info
  } catch (error) {
    // If any unforseen errors are caught in the process; return object containing error message
    return { error: error.message };
  }
};

const checkIfAdminAccount = async () => {
  try {
    return await Moralis.Cloud.run('checkIfAdminAccount');
  } catch (error) {
    return { error: error.message };
  }
};

const checkIfDirectorAccount = async () => {
  try {
    return await Moralis.Cloud.run('checkIfDirectorAccount');
  } catch (error) {
    return { error: error.message };
  }
}

const checkIfCopytradingPackageBought = async userID => {
  try {
    return await Moralis.Cloud.run('checkIfCopytradingPackageBought', { userID });
  } catch (error) {
    return { error: error.message };
  }
}

// Function that will log out user from the Moralis back end
const moralisLogOut = () => Moralis.User.logOut();

export { 
  isMetamaskInstalled, 
  moralisConnect, 
  getMoralisUser, 
  checkIfAdminAccount, 
  checkIfDirectorAccount, 
  moralisLogOut,
  checkIfCopytradingPackageBought,
};
