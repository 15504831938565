import React from 'react';
import Box from '@material-ui/core/Box';
import { alpha, makeStyles } from '@material-ui/core/styles';
import VideoBackground from 'components/Common/VideoBackground';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  errorNumber: {
    color: 'white',
    fontWeight: 300,
    lineHeight: 1,
    marginBottom: 30,
    fontWeight: 'bold',
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
  },
  paragraph: {
    color: 'white',
    fontWeight: 100,
    lineHeight: 1,
    marginBottom: 30,
    fontWeight: 'bold',
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
    marginBottom: 0,
  },
  link: {
    color: 'white',
    fontWeight: 100,
    lineHeight: 1,
    marginBottom: 30,
    fontWeight: 'bold',
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
    marginTop: 0,
    '&:visited': {
      color: 'white',
    },
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .searchBtn': {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      boxSizing: 'border-box',
      padding: '5px 50px 5px 15px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

const PeopleByVanessiPage = () => {
  const link = 'https://nftrade.com/collection/powermade-people?search=&sort=created_desc&contractAddress=0xa475aee204c922b391009ad1d3c8b26113bb40b0&chainName=';
  const classes = useStyles();

  window.open(link, '_blank');

  return (
    <Box className={classes.root}>
      <VideoBackground videoSrc="/background.mp4" />
      <Box fontSize={{ xs: 60, sm: 60 }} className={classes.errorNumber}>
        Link opened in a new window
      </Box>
      <p className={classes.paragraph}>Window didn't open?</p>
      <a className={classes.link} href={link} target="_blank" rel="noreferrer">
        Click here
      </a>
    </Box>
  );
};

export default PeopleByVanessiPage;
