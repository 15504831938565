import React from 'react';
import { makeStyles } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Done, Error } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 10,
  },
  rankImagesContainer: {
    display: 'flex',
  },
  rankItemContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rankImage: {
    width: 30,
    marginRight: 5,
    marginLeft: 5,
    '&:hover': {
      width: 80,
    },
  },
  nParticipants: {
    fontSize: 15,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  balancesContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 10,
    border: 'solid black 2px',
    padding: 5,
    borderRadius: 5,
  },
}));

const PoolInfo = ({ poolInfo, globalCareerSettings }) => {
  const classes = useStyles();

  return (
    <div className={classes.marginBottom}>
      <h2>
        <IntlMessages id={'component.poolInfo'} />
      </h2>
      {poolInfo.globals.numberOfParticipants === 0 && (
        <div className={classes.marginTop}>
          <h3 className={classes.marginBottom}>
            <strong>
              <IntlMessages id={'component.poolWillStartWhen'} />
            </strong>
          </h3>
        </div>
      )}
      <div className={classes.balancesContainer}>
        <div className={classes.balanceContainer}>
          <h3>
            <strong>
              <IntlMessages id={'component.poolBalance'} />
            </strong>
          </h3>
          <h3>${poolInfo.globals.poolBalance.toFixed(2)}</h3>
        </div>
        <div className={classes.balanceContainer}>
          <h3>
            <strong>
              <IntlMessages id={'component.activeBalance'} />
            </strong>
          </h3>
          <h3>${poolInfo.globals.activeBalance.toFixed(2)}</h3>
        </div>
      </div>
      <p className={classes.marginTop}>
        <strong>Minimum active reward: ${poolInfo.globals.dividends.toFixed(2)}</strong>
      </p>
      <p>
        <strong>
          <IntlMessages id={'component.numberOfParticipants'} />: {poolInfo.globals.numberOfParticipants}
          {' - '}
          <IntlMessages id={'component.totalWeight'} />: {poolInfo.globals.totalPoolWeight / 10.0}
        </strong>
      </p>
      <div className={classes.rankImagesContainer}>
        {poolInfo.nParticipantsPerRank.map((nParticipants, i) => {
          const imageURI = globalCareerSettings.ranks[i].imageURI;
          if (nParticipants > 0) {
            return (
              <div className={classes.rankItemContainer} key={i}>
                <img className={classes.rankImage} src={`/images/career_ranks/rank_${i}.png`} />
                <p className={classes.nParticipants}>
                  <strong>{nParticipants}</strong>
                </p>
              </div>
            );
          }
        })}
      </div>
      <p>
        <strong>
          <IntlMessages id={'component.totalAmountClaimedByUsers'} />: $
          {poolInfo.globals.totalAmountClaimedByUsers.toFixed(2)}
        </strong>
      </p>

      <div className={classes.marginTop}>
        <h2>
          <IntlMessages id={'component.participantInfo'} />
        </h2>
        <p className={classes.marginTop}>
          {poolInfo.userStats.participating ? (
            <div className={classes.iconContainer}>
              <p className={classes.marginRight}>
                <strong>
                  <IntlMessages id={'component.joined'} />
                </strong>
              </p>
              <Done style={{ color: 'green' }} />
            </div>
          ) : (
            <div className={classes.iconContainer}>
              <p className={classes.marginRight}>
                <IntlMessages id={'component.notJoined'} />
              </p>
              <Error style={{ color: 'red' }} />
            </div>
          )}
        </p>
        {poolInfo.userStats.participating && (
          <div>
            <p>
              <strong>
                <IntlMessages id={'component.weight'} /> {poolInfo.userStats.weight / 10.0} /{' '}
                {poolInfo.globals.totalPoolWeight.toFixed(2) / 10.0}
                {'  '}
                (<IntlMessages id={'component.rankStoredInPoolContract'} /> {poolInfo.userStats.rankStoredInPoolContract})
              </strong>
            </p>
            <p>
              <IntlMessages id={'component.activeRewards'} />: ${poolInfo.userStats.realtimeDividends.toFixed(2)}
            </p>
            {poolInfo.userStats.rankStoredInPoolContract < 10 && (
              <p>
                <IntlMessages id={'component.activeRewardsIfIwasRank'} /> {poolInfo.userStats.rankStoredInPoolContract + 1}
                {': $'}
                {poolInfo.userStats.estimatedDividendsNextRank.toFixed(2)}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PoolInfo;
