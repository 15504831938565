import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import IntlMessages from '@jumbo/utils/IntlMessages';
import VideoBackground from 'components/Common/VideoBackground';
import ContentLoader from '@jumbo/components/ContentLoader';
import { fetchError, fetchStart, fetchSuccess, clearAlert } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { getPoolInfo, getJoinClaimConditionsInfo, join, claim } from 'smart_contract/activity_pool/functions';
import { getActivityPoolSettings } from 'db/functions';
import { getCareerInfo } from 'smart_contract/career/functions';
import LastPool from 'components/ActivityPoolPage/LastPool';
import CurrentPool from 'components/ActivityPoolPage/CurrentPool';
import Claims from 'components/ActivityPoolPage/Claims';
import CycleHistory from 'components/ActivityPoolPage/CycleHistory';
import TransactionConfirmationModal from 'components/Common/TransactionConfirmationModal';

const useStyles = makeStyles(theme => ({
  flexRowDesktop: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap-reverse',
    [theme.breakpoints.down(770)]: {
      display: 'none',
    },
  },
  flexRowMobile: {
    display: 'none',
    [theme.breakpoints.down(770)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  marginBottom: {
    marginBottom: 50,
  },
  divider: {
    display: 'flex',
    borderBottom: 'solid 2px #E0E0E0',
    width: '100%',
    marginBottom: 50,
  },
}));

const ActivityPoolPage = () => {
  const breadcrumbs = [
    { label: <IntlMessages id={'sidebar.main'} />, link: '/home' },
    {
      label: <IntlMessages id={'pages.careerPage.pools.activity'} />,
      isActive: true,
    },
  ];

  const classes = useStyles();
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const userAddress = authUser.userAddress;

  const [loadingFinished, setLoadingFinished] = useState(false);
  const [poolInfo, setPoolInfo] = useState({});
  const [joinClaimConditionsInfo, setJoinClaimConditionsInfo] = useState({});
  const [activityPoolSettings, setActivityPoolSettings] = useState({});
  const [careerInfo, setCareerInfo] = useState({});
  const [joining, setJoining] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [loadModals, setLoadModals] = useState(false);
  const [transactionConfirmationModalIsOpen, setTransactionConfirmationModalIsOpen] = useState(false);

  const loadData = async () => {
    dispatch(fetchStart());
    let response = await getPoolInfo(userAddress);
    if (response.error) {
      dispatch(fetchError(`Error - loadData: ${response.error}`));
      return;
    }
    const poolInfo = response.poolInfo;
    setPoolInfo(poolInfo);

    response = await getJoinClaimConditionsInfo(userAddress);
    if (response.error) {
      dispatch(fetchError(`Error - getJoinClaimConditionsInfo: ${response.error}`));
      return;
    }
    const joinClaimConditionsInfo = response.joinClaimConditionsInfo;
    setJoinClaimConditionsInfo(joinClaimConditionsInfo);

    response = await getActivityPoolSettings();
    if (response.error) {
      dispatch(fetchError(`Error - getActivityPoolSettings: ${response.error}`));
      return;
    }
    const activityPoolSettings = response.activityPoolSettings;
    setActivityPoolSettings(activityPoolSettings);

    response = await getCareerInfo(0, userAddress);
    if (response.error) {
      dispatch(fetchError(`Error - getCareerInfo: ${response.error}`));
      return;
    }
    const careerInfo = response.careerInfo;
    setCareerInfo(careerInfo);

    setLoadingFinished(true);
    dispatch(fetchSuccess());
  };

  useEffect(() => {
    const asyncFunction = async () => {
      await loadData();
      setLoadModals(true);
    };
    asyncFunction();
  }, []);

  const onJoin = async () => {
    setJoining(true);
    dispatch(fetchStart());
    const response = await join();
    if (response.error) {
      dispatch(fetchError(`Error - onJoin: ${response.error}`));
      setJoining(false);
      return;
    }
    const data = response.data;
    await loadData();

    dispatch(fetchSuccess(<IntlMessages id={'success.joinedPool'} />));
    dispatch(clearAlert('activityPoolJoinCriteriaMetButNotJoined'));
    setTransactionConfirmationModalIsOpen(true);
    setJoining(false);
  };

  const onClaim = async () => {
    setClaiming(true);
    dispatch(fetchStart());
    const response = await claim();
    if (response.error) {
      dispatch(fetchError(`Error - onClaim: ${response.error}`));
      setClaiming(false);
      return;
    }
    const data = response.data;
    await loadData();

    dispatch(clearAlert('activityPoolClaimAvailable'));
    dispatch(fetchSuccess(<IntlMessages id={'success.claimedReward'} />));
    setTransactionConfirmationModalIsOpen(true);
    setClaiming(false);
  };

  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer
        id="activity_pool_container"
        heading={<IntlMessages id={'pages.careerPage.pools.activity.description'} />}
        breadcrumbs={breadcrumbs}>
        {loadModals && (
          <div>
            <TransactionConfirmationModal
              setIsOpen={setTransactionConfirmationModalIsOpen}
              modalIsOpen={transactionConfirmationModalIsOpen}
            />
          </div>
        )}
        <CmtCard id={'career_main'}>
          <CmtCardContent
            style={{
              paddingTop: 24,
              alignItems: 'center',
              whiteSpace: 'nowrap',
              zIndex: 0,
            }}>
            {!loadingFinished && <ContentLoader />}
            {loadingFinished && (
              <div>
                <div className={classes.flexRowDesktop}>
                  <LastPool poolInfo={poolInfo} />
                  <CurrentPool
                    poolInfo={poolInfo}
                    joinClaimConditionsInfo={joinClaimConditionsInfo}
                    activityPoolSettings={activityPoolSettings}
                    careerInfo={careerInfo}
                    onJoin={onJoin}
                    joining={joining}
                  />
                  <Claims
                    poolInfo={poolInfo}
                    joinClaimConditionsInfo={joinClaimConditionsInfo}
                    activityPoolSettings={activityPoolSettings}
                    onClaim={onClaim}
                    claiming={claiming}
                  />
                </div>
                <div className={classes.flexRowMobile}>
                  <div className={classes.marginBottom}>
                    <CurrentPool
                      poolInfo={poolInfo}
                      joinClaimConditionsInfo={joinClaimConditionsInfo}
                      activityPoolSettings={activityPoolSettings}
                      careerInfo={careerInfo}
                      onJoin={onJoin}
                      joining={joining}
                    />
                  </div>
                  <div className={classes.divider} />
                  <div className={classes.marginBottom}>
                    <LastPool poolInfo={poolInfo} />
                  </div>
                  <div className={classes.divider} />
                  <Claims
                    poolInfo={poolInfo}
                    joinClaimConditionsInfo={joinClaimConditionsInfo}
                    activityPoolSettings={activityPoolSettings}
                    onClaim={onClaim}
                    claiming={claiming}
                  />
                </div>
                <div className={classes.divider} />
                <CycleHistory />
              </div>
            )}
          </CmtCardContent>
        </CmtCard>
      </PageContainer>
    </div>
  );
};

export default ActivityPoolPage;
