import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import TextField from '@material-ui/core/TextField';
import { Button, Grid, Divider } from '@material-ui/core';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import Papa from 'papaparse';
import moment from 'moment';
import { addCouponsFromJSON } from 'db/functions';
import LoadButton from 'components/Common/LoadButton';
import validator from 'validator';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.couponUploadPage'} />, isActive: true },
];

const styles = {
  button: {
    maxWidth: 300,
    marginTop: 10,
  },
  field: {
    maxWidth: 300,
  },
};

const CouponUploadPage = () => {
  const dispatch = useDispatch();

  const [keyword, setKeyword] = useState('');
  const [password, setPassword] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [codeFieldName, setCodeFieldName] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const [urlOverwrite, setUrlOverwrite] = useState(undefined);
  const [urlFieldName, setUrlFieldName] = useState('');
  const [expirationValue, setExpirationValue] = useState('');
  const [expirationOverwrite, setExpirationOverwrite] = useState(undefined);
  const [expirationFieldName, setExpirationFieldName] = useState('');
  const [durationValue, setDurationValue] = useState('');
  const [durationOverwrite, setDurationOverwrite] = useState(undefined);
  const [durationFieldName, setDurationFieldName] = useState('');
  const [couponData, setCouponData] = useState([]);
  const [uploadedCSV, setUploadedCSV] = useState(false);
  const [uploadingCoupons, setUploadingCoupons] = useState(false);

  const parseCSVtoJSON = async file => {
    const onParsed = results => {
      results.data.forEach((result, i) => {
        const expirationInitial = result.expiration;

        if (expirationInitial) {
          let expirationTimestamp;
          if (isNaN(Number(expirationInitial))) {
            //Expects value to be in ISO 8601 format
            const dateMomentObject = moment(expirationInitial);
            expirationTimestamp = dateMomentObject.valueOf();
          }

          if (!isNaN(Number(expirationInitial))) {
            //Expects value to be in UNIX timestamp format in seconds converting to milliseconds
            expirationTimestamp = Number(expirationInitial) * 1000;
          }

          if (expirationFieldName && expirationTimestamp) {
            result[expirationFieldName] = expirationTimestamp;
          }
        }
      });

      const filteredResultsDataArray = results.data.filter(resultsData => {
        let emptyRow = true;
        for (let key in resultsData) {
          if (resultsData[key].toString().trim() !== '' && resultsData[key][0] !== ',' && resultsData[key] !== undefined) {
            emptyRow = false;
          }
        }
        if (!emptyRow) {
          return resultsData;
        }
      });

      setCouponData(filteredResultsDataArray);
      setUploadedCSV(true);
    };

    const config = {
      header: true,
      complete: results => onParsed(results),
    };

    Papa.parse(file, config);
  };

  const uploadCoupons = async () => {
    setUploadingCoupons(true);
    dispatch(fetchStart());

    const couponValidation = validateCouponData();
    if (couponValidation.error) {
      dispatch(fetchError(couponValidation.errorMessage));
      setUploadingCoupons(false);
      return;
    }
    const response = await addCouponsFromJSON(
      JSON.stringify(couponData),
      keyword,
      password,
      codeFieldName,
      urlOverwrite,
      urlFieldName,
      expirationOverwrite,
      expirationFieldName,
      durationOverwrite,
      durationFieldName,
    );

    if (response.error) {
      dispatch(fetchError(response.error));
      setUploadingCoupons(false);
      return;
    }
    setUploadingCoupons(false);
    setCouponData([]);
    setUploadedCSV(false);
    dispatch(fetchSuccess(`Upload successful (${couponData.length} new coupons added)`));
  };

  const validateCouponData = () => {
    const validationResult = runColumnNamesValidation();
    if (validationResult.error) {
      return validationResult;
    }

    const couponDataLength = couponData.length;
    let errorFound = false;
    let result = { error: false };
    let i = 0;
    while (i < couponDataLength && !errorFound) {
      const coupon = couponData[i];

      const validationResult = runCouponValidation(coupon, i + 2);
      if (validationResult.error) {
        errorFound = true;
        result = validationResult;
      }

      i++;
    }
    return result;
  };

  const runColumnNamesValidation = () => {
    if (codeFieldName) {
      const customFieldNameExists = couponData.find(coupon => coupon[codeFieldName]);
      if (!customFieldNameExists) {
        const errorMessage = `No column named "${codeFieldName}" (Code) found in CSV`;
        return { error: true, errorMessage };
      }
    }

    if (urlFieldName) {
      const customFieldNameExists = couponData.find(coupon => coupon[urlFieldName]);
      if (!customFieldNameExists) {
        const errorMessage = `No column named "${urlFieldName}" (URL) found in CSV`;
        return { error: true, errorMessage };
      }
    }
    if (!urlOverwrite) {
      const indexOfUrlNotURL = couponData.find(coupon => coupon[urlFieldName] && !validator.isURL(coupon[urlFieldName]));
      if (indexOfUrlNotURL > -1) {
        const errorMessage = `Row ${indexOfUrlNotURL + 2} ${urlFieldName.toUpperCase()} has to be a valid URL`;
        return { error: true, errorMessage };
      }
    }

    if (durationFieldName) {
      const customFieldNameExists = couponData.find(coupon => coupon[durationFieldName]);
      if (!customFieldNameExists) {
        const errorMessage = `No column named "${durationFieldName}" (Duration) found in CSV`;
        return { error: true, errorMessage };
      }
    }

    if (expirationFieldName) {
      const customFieldNameExists = couponData.find(coupon => coupon[expirationFieldName]);
      if (!customFieldNameExists) {
        const errorMessage = `No column named "${expirationFieldName}" (Expiration) found in CSV`;
        return { error: true, errorMessage };
      }
    }

    return { error: false };
  };

  const runCouponValidation = (coupon, index) => {
    if (!coupon[codeFieldName]) {
      const errorMessage = `Row ${index}: ${codeFieldName.toUpperCase()} must contain a code`;
      return { error: true, errorMessage };
    }

    if (!durationOverwrite) {
      if (coupon[durationFieldName] && isNaN(Number(coupon[durationFieldName]))) {
        const errorMessage = `Row ${index}: ${durationFieldName.toUpperCase()} has to be a number`;
        return { error: true, errorMessage };
      }
    }

    if (!expirationOverwrite) {
      if (coupon[expirationFieldName] && isNaN(coupon[expirationFieldName])) {
        const errorMessage = `Row ${index}: ${expirationFieldName.toUpperCase()} has to be a UNIX timestamp or in ISO 8601 format`;
        return { error: true, errorMessage };
      }
    }

    return { error: false };
  };

  const setCode = value => {
    setCodeValue(value);
    if (value !== '') {
      setCodeFieldName(value);
    }

    if (value === '') {
      setCodeFieldName('');
    }
  };

  const setUrl = value => {
    setUrlValue(value);
    if (!validator.isURL(value)) {
      setUrlOverwrite(undefined);
      setUrlFieldName(value);
    }

    if (validator.isURL(value)) {
      setUrlFieldName('');
      setUrlOverwrite(value);
    }

    if (value === '') {
      setUrlFieldName('');
      setUrlOverwrite(undefined);
    }
  };

  const setDuration = value => {
    setDurationValue(value);
    if (value !== '' && isNaN(Number(value))) {
      setDurationOverwrite(undefined);
      setDurationFieldName(value);
    }

    if (value !== '' && !isNaN(Number(value))) {
      setDurationFieldName('');
      setDurationOverwrite(Number(value));
    }

    if (value === '') {
      setDurationFieldName('');
      setDurationOverwrite(undefined);
    }
  };

  const setExpiration = value => {
    setExpirationValue(value);
    if (value !== '' && isNaN(Number(value))) {
      setExpirationOverwrite(undefined);
      setExpirationFieldName(value);
    }

    if (value !== '' && !isNaN(Number(value))) {
      setExpirationFieldName('');
      //Expecting UNIX timestamp format in seconds converting it to milliseconds
      setExpirationOverwrite(Number(value) * 1000);
    }

    if (value === '') {
      setExpirationFieldName('');
      setExpirationOverwrite(undefined);
    }
  };

  return (
    <PageContainer
      id="upload_coupons_container"
      heading={<IntlMessages id="pages.couponUploadPage" />}
      breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}>
            <IntlMessages id="pages.couponUploadPage.description" />
          </div>
          <Divider />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              style={styles.field}
              label={<IntlMessages id="appModule.keyword" />}
              fullWidth
              margin="normal"
              variant="outlined"
              value={keyword}
              onChange={event => setKeyword(event.target.value)}
            />
            <TextField
              type="password"
              style={styles.field}
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              margin="normal"
              variant="outlined"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
            <TextField
              style={styles.field}
              label={<IntlMessages id="appModule.code" />}
              fullWidth
              margin="normal"
              variant="outlined"
              value={codeValue}
              onChange={event => setCode(event.target.value)}
            />
            <TextField
              style={styles.field}
              label={<IntlMessages id="appModule.url" />}
              fullWidth
              margin="normal"
              variant="outlined"
              value={urlValue}
              onChange={event => setUrl(event.target.value)}
            />
            <TextField
              style={styles.field}
              label={<IntlMessages id="appModule.duration" />}
              fullWidth
              margin="normal"
              variant="outlined"
              value={durationValue}
              onChange={event => setDuration(event.target.value)}
            />
            <TextField
              style={styles.field}
              label={<IntlMessages id="appModule.expiration" />}
              fullWidth
              margin="normal"
              variant="outlined"
              value={expirationValue}
              onChange={event => setExpiration(event.target.value)}
            />
            <Button variant="contained" component="label" style={styles.button}>
              Upload CSV
              <input
                hidden
                accept="text/csv"
                type="file"
                onChange={event => {
                  parseCSVtoJSON(event.target.files[0]);
                  event.target.value = null;
                }}
              />
            </Button>
            <LoadButton
              variant="contained"
              component="label"
              customStyle={styles.button}
              disabled={!uploadedCSV || !keyword || !password || !codeValue || uploadingCoupons}
              onClick={() => uploadCoupons()}
              loading={uploadingCoupons}
              messageID={'button.submit'}
            />
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default CouponUploadPage;
