import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import LoadButton from 'components/Common/LoadButton';
import Criterias from './Criterias';
import moment from 'moment';
import CountdownWithSecondsSimple from 'components/Common/Pools/CountdownWithSecondsSimple';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
  claimButtonContainer: {
    marginTop: 10,
    display: 'flex',
  },
}));

const ClaimInfo = ({ poolInfo, claiming, onClaim }) => {
  const classes = useStyles();
  const [countdownCompleted, setCountdownCompleted] = useState(poolInfo.claimFlags.cooldownExpired);

  useEffect(() => {
    setCountdownCompleted(poolInfo.claimFlags.cooldownExpired);
  }, [poolInfo]);

  const onCountdownComplete = () => {
    setCountdownCompleted(true);
  };

  return (
    <div className={classes.marginBottom}>
      <h2>
        <IntlMessages id={'component.claimRewards'} />
      </h2>
      <div className={classes.marginTop}>
        {poolInfo.userStats.participating && <Criterias poolInfo={poolInfo} />}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h3 className={classes.marginTop}>
            <strong>
              <IntlMessages id={'component.userClaimableAmount'} />: ${poolInfo.claimInfo.userClaimableAmountUSDT.toFixed(2)}
            </strong>
          </h3>
          {poolInfo.claimInfo.claimExpirationTimestamp > 0 && (
            <p>
              <IntlMessages id={'component.claimExpirationTimestamp'} />:{' '}
              {moment(poolInfo.claimInfo.claimExpirationTimestamp * 1000).format('YYYY-MM-DD HH:mm')}
            </p>
          )}
          <div className={classes.claimButtonContainer}>
            <LoadButton
              customStyle={{ width: '100%', height: 50 }}
              loading={claiming}
              disabled={!poolInfo.claimFlags.allConditions || claiming || poolInfo.claimInfo.userClaimableAmountUSDT === 0}
              onClick={onClaim}
              messageID={'button.claim'}
              customMessage={
                !countdownCompleted ? (
                  <CountdownWithSecondsSimple
                    targetTimestamp={poolInfo.claimInfo.nextClaimUnlockTimestamp * 1000}
                    onCountdownComplete={onCountdownComplete}
                  />
                ) : (
                  undefined
                )
              }
            />
          </div>
        </div>
        <div className={classes.marginTop}>
          {poolInfo.claimInfo.previousClaimTimestamp > 0 && (
            <p>
              <IntlMessages id={'component.previousClaimTimestamp'} />:{' '}
              {moment(poolInfo.claimInfo.previousClaimTimestamp * 1000).format('YYYY-MM-DD HH:mm')}
            </p>
          )}
          {poolInfo.claimInfo.totalReward > 0 && (
            <p className={classes.marginTop}>
              <strong>
                <IntlMessages id={'component.totalReward'} />: ${poolInfo.claimInfo.totalReward.toFixed(2)}
              </strong>
            </p>
          )}
          {poolInfo.claimInfo.totalLost > 0 && (
            <p>
              <IntlMessages id={'component.totalLost'} />: ${poolInfo.claimInfo.totalLost.toFixed(2)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimInfo;
