import React from 'react';
import { Typography } from '@material-ui/core';
import User from './User';
import Navigator from './Navigator';
import IntlMessages from '@jumbo/utils/IntlMessages';

const NetworkTreeMobile = ({
  networkUsers,
  handleGoBack,
  handleGoForward,
  handleGoMe,
  handleSearch,
  downlineUsers,
  invitedUsers,
  usernames,
  subscriptionExpirations,
}) => {
  return (
    <div style={{ top: 25 }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Navigator
          handleGoBack={handleGoBack}
          handleGoForward={handleGoForward}
          handleGoMe={handleGoMe}
          handleSearch={handleSearch}
        />
      </div>
      {usernames && subscriptionExpirations && (
        <div>
          <div>
            <Typography component="h1" variant="h1">
              Hierarchy
            </Typography>
            <div>
              <div style={{ maxWidth: 220 }}>
                <div style={{ marginTop: 40 }}>
                  <User
                    role={'User'}
                    username={networkUsers.source ? usernames[networkUsers.source] : undefined}
                    subscriptionExpiration={networkUsers.source ? subscriptionExpirations[networkUsers.source] : undefined}
                    arrowID={'source'}
                    userID={networkUsers.source}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {downlineUsers && downlineUsers.length > 0 && (
        <div style={{ marginTop: 40 }}>
          <Typography component="h1" variant="h1">
            <IntlMessages id={'component.downline'} />
          </Typography>
          <div style={{ display: 'flex', overflowX: 'scroll' }}>{downlineUsers}</div>
        </div>
      )}
      {invitedUsers && invitedUsers.length > 0 && (
        <div style={{ marginTop: 100 }}>
          <Typography component="h1" variant="h1">
            <IntlMessages id={'component.directUsersList'} />
          </Typography>
          <div style={{ display: 'flex', overflowX: 'scroll' }}>{invitedUsers}</div>
        </div>
      )}
    </div>
  );
};

export default NetworkTreeMobile;
