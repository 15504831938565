import { smartContractFunctionCall } from 'smart_contract/services';
import { parsePoolInfo } from './services';

const getPoolInfo = async userAddress => {
  try {
    const params = {
      user_address: userAddress,
    };

    const contractAdress = process.env.REACT_APP_INFINITY_POOL_CONTRACT_ADDRESS;
    const poolInfo = await smartContractFunctionCall(contractAdress, 'getPoolInfo', 'InfinityPoolABI', params);
    const parsedPoolInfo = parsePoolInfo(poolInfo);
    return { error: false, poolInfo: parsedPoolInfo };
  } catch (error) {
    return { error: error.message };
  }
};

const claim = async () => {
  try {
    const params = {};

    const contractAdress = process.env.REACT_APP_INFINITY_POOL_CONTRACT_ADDRESS;
    const data = await smartContractFunctionCall(contractAdress, 'claim', 'InfinityPoolABI', params, true);
    return { error: false, data };
  } catch (error) {
    return { error: error.message };
  }
};

export { getPoolInfo, claim };
