import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStart, fetchSuccess } from 'redux/actions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Divider,
  makeStyles,
} from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import moment from 'moment';
import VideoBackground from 'components/Common/VideoBackground';
import { getAllPackages, getPurchasePackageEvByUserID } from 'db/functions';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { getTitleAndDescription } from 'components/PackagesPage/functions';

const imagesFolder = '/images/packages';
const defaultIcon = 'package.svg';

const useStyles = makeStyles(() => ({
  tableCellHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  tableCellContent: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  img: {
    width: 50,
  },
}));

const PurchasesPage = () => {
  const breadcrumbs = [
    { label: <IntlMessages id={'sidebar.main'} />, link: '/home' },
    { label: <IntlMessages id={'pages.purchasesPage'} />, isActive: true },
  ];

  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const { locale } = useContext(AppContext);

  const [purchases, setPurchases] = useState([]);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const asyncOperation = async () => {
      dispatch(fetchStart());
      const userID = authUser.userID;
      const { packages } = await getAllPackages();
      const { sortedPurchasePackageEv } = await getPurchasePackageEvByUserID(userID);
      setPackages(packages);
      setPurchases(sortedPurchasePackageEv);
      dispatch(fetchSuccess());
    };
    asyncOperation();
  }, []);

  const purchasesRows = purchases.map((purchase, i) => {
    const packageID = purchase.packageID;
    const _package = packages.find(_package => _package.packageID === packageID);
    
    const { title } = getTitleAndDescription(_package.textContent, locale.locale);

    const customIcon = _package.customIcon;

    let iconSrc = `${imagesFolder}/${defaultIcon}`;
    if (customIcon) {
      iconSrc = customIcon;
    }

    const icon = <img className={classes.img} src={iconSrc} />;
    const bscScanLink = (
      <a target="_blank" href={`${process.env.REACT_APP_BSCSCAN_LINK}${purchase.transactionHash}`} rel="noreferrer">
        <IntlMessages id={'component.link'} />
      </a>
    );

    const blockTimestampISO = purchase.blockTimestamp.iso;
    const blockTimestamp = moment(blockTimestampISO).format('YYYY-MM-DD');

    return (
      <TableRow key={i}>
        <TableCell>{icon}</TableCell>
        <TableCell className={classes.tableCellContent}>{purchase.PID}</TableCell>
        <TableCell className={classes.tableCellContent}>{purchase.packageID}</TableCell>
        <TableCell className={classes.tableCellContent}>{title}</TableCell>
        <TableCell className={classes.tableCellContent}>{bscScanLink}</TableCell>
        <TableCell className={classes.tableCellContent}>{blockTimestamp}</TableCell>
      </TableRow>
    );
  });

  return (
    <div style={{ color: 'white', margin: '20px 30px 20px 30px' }}>
      <VideoBackground videoSrc="/background.mp4" />
      <PageContainer
        id="purchases_container"
        heading={<IntlMessages id={'pages.purchasesPage'} />}
        breadcrumbs={breadcrumbs}>
        <GridContainer>
          <Grid item xs={12}>
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}>
              <IntlMessages id={'pages.purchasesPage.description'} />
            </div>
            <Divider />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellHeader} />
                    <TableCell className={classes.tableCellHeader}>
                      <IntlMessages id={'component.purchaseID'} />
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>
                      <IntlMessages id={'component.packageID'} />
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>
                      <IntlMessages id={'component.title'} />
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>
                      <IntlMessages id={'component.linkBscScan'} />
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>
                      <IntlMessages id={'component.dateOfPurchase'} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{purchasesRows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </GridContainer>
      </PageContainer>
    </div>
  );
};

export default PurchasesPage;
