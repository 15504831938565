import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';

const useStyles = makeStyles(theme => ({
  userAddress: {
    fontSize: 10,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
  },
}));

const UserWallet = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  return (
    <Typography className={classes.userAddress} component="p">
      {authUser && authUser.userAddress ? `${authUser.userAddress}` : null}
    </Typography>
  );
};

export default UserWallet;
