import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import CmtCard from '@coremat/CmtCard';
import { getEarningsListAsAdmin } from 'db/functions';
import moment from 'moment';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
    width: '90vw',
  },
  tableContainer: {
    maxHeight: '60vh',
    overflow: 'scroll',
  },
  tableHeaderCell: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  searchFieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  searchField: {
    margin: '10px',
    width: 'calc(33.33% - 20px)',
    '@media (max-width: 900px)': {
      width: 'calc(50% - 20px)',
    },
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
}));

const TotalEarnings = () => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [searchTermHash, setSearchTermHash] = useState('');
  const [searchTermPackageID, setSearchTermPackageID] = useState('');
  const [searchTermUserID, setSearchTermUserID] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: 'blockTimestamp',
    direction: 'descending',
  });

  useEffect(() => {
    const asyncFunction = async () => {
      const earningsList = await getEarningsListAsAdmin();
      setData(earningsList);
    };
    asyncFunction();
  }, []);

  const filteredAndSortedData = React.useMemo(() => {
    const filteredData = data.filter(item => {
      const matchesHash = item.transactionHash.toLowerCase().includes(searchTermHash.toLowerCase());
      const matchesPackageID =
        searchTermPackageID.trim() === '' ||
        item.packageID.toString().toLowerCase() === searchTermPackageID.trim().toLowerCase();
      const matchesUserID =
        searchTermUserID.trim() === '' || item.userID.toString().toLowerCase() === searchTermUserID.trim().toLowerCase();
      const itemDate = moment(item.blockTimestamp * 1000);
      const matchesFromDate = fromDate ? itemDate.isSameOrAfter(moment(fromDate), 'day') : true;
      const matchesToDate = toDate ? itemDate.isSameOrBefore(moment(toDate), 'day') : true;

      return matchesHash && matchesPackageID && matchesUserID && matchesFromDate && matchesToDate;
    });

    return filteredData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, [data, searchTermHash, searchTermPackageID, searchTermUserID, fromDate, toDate, sortConfig]);

  const totalSum = filteredAndSortedData.reduce((acc, item) => {
    return acc + Number(item.amount) / 10 ** 18;
  }, 0);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <CmtCard className={classes.container}>
      <Typography variant="h1" component={'h1'} style={{ padding: 20 }}>
        Earnings
      </Typography>
      <CmtCardContent className={classes.searchFieldsContainer}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search by Transaction Hash"
          onChange={e => setSearchTermHash(e.target.value)}
        />
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search by PackageID"
          onChange={e => setSearchTermPackageID(e.target.value)}
        />
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search by UserID"
          onChange={e => setSearchTermUserID(e.target.value)}
        />
      </CmtCardContent>
      <CmtCardContent>
        <KeyboardDatePicker
          className={classes.searchField}
          disableToolbar
          variant="inline"
          format="YYYY-MM-DD"
          margin="normal"
          label="From Date"
          value={fromDate}
          onChange={setFromDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          className={classes.searchField}
          disableToolbar
          variant="inline"
          format="YYYY-MM-DD"
          margin="normal"
          label="To Date"
          value={toDate}
          onChange={setToDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </CmtCardContent>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('userID')}>
                UserID
              </TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('amount')}>
                Amount
              </TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('blockTimestamp')}>
                Date
              </TableCell>
              <TableCell className={classes.tableHeaderCell} onClick={() => requestSort('packageID')}>
                Package ID
              </TableCell>
              <TableCell className={classes.tableHeaderCell}>Transaction Hash</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedData.map((item, i) => (
              <TableRow key={i}>
                <TableCell>{item.userID}</TableCell>
                <TableCell>${(Number(item.amount) / 10 ** 18).toFixed(2)}</TableCell>
                <TableCell>{moment(item.blockTimestamp * 1000).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{item.packageID}</TableCell>
                <TableCell>{item.transactionHash}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h1" component={'h1'} style={{ padding: 20 }}>
        Total: $
        {totalSum.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
    </CmtCard>
  );
};

export default TotalEarnings;
