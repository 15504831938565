import React, { useEffect, useState } from 'react';
import { Slide, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../PageComponents/PageLoader';
import { fetchError } from '../../../redux/actions';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const ContentLoader = () => {
  const { error, loading, message } = useSelector(({ common }) => common);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorToDisplay, setErrorToDisplay] = useState(false);
  const [messageOpen, setMessageOpen] = useState();
  const [messageToDisplay, setMessageToDisplay] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchError(''));
    }, 3000);
    if (Boolean(error)) {
      setErrorOpen(true);
      setErrorToDisplay(error);
      setTimeout(() => {
        setErrorOpen(false);
        setErrorToDisplay('');
      }, 20000);
    }

    if (Boolean(message)) {
      setMessageOpen(true);
      setMessageToDisplay(message);
      setTimeout(() => {
        setMessageOpen(false);
        setMessageToDisplay('');
      }, 7000);
    }
  }, [dispatch, error, message]);

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      {
        <Snackbar
          open={errorOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}>
          <Alert variant="filled" severity="error">
            {errorToDisplay}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar
          open={messageOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}>
          <Alert variant="filled" severity="success">
            {messageToDisplay}
          </Alert>
        </Snackbar>
      }
    </React.Fragment>
  );
};

export default ContentLoader;
