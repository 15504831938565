import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 10,
    background: 'linear-gradient(to bottom right, #ebd78f 0%, #b08a54 100%)',
    textShadow: '2px 2px 1px #311b1b',
    color: '#FFFFFF',
    backgroundColor: '#e0e0e0',
  },
}));

const CountdownTimerSubscriptionPackage = ({ targetTimestamp }) => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = moment();
    const target = moment(targetTimestamp);
    const duration = moment.duration(target.diff(now));
    const hourText =
      duration.hours() === 1 ? <IntlMessages id={'component.hour'} /> : <IntlMessages id={'component.hours'} />;
    const minuteText =
      duration.minutes() === 1 ? <IntlMessages id={'component.minute'} /> : <IntlMessages id={'component.minutes'} />;

    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
      hourText,
      minuteText,
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear interval if the component is unmounted or the countdown is over
    return () => clearInterval(timer);
  }, []);

  // If we're past the target time, render an expiration message
  if (timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0) {
    const formattedMoment = moment(targetTimestamp).format('YYYY-MM-DD');
    return (
      <div style={{ color: 'red' }}>
        <p>
          <IntlMessages id={'component.expiredOn'} /> <strong>{formattedMoment}</strong>
        </p>
        <Button
          onClick={() =>
            (window.location.href = `/packages?packageQuery=ID:${process.env.REACT_APP_SUBSCRIPTION_PACKAGE_ID}&popup=no`)
          }
          className={classes.button}>
          <IntlMessages id={'button.renewSubscription'} />
        </Button>
      </div>
    );
  }

  // Render the countdown
  return (
    <div>
      <IntlMessages id={'component.expiresIn'} />{' '}
      <strong>
        {timeLeft.hours} {timeLeft.hourText} <IntlMessages id={'component.and'} /> {timeLeft.minutes} {timeLeft.minuteText}
      </strong>
    </div>
  );
};

export default CountdownTimerSubscriptionPackage;
