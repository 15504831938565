import React from 'react';
import LoadButton from '../LoadButton';

const ApproveAndPurchaseButtons = ({ approveEnabled, approveLoading, onApprove, buyEnabled, buyLoading, onBuy }) => {
  return (
    <div>
      <LoadButton
        customStyle={{ marginRight: 5 }}
        disabled={!approveEnabled}
        loading={approveLoading}
        onClick={() => {
          if (approveEnabled) {
            onApprove();
          }
        }}
        messageID={'component.buttonGroup.approve'}
      />
      <LoadButton
        disabled={!buyEnabled}
        loading={buyLoading}
        onClick={() => {
          if (buyEnabled) {
            onBuy();
          }
        }}
        messageID={'component.buttonGroup.buy'}
      />
    </div>
  );
};

export default ApproveAndPurchaseButtons;
